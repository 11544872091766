import { Paper } from "@mui/material";
import React, { useEffect, useReducer } from "react";
import ResourceTable from "./Table/Table";
import { PAGINATION } from "settings/constants/pagination";
import { useParams } from "react-router-dom";
import { handleError } from "utils/error";
import { get } from "lodash";
import { useLazyDeleteResourceByIdQuery } from "api/Vendors/Resource/deleteResourceById";
import { toast } from "react-toastify";
import T from "T";
import { useLazySetActiveResourceByIdQuery } from "api/Vendors/Resource/setActiveResourceById";
import { useResourceFilterMutation } from "api/Vendors/Resource/resourceFilter";
import { useLazyGetResourcesByVendorIdQuery } from "api/Vendors/Resource/getResourcesByVendorId";
import Constants from "Constants";
import { MISCurrentUser } from "utils/validations";
import { downloadFile } from "utils/file";

const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;
const { SERVER_URL } = Constants;

const Resource = () => {
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    page: INITIAL_PAGE,
    size: ROWS_PER_PAGE,
    totalPageCount: INITIAL_PAGE,
    totalTableRowsCount: INITIAL_PAGE,
    tableData: [],
    filters: {},
  });

  const { sessionToken } = MISCurrentUser();

  const { page, size, totalPageCount, totalTableRowsCount, tableData, filters } = localState;

  const { vendorId } = useParams();

  const handleRowsPerPageChange = (event) => {
    const { value } = event.target;
    setLocalState({ page: INITIAL_PAGE, size: value });
  };

  const handlePageChange = (newPage) => {
    setLocalState({ page: newPage });
    document.getElementsByClassName("MuiTableContainer-root")[0].scrollTop = 0;
  };

  const onhandleFilterChange = (newValue, item) => {
    filters[item] = newValue;

    setLocalState({ filters });
  };

  const handleFilterSubmit = () => {
    resourceFilterData();
  };

  const resourceFilterData = () => {
    const techs = get(filters, "techStack", []);
    const isActive = get(filters, "active", "");
    const payload = {
      techStack: techs.length < 1 ? [] : techs.map((it) => it.skillName),
      workMode: get(filters, "workMode", ""),
      vendorCurrency: get(filters, "currency", ""),
      isActive: isActive ? (isActive === "True" ? true : false) : "",
      overAllRating: filters?.overAllRating?.rating,
      text: "",
      status: get(filters, "status", ""),
    };
    resourceFilter({ page: INITIAL_PAGE, size: ROWS_PER_PAGE, vendorId, payload })
      .unwrap()
      .then((res) => {
        setLocalState({
          tableData: res.results,
          totalTableRowsCount: get(res, "numberOfRecords", 0),
          totalPageCount: get(res, "numberOfPages", 0),
        });
      })
      .catch(handleError);
  };

  const downloadResourceCsv = () => {
    const techs = get(filters, "techStack", []);
    const isActive = get(filters, "active", "");
    const payload = {
      techStack: techs.length < 1 ? [] : techs.map((it) => it.skillName),
      workMode: get(filters, "workMode", ""),
      isActive: isActive ? (isActive === "True" ? true : false) : "",
      rating: filters?.rating?.rating,

      text: "",
    };
    fetch(`${SERVER_URL}/resource/download/csv?vendorId=${vendorId}`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        Authorization: `Bearer ${sessionToken}`,
        "content-type": "application/json",
      },
    })
      .then((res) => res.blob())
      .then((response) => {
        downloadFile(response, T.CSV);
      })
      .catch(handleError);
  };

  const handleFilterClose = () => {
    setLocalState({ filters: {} });
    getAllRes();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    filters[name] = value;
    setLocalState({ filters });
  };

  const [getAllResource] = useLazyGetResourcesByVendorIdQuery();
  const [deleteResourceByid] = useLazyDeleteResourceByIdQuery();
  const [setActiveResourceById] = useLazySetActiveResourceByIdQuery();
  const [resourceFilter] = useResourceFilterMutation();

  const deleteResource = (id) => {
    deleteResourceByid({ id })
      .unwrap()
      .then((res) => {
        toast.success(T.RESOURCE_DELETED_SUCCESSFULLY);
        getAllRes();
      });
  };

  const getAllRes = () => {
    getAllResource({ page, size, vendorId })
      .unwrap()
      .then((res) => {
        setLocalState({
          tableData: res.results,
          totalTableRowsCount: get(res, "numberOfRecords", 0),
          totalPageCount: get(res, "numberOfPages", 0),
        });
      })
      .catch(handleError);
  };

  useEffect(() => {
    getAllRes();
  }, [page, size]);

  const handleActive = (id, status) => {
    setActiveResourceById({ id, status })
      .unwrap()
      .then((res) => {
        toast.success(res.message, { delay: 500 });
        getAllRes();
        setLocalState({ filters: {} });
      })
      .catch(handleError);
  };

  return (
    <Paper display={"block"} sx={{ p: 2, borderRadius: 2 }}>
      <ResourceTable
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
        page={page}
        size={size}
        tableData={tableData}
        totalPageCount={totalPageCount}
        totalTableRowsCount={totalTableRowsCount}
        deleteVendor={deleteResource}
        filters={filters}
        onhandleFilterChange={onhandleFilterChange}
        handleFilterClose={handleFilterClose}
        handleFilterSubmit={handleFilterSubmit}
        handleChange={handleChange}
        vendorId={vendorId}
        handleActive={handleActive}
        downloadResourceCsv = {downloadResourceCsv}
      />
    </Paper>
  );
};

export default Resource;
