import { createSlice } from "@reduxjs/toolkit";

const businessCentralFilterSlice = createSlice({
  name: "BusinessCentralFilterSlice",
  initialState: {
    storedFilters: {
      status: null,
      projectManager: null,
      vicePresident: null,
      giftStatus: null,
      projects: [],
    },
    storedSearch: "",
    storedTabValue: ["BYT"],
  },
  reducers: {
    businessCentralFilterStore: (state, action) => {
      state.storedFilters = action.payload.storedFilters;
    },
    businessCentralTabValueStore: (state, action) => {
      state.storedTabValue = action.payload.storedTabValue;
    },
    businessCentralSearchStore: (state, action) => {
      state.storedSearch = action.payload.storedSearch;
    },
  },
});
export const { businessCentralFilterStore, businessCentralTabValueStore, businessCentralSearchStore } =
  businessCentralFilterSlice.actions;

export default businessCentralFilterSlice.reducer;
