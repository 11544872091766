import { Children, useReducer } from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import { Box, TableRow, TableCell, Typography, TableBody, Tooltip, styled, IconButton, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SubTitleTypography, TitleTypography, StyledContentCopyIcon, MainTitleTypography } from "utils/members";
import { BACKGROUND, BORDER, NETSMARTZ_THEME_COLOR } from "theme/colors";
import { get } from "utils/lodash";
import memo from "utils/react";
import { capitalizeWords } from "utils/commonFunction";
import T from "T";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmSubmit from "components/Members/Member/ConfirmSubmit";
import { toast } from "react-toastify";
import { handleError } from "utils/error";
import { getFEDateFormat } from "utils/members";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderTop: "inherit",
  overflow: "hidden",
  padding: "8px 0px 8px 8px",
  ...theme.typography.subtitle1,

  "&:not(:nth-child(1)):not(:nth-child(2))": {},
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: BACKGROUND.white,
  },
  "&:nth-of-type(even)": {
    backgroundColor: BACKGROUND.cardDefault,
  },
}));

const HardwareHistoryTableBody = ({ allTableRows = [] }) => {
  return (
    <TableBody>
      {Children.toArray(
        allTableRows.map((record, index) => {
          const { hardware } = record;
          return (
            <StyledTableRow key={index}>
              <StyledTableCell
                sx={{
                  borderLeft: `5px solid ${get(record, "returnedDate", "") ? "#40c55e" : "#d44c4b"}`,
                  borderRadius: "5px 0px 0px 5px",
                }}
              >
                <Box display="flex" whiteSpace="nowrap">
                  <SubTitleTypography>{T.HARDWARE_TYPE}: </SubTitleTypography>
                  <TitleTypography sx={{ color: NETSMARTZ_THEME_COLOR }} ml={1}>
                    {capitalizeWords(get(hardware, "hardwareType.type", ""))}
                  </TitleTypography>
                </Box>
                <Box display="flex" whiteSpace="nowrap">
                  <SubTitleTypography>{T.MANUFACTURER}: </SubTitleTypography>
                  <TitleTypography ml={1}>{capitalizeWords(get(hardware, "manufacturer.name", ""))}</TitleTypography>
                </Box>
                <Box display="flex" whiteSpace="nowrap">
                  <SubTitleTypography>{T.SERIAL_NUMBER}:</SubTitleTypography>
                  <Tooltip title={get(hardware, "serialNumber", "")} placement="top">
                    <TitleTypography ml={1}>{get(hardware, "serialNumber", "")}</TitleTypography>
                  </Tooltip>
                  {StyledContentCopyIcon(get(hardware, "serialNumber", ""), T.SERIAL_NUMBER)}
                </Box>
                <Box display="flex" whiteSpace="nowrap">
                  <SubTitleTypography>{T.PURCHASE_DATE}: </SubTitleTypography>
                  <TitleTypography ml={1}>{getFEDateFormat(get(hardware, "purchaseDate", ""))}</TitleTypography>
                </Box>
                <Box display="flex" whiteSpace="nowrap">
                  <SubTitleTypography>{T.WARRANTY_END_DATE}: </SubTitleTypography>
                  <TitleTypography ml={1}>{getFEDateFormat(get(hardware, "warrantyEndDate", ""))}</TitleTypography>
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <TitleTypography sx={{ color: NETSMARTZ_THEME_COLOR }}>{capitalizeWords(record.userName)}</TitleTypography>
                <Box display="flex" whiteSpace="nowrap">
                  <SubTitleTypography>{T.EMP_CODE}: </SubTitleTypography>
                  <TitleTypography ml={1}>{capitalizeWords(record.employeeCode)}</TitleTypography>
                  {StyledContentCopyIcon(capitalizeWords(record.employeeCode), T.EMP_CODE)}
                </Box>
                <Box display="flex" whiteSpace="nowrap">
                  <SubTitleTypography>{T.DEPARTMENT}: </SubTitleTypography>
                  <TitleTypography ml={1}>{capitalizeWords(get(record, "departmentName", ""))}</TitleTypography>
                </Box>
                <Box display="flex" whiteSpace="nowrap">
                  <SubTitleTypography>{T.DESIGNATION}:</SubTitleTypography>
                  <Tooltip title={get(record, "userDesignation", "")} placement="top">
                    <TitleTypography ml={1}>{record.userDesignation}</TitleTypography>
                  </Tooltip>
                </Box>
                <Box display="flex" whiteSpace="nowrap">
                  <SubTitleTypography>{T.EMAIL_ID}: </SubTitleTypography>
                  <TitleTypography ml={1}>{record.userEmailId}</TitleTypography>
                  {StyledContentCopyIcon(record.userEmailId, T.EMAIL_ID)}
                </Box>
              </StyledTableCell>
              <StyledTableCell>{getFEDateFormat(get(record, "assignedDate", ""))}</StyledTableCell>
              <StyledTableCell>{getFEDateFormat(get(record, "returnedDate", ""))}</StyledTableCell>
            </StyledTableRow>
          );
        }),
      )}
    </TableBody>
  );
};

export default HardwareHistoryTableBody;
