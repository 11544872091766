import { Route, Routes, Navigate } from "react-router-dom";
import { MISCurrentUser } from "utils/validations";
import {
  LoginPage,
  CaseStudyPage,
  ForgotPasswordPage,
  ReportsPage,
  DashboardPage,
  ChangePasswordPage,
  NonCompliancePage,
  MembersPage,
  RolesPage,
  ProfilePage,
  MasterSettingsPage,
  TestPage,
  ErrorPage,
  FeedbackPage,
  InsideSalesPage,
  ISToolsPage,
  BusinessCentralPage,
  HardwarePage,
  CampusPlacementPage,
  AssessmentEvaluationPage,
  CommunicationAssessmentPage,
  CommunicationAssessmentEvaluationPage,
} from "pages";

import AddRole from "components/Roles/AddRole";
import EditRole from "components/Roles/EditRole";
import AddMember from "components/Members/Member/AddMember";
import EditMember from "components/Members/Member/EditMember";
import ViewMember from "components/Members/Member/ViewMember";
import routes from "router/routes";

import {
  canAddRole,
  canAddUser,
  canEditRole,
  canEditUser,
  canViewFeedback,
  canViewDashboard,
  canViewNC,
  canViewCaseStudy,
  canViewReport,
  canViewRole,
  canViewUser,
  canViewMasterSettings,
  canViewAccounting,
  canViewPreSales,
  canAddPreSales,
  canEditPreSales,
  canViewTraining,
  canAddTraining,
  canEditTraining,
  canViewTrainee,
  canViewTestimonial,
  canViewInsideSales,
  canViewTechAssignment,
  canAddTechAssignment,
  canViewMockAssessment,
  canViewISTools,
  canAddISTools,
  canEditISTools,
  canViewReferral,
  canViewRecommendations,
  canViewRecommendationsTab,
  canViewProfile,
  canViewBusinessCentral,
  canAddBusinessCentral,
  canEditBusinessCentral,
  canViewVms,
  canEditVms,
  canDeleteVms,
  canAddVms,
  canViewHardware,
  canViewCampusPlacement,
  mockVideoAssessmentView,
} from "utils/permissions";

import { get } from "lodash";
import T from "T";
import AccountingPage from "pages/AccountingPage";
import PreSalesPage from "pages/PreSalesPage";
import CreateNewProject from "components/PreSales/CreateNewProject";
import EditPreSalesProject from "components/PreSales/EditPreSalesProject";
import RecommendedMemberPage from "pages/RecommendedMemberPage";
import TrainingPage from "pages/TrainingPage";
import CreateNewCourse from "components/Training/CourseDetail/CreateNewCourse";
import EditCourse from "components/Training/CourseDetail/EditCourse";
import FeedbackForm from "components/Members/Member/Feedback/FeedbackForm";
import TestimonialPage from "pages/TestimonialPage";

// import FeedbackPage from "pages/FeedbackPage";
import ShortListedMembersPage from "pages/ShortListedMembersPage";
import PitchedMembersPage from "pages/PitchedMembersPage";
import InterviewTrackerPage from "pages/InterviewTrackerPage";
import ScheduleInterviewForm from "components/PreSales/PitchedMembers/Table/InterviewScheduleForm";
import TraineeCourseDetail from "components/Training/CourseAssigned/TraineeCourseDetail";
import { WithoutAuth } from "./WithoutAuth";
import { RequireAuth } from "./RequireAuth";
import EditInterviewTrackerPage from "components/PreSales/InterviewTracker/editInterviewTracker";
import Groups from "components/Groups";
import AddSubRole from "components/Roles/AddSubRole";
import CodeAssessment from "components/PreSales/PitchedMembers/Table/CodeAssessment";
import InterviewChecklist from "components/PreSales/PitchedMembers/Table/InterviewChecklist";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { BACKGROUND } from "theme/colors";
import HeaderLogo from "assets/Header.png";
import ViewClient from "components/PreSales/ViewClient";
import SwotAnalysisPage from "pages/SwotAnalysisPage";
import SwotForm from "components/Members/Member/SwotAnalysis/SwotForm";
import MockInterviewForm from "components/PreSales/PitchedMembers/Table/MockInterview";
import MemberAssignmentsPage from "pages/MemberAssignmentsPage";
import AddAssignmentPage from "pages/AddAssignmentPage";
import AssignmentsPage from "pages/AssignmentsPage";
import AddConsultant from "components/Members/Member/AddConsultant";
import MockInterViewPage from "pages/MockInterViewPage";
import InterviewResponses from "components/Mockinterview/InterviewResponses";
import SpecialIncidentRecord from "components/Members/Member/SIR";
import MockAssessmentContainer from "containers/MockAssessment";
import ViewAssessmentResult from "components/MockAssessment/ViewAssessmentResult";
import AddToolsLicence from "components/ISTools/ToolsLicence/LicenseForm/AddToolsLicence";
import EditToolsLicence from "components/ISTools/ToolsLicence/LicenseForm/EditToolsLicence";
import ReferralsContainer from "containers/Referrals";
import AddRecommendation from "components/Referrals/Form";
import CandidateRecommendationDetails from "components/Referrals/Recommendations/Details";
import EmployeeProfilePage from "pages/EmployeeProfilePage";
import CaseStudyVersions from "components/CaseStudy/CaseStudyVersions";
import TechnicalInterview from "components/TechnicalInterview";
import InterviewQuestions from "components/TechnicalInterview/Questions/InterviewPage";
import { navbarItems } from "components/Header/menuItems";
import AddProject from "components/BusinessCentral/ProjectForm/AddProject";
import EditProject from "components/BusinessCentral/ProjectForm/EditProject";
import VendorsPage from "pages/VendorsPage";
import VendorsForm from "components/Vendors/Form/Form";
import ResourcePage from "pages/ResourcePage";
import ResourceForm from "components/Vendors/Resource/Form/Form";
import MISLoader from "components/common/MISLoader";
import { store } from "providers/store";
import ReportCard from "components/PreSales/ReportCard";
import RefferalReportCard from "components/Referrals/ReportCard";
import AddHardware from "components/Hardware/HardwareDetail/HardwareForm/AddHardware";
import EditHardware from "components/Hardware/HardwareDetail/HardwareForm/EditHardware";
import IntervieweeLoginPage from "pages/IntervieweeLoginPage";
import VideoInterviewPage from "pages/VideoInterviewPage";
import LnDPage from "components/Training/AssignedTraining/LnDpage";
import TaskboxPage from "pages/TaskboxPage";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setBadgeCount } from "slices/taskboxSlice";
import { useLazyGetAllTasksQuery } from "api/taskbox/getAllTasks";
import { handleError } from "utils/error";
import { PAGINATION } from "settings/constants/pagination";
import MockIntervieweeLoginPage from "pages/MockInterviewLoginPage";
import MockVideoInterviewPage from "pages/MockVideoInterviewPage";
import ForgotPassPage from "pages/ForgotPassPage";
import OnBench from "components/Dashboard/OnBench";

const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;

const MainRoute = () => {
  // Initial page, we can change this based on user role in future
  const { sessionToken, user } = MISCurrentUser();
  const userRole = get(user, "role", "");
  const dispatch = useDispatch();
  const [getTaskbox] = useLazyGetAllTasksQuery();

  const getLoadingState = () => {
    const state = store.getState();
    return get(state, "LoadingSlice.storedLoading", false);
  };

  const defaultPath = routes.app.login;
  let elsePath = "";
  for (let i = 0; i < navbarItems().length; i++) {
    if (navbarItems()[i].permission) {
      elsePath = navbarItems()[i].route;
      break;
    }
  }

  const getInitialData = () => {
    const payload = {
      isReportingManger: null,
      text: null,
      userEmail: user?.user?.userEmailId ?? null,
    };
    getTaskbox({
      page: INITIAL_PAGE,
      rowPerPage: ROWS_PER_PAGE,
      payload,
    })
      .unwrap()
      .then((res) => {
        if (res) {
          dispatch(setBadgeCount({ badgeCount: get(res, "numberOfRecords", 0) }));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (user?.user) {
      getInitialData();
    }
  }, [user]);

  const redirectPath =
    sessionToken &&
    userRole !== T.ACCOUNTANT &&
    userRole !== T.SALES_MANAGER &&
    userRole !== T.DYNAMICS_TOWER_HEAD &&
    userRole !== T.DYNAMICS_VP &&
    userRole !== T.TRAINEE &&
    userRole !== T.IS &&
    userRole !== T.IS_TOOLS &&
    userRole !== T.CORPORATE_TRAINER &&
    userRole !== T.TA
      ? canViewUser()
        ? routes.app.members
        : elsePath
      : sessionToken && userRole === T.ACCOUNTANT
        ? routes.app.accounting
        : sessionToken && userRole === T.SALES_MANAGER
          ? routes.app.preSales
          : sessionToken && (userRole === T.DYNAMICS_TOWER_HEAD || userRole === T.DYNAMICS_VP)
            ? routes.app.nonCompliance
            : sessionToken && userRole === T.TRAINEE
              ? routes.app.employeeProfile
              : sessionToken && userRole === T.IS
                ? routes.app.insideSales
                : sessionToken && userRole === T.IS_TOOLS
                  ? routes.app.isTools
                  : sessionToken && userRole === T.CORPORATE_TRAINER
                    ? routes.app.mockAssessment
                    : sessionToken && userRole === T.TA
                      ? routes.app.vendors
                      : sessionToken && userRole === T.VMS
                        ? routes.app.referrals
                        : defaultPath;

  return (
    <Routes>
      <Route
        path={routes.app.login}
        element={
          <WithoutAuth path={routes.app.login}>
            <LoginPage />
          </WithoutAuth>
        }
      />
      <Route
        path={routes.app.forgotPwd}
        element={
          <WithoutAuth path={routes.app.forgotPwd}>
            <ForgotPasswordPage />
          </WithoutAuth>
        }
      />
      <Route
        path={routes.app.forgotPwdPage}
        element={
          <WithoutAuth path={routes.app.forgotPwdPage}>
            <ForgotPassPage />
          </WithoutAuth>
        }
      />
      <Route path="/" element={<Navigate to={redirectPath} />} />
      <Route
        path={routes.app.changePwd}
        element={
          <RequireAuth path={routes.app.changePwd}>
            <ChangePasswordPage />
          </RequireAuth>
        }
      />
      {canViewReport() && (
        <Route
          exact
          path={routes.app.reports}
          element={
            <RequireAuth path={routes.app.reports}>
              <ReportsPage />
            </RequireAuth>
          }
        />
      )}
      {canViewDashboard() && (
        <Route
          exact
          path={routes.app.dashboard}
          element={
            <RequireAuth path={routes.app.dashboard}>
              <DashboardPage />
            </RequireAuth>
          }
        />
      )}
      {canViewCaseStudy() && (
        <Route
          exact
          path={routes.app.caseStudy}
          element={
            <RequireAuth path={routes.app.caseStudy}>
              <CaseStudyPage />
            </RequireAuth>
          }
        />
      )}
      {canViewCaseStudy() && (
        <Route
          exact
          path={routes.app.csHistory}
          element={
            <RequireAuth path={routes.app.csHistory}>
              <CaseStudyVersions />
            </RequireAuth>
          }
        />
      )}
      {canViewTestimonial() && (
        <Route
          exact
          path={routes.app.testimonial}
          element={
            <RequireAuth path={routes.app.testimonial}>
              <TestimonialPage />
            </RequireAuth>
          }
        />
      )}
      {canViewUser() && (
        <Route
          exact
          path={routes.app.members}
          element={
            <RequireAuth path={routes.app.members}>
              <MembersPage />
            </RequireAuth>
          }
        />
      )}
      <Route
        exact
        path={routes.app.lndpage}
        element={
          <RequireAuth path={routes.app.lndpage}>
            <LnDPage />
          </RequireAuth>
        }
      />
      ;
      {canAddUser() && (
        <Route
          exact
          path={routes.app.addMembers}
          element={
            <RequireAuth path={routes.app.addMembers}>
              <AddMember />
            </RequireAuth>
          }
        />
      )}
      {canAddUser() && (
        <Route
          exact
          path={routes.app.addConsultant}
          element={
            <RequireAuth path={routes.app.addConsultant}>
              <AddConsultant />
            </RequireAuth>
          }
        />
      )}
      {canViewFeedback() && (
        <Route
          exact
          path={routes.app.feedback}
          element={
            <RequireAuth path={routes.app.feedback}>
              <FeedbackPage />
            </RequireAuth>
          }
        />
      )}
      <Route
        exact
        path={routes.app.feedbackForm}
        element={
          <RequireAuth path={routes.app.feedbackForm}>
            <FeedbackForm />
          </RequireAuth>
        }
      />
      <Route
        exact
        path={routes.app.swotAnalysis}
        element={
          <RequireAuth path={routes.app.swotAnalysis}>
            <SwotAnalysisPage />
          </RequireAuth>
        }
      />
      <Route
        exact
        path={routes.app.swotForm}
        element={
          <RequireAuth path={routes.app.swotForm}>
            <SwotForm />
          </RequireAuth>
        }
      />
      <Route
        exact
        path={routes.app.editSwotForm}
        element={
          <RequireAuth path={routes.app.editSwotForm}>
            <SwotForm />
          </RequireAuth>
        }
      />
      <Route
        exact
        path={routes.app.specialIncidentRegister}
        element={
          <RequireAuth path={routes.app.specialIncidentRegister}>
            <SpecialIncidentRecord />
          </RequireAuth>
        }
      />
      {canEditUser() && (
        <Route
          exact
          path={routes.app.editMembers}
          element={
            <RequireAuth path={routes.app.editMembers}>
              <EditMember />
            </RequireAuth>
          }
        />
      )}
      {canViewUser() && (
        <Route
          exact
          path={routes.app.viewMembers}
          element={
            <RequireAuth path={routes.app.viewMembers}>
              <ViewMember />
            </RequireAuth>
          }
        />
      )}
      {canViewRole() && (
        <Route
          exact
          path={routes.app.roles}
          element={
            <RequireAuth path={routes.app.roles}>
              <RolesPage />
            </RequireAuth>
          }
        />
      )}
      {canAddRole() && (
        <Route
          exact
          path={routes.app.addRoles}
          element={
            <RequireAuth path={routes.app.addRoles}>
              <AddRole />
            </RequireAuth>
          }
        />
      )}
      {canAddRole() && (
        <Route
          exact
          path={routes.app.addSubRoles}
          element={
            <RequireAuth path={routes.app.addSubRoles}>
              <AddSubRole />
            </RequireAuth>
          }
        />
      )}
      {canEditRole() && (
        <Route
          exact
          path={routes.app.editRoles}
          element={
            <RequireAuth path={routes.app.editRoles}>
              <EditRole />
            </RequireAuth>
          }
        />
      )}
      {canViewNC() && (
        <Route
          exact
          path={routes.app.nonCompliance}
          element={
            <RequireAuth path={routes.app.nonCompliance}>
              <NonCompliancePage />
            </RequireAuth>
          }
        />
      )}
      {canViewMasterSettings() && (
        <Route
          exact
          path={routes.app.masterSettings}
          element={
            <RequireAuth path={routes.app.masterSettings}>
              <MasterSettingsPage />
            </RequireAuth>
          }
        />
      )}
      {canViewAccounting() && (
        <Route
          exact
          path={routes.app.accounting}
          element={
            <RequireAuth path={routes.app.accounting}>
              <AccountingPage />
            </RequireAuth>
          }
        />
      )}
      {canViewPreSales() && (
        <Route
          exact
          path={routes.app.preSales}
          element={
            <RequireAuth path={routes.app.preSales}>
              <PreSalesPage />
            </RequireAuth>
          }
        />
      )}
      {canViewPreSales() && (
        <Route
          exact
          path={routes.app.recommendedMembers}
          element={
            <RequireAuth path={routes.app.recommendedMembers}>
              <RecommendedMemberPage />
            </RequireAuth>
          }
        />
      )}
      {canViewPreSales() && (
        <Route
          exact
          path={routes.app.shortlistedMembers}
          element={
            <RequireAuth path={routes.app.shortlistedMembers}>
              <ShortListedMembersPage />
            </RequireAuth>
          }
        />
      )}
      {canViewPreSales() && (
        <Route
          exact
          path={routes.app.pitchedMembers}
          element={
            <RequireAuth path={routes.app.pitchedMembers}>
              <PitchedMembersPage />
            </RequireAuth>
          }
        />
      )}
      {canViewPreSales() && (
        <Route
          exact
          path={routes.app.codeAssessment}
          element={
            <RequireAuth path={routes.app.codeAssessment}>
              <CodeAssessment />
            </RequireAuth>
          }
        />
      )}
      {canViewProfile() && (
        <Route
          exact
          path={routes.app.employeeProfile}
          element={
            <RequireAuth path={routes.app.employeeProfile}>
              <EmployeeProfilePage />
            </RequireAuth>
          }
        />
      )}
      {!userRole || userRole === undefined || userRole === null ? (
        <Route
          path={routes.app.interviewChecklist}
          element={
            <WithoutAuth path={routes.app.interviewChecklist}>
              <Box
                sx={{
                  backgroundColor: BACKGROUND.white,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  p: 0,
                }}
                mb={1}
                mt={-2.4}
                mx={-2.4}
              >
                <Box component="img" src={HeaderLogo} width={145} pl={1.5} />
                <Typography
                  sx={{
                    flexGrow: 1,
                  }}
                  variant="h5"
                  textAlign="center"
                  pt={2.5}
                  pb={1.5}
                  mr={19}
                >
                  {T.INTERVIEW_READINESS_CHECKLIST}
                </Typography>
              </Box>

              <InterviewChecklist defaultTab="1" disableSecondTab={true} />
            </WithoutAuth>
          }
        />
      ) : (
        <Route
          path={routes.app.interviewChecklist}
          element={
            <RequireAuth path={routes.app.interviewChecklist}>
              <InterviewChecklist defaultTab="2" disableFirstTab={true} />
            </RequireAuth>
          }
        />
      )}
      {canViewPreSales() && (
        <Route
          exact
          path={routes.app.scheduleInterview}
          element={
            <RequireAuth path={routes.app.scheduleInterview}>
              <ScheduleInterviewForm />
            </RequireAuth>
          }
        />
      )}
      {canViewPreSales() && (
        <Route
          exact
          path={routes.app.presalesReportCard}
          element={
            <RequireAuth path={routes.app.presalesReportCard}>
              <ReportCard />
            </RequireAuth>
          }
        />
      )}
      {canViewPreSales() && (
        <Route
          exact
          path={routes.app.scheduleMockInterview}
          element={
            <RequireAuth path={routes.app.scheduleMockInterview}>
              <MockInterviewForm />
            </RequireAuth>
          }
        />
      )}
      {/* {canTrackerView() && ( */}
      {canViewPreSales() && (
        <Route
          exact
          path={routes.app.allPitchedMembers}
          element={
            <RequireAuth path={routes.app.allPitchedMembers}>
              <InterviewTrackerPage />
            </RequireAuth>
          }
        />
      )}
      {canViewPreSales() && (
        <Route
          exact
          path={routes.app.viewClient}
          element={
            <RequireAuth path={routes.app.viewClient}>
              <ViewClient />
            </RequireAuth>
          }
        />
      )}
      {canViewPreSales() && (
        <Route
          exact
          path={routes.app.editInterviewTracker}
          element={
            <RequireAuth path={routes.app.editInterviewTracker}>
              <EditInterviewTrackerPage />
            </RequireAuth>
          }
        />
      )}
      {canAddPreSales() && (
        <Route
          exact
          path={routes.app.createNewProject}
          element={
            <RequireAuth path={routes.app.createNewProject}>
              <CreateNewProject />
            </RequireAuth>
          }
        />
      )}
      {canEditPreSales() && (
        <Route
          exact
          path={routes.app.editPreSalesProject}
          element={
            <RequireAuth path={routes.app.editPreSalesProject}>
              <EditPreSalesProject />
            </RequireAuth>
          }
        />
      )}
      {(canViewTrainee() || canViewTraining()) && (
        <Route
          exact
          path={routes.app.training}
          element={
            <RequireAuth path={routes.app.training}>
              <TrainingPage />
            </RequireAuth>
          }
        />
      )}
      {canAddTraining() && (
        <Route
          exact
          path={routes.app.groups}
          element={
            <RequireAuth path={routes.app.groups}>
              <Groups />
            </RequireAuth>
          }
        />
      )}
      {canAddTraining() && (
        <Route
          exact
          path={routes.app.addCourse}
          element={
            <RequireAuth path={routes.app.addCourse}>
              <CreateNewCourse />
            </RequireAuth>
          }
        />
      )}
      {canEditTraining() && (
        <Route
          exact
          path={routes.app.editCourse}
          element={
            <RequireAuth path={routes.app.editCourse}>
              <EditCourse />
            </RequireAuth>
          }
        />
      )}
      {(canViewTrainee() || canViewTraining()) && (
        <Route
          exact
          path={routes.app.traineeCourse}
          element={
            <RequireAuth path={routes.app.traineeCourse}>
              <TraineeCourseDetail />
            </RequireAuth>
          }
        />
      )}
      {canViewInsideSales() && (
        <Route
          exact
          path={routes.app.insideSales}
          element={
            <RequireAuth path={routes.app.insideSales}>
              <InsideSalesPage />
            </RequireAuth>
          }
        />
      )}
      {canViewTechAssignment() && (
        <Route
          exact
          path={routes.app.memberAssignments}
          element={
            <RequireAuth path={routes.app.memberAssignments}>
              <MemberAssignmentsPage />
            </RequireAuth>
          }
        />
      )}
      {canViewTechAssignment() && (
        <Route
          exact
          path={routes.app.memberAssignments}
          element={
            <RequireAuth path={routes.app.memberAssignments}>
              <MemberAssignmentsPage />
            </RequireAuth>
          }
        />
      )}
      {canAddTechAssignment() && (
        <Route
          exact
          path={routes.app.addAssignment}
          element={
            <RequireAuth path={routes.app.addAssignment}>
              <AddAssignmentPage />
            </RequireAuth>
          }
        />
      )}
      {canViewTechAssignment() && (
        <Route
          exact
          path={routes.app.assignedTopics}
          element={
            <RequireAuth path={routes.app.assignedTopics}>
              <AssignmentsPage />
            </RequireAuth>
          }
        />
      )}
      <Route
        exact
        path={routes.app.mockInterview}
        element={
          <RequireAuth path={routes.app.mockInterview}>
            <MockInterViewPage />
          </RequireAuth>
        }
      />
      <Route
        exact
        path={routes.app.mockInterviewReponses}
        element={
          <RequireAuth path={routes.app.mockInterviewReponses}>
            <InterviewResponses />
          </RequireAuth>
        }
      />
      {canViewISTools() && (
        <Route
          exact
          path={routes.app.isTools}
          element={
            <RequireAuth path={routes.app.isTools}>
              <ISToolsPage />
            </RequireAuth>
          }
        />
      )}
      {canAddISTools() && (
        <Route
          exact
          path={routes.app.addISLicense}
          element={
            <RequireAuth path={routes.app.addISLicense}>
              <AddToolsLicence />
            </RequireAuth>
          }
        />
      )}
      {canEditISTools() && (
        <Route
          exact
          path={routes.app.editISLicense}
          element={
            <RequireAuth path={routes.app.editISLicense}>
              <EditToolsLicence />
            </RequireAuth>
          }
        />
      )}
      {canViewCampusPlacement() && (
        <Route
          exact
          path={routes.app.campusPlacement}
          element={
            <RequireAuth path={routes.app.campusPlacement}>
              <CampusPlacementPage />
            </RequireAuth>
          }
        />
      )}
      <Route
        exact
        path={routes.app.assessmentEvaluation}
        element={
          <RequireAuth path={routes.app.assessmentEvaluation}>
            <AssessmentEvaluationPage />
          </RequireAuth>
        }
      />
      <Route
        exact
        path={routes.app.referralRecommendationForm}
        element={
          <RequireAuth path={routes.app.referralRecommendationForm}>
            <AddRecommendation />
          </RequireAuth>
        }
      />
      <Route
        exact
        path={routes.app.recommendationDetails}
        element={
          <RequireAuth path={routes.app.recommendationDetails}>
            <CandidateRecommendationDetails />
          </RequireAuth>
        }
      />
      <Route
        exact
        path={routes.app.profile}
        element={
          <RequireAuth path={routes.app.profile}>
            <ProfilePage />
          </RequireAuth>
        }
      />
      {(canViewRecommendations() || canViewRecommendationsTab()) && (
        <Route
          exact
          path={routes.app.referrals}
          element={
            <RequireAuth path={routes.app.referrals}>
              <ReferralsContainer />
            </RequireAuth>
          }
        />
      )}
      <Route
        exact
        path={routes.app.recommendationReportCard}
        element={
          <RequireAuth path={routes.app.recommendationReportCard}>
            <RefferalReportCard />
          </RequireAuth>
        }
      />
      {canViewMockAssessment() && (
        <>
          <Route
            exact
            path={routes.app.mockAssessment}
            element={
              <RequireAuth path={routes.app.mockAssessment}>
                <MockAssessmentContainer />
              </RequireAuth>
            }
          />
          <Route
            exact
            path={routes.app.mockAssessmentResult}
            element={
              <RequireAuth path={routes.app.mockAssessmentResult}>
                <ViewAssessmentResult />
              </RequireAuth>
            }
          />
        </>
      )}
      <Route
        exact
        path={routes.app.technicalInterview}
        element={
          <RequireAuth path={routes.app.technicalInterview}>
            <TechnicalInterview />
          </RequireAuth>
        }
      />
      <Route
        exact
        path={routes.app.technicalInterviewUserPage}
        element={
          <RequireAuth path={routes.app.technicalInterviewUserPage}>
            <TechnicalInterview />
          </RequireAuth>
        }
      />
      <Route
        exact
        path={routes.app.taskbox}
        element={
          <RequireAuth path={routes.app.taskbox}>
            <TaskboxPage />
          </RequireAuth>
        }
      />
      {canViewBusinessCentral() && (
        <Route
          exact
          path={routes.app.businessCentral}
          element={
            <RequireAuth path={routes.app.businessCentral}>
              <BusinessCentralPage />
            </RequireAuth>
          }
        />
      )}
      {canAddBusinessCentral() && (
        <Route
          exact
          path={routes.app.addProject}
          element={
            <RequireAuth path={routes.app.addProject}>
              <AddProject />
            </RequireAuth>
          }
        />
      )}
      {canEditBusinessCentral() && (
        <Route
          exact
          path={routes.app.editProject}
          element={
            <RequireAuth path={routes.app.editProject}>
              <EditProject />
            </RequireAuth>
          }
        />
      )}
      {canViewHardware() && (
        <Route
          exact
          path={routes.app.hardware}
          element={
            <RequireAuth path={routes.app.hardware}>
              <HardwarePage />
            </RequireAuth>
          }
        />
      )}
      {canViewHardware() && (
        <Route
          exact
          path={routes.app.addHardware}
          element={
            <RequireAuth path={routes.app.addHardware}>
              <AddHardware />
            </RequireAuth>
          }
        />
      )}
      {canViewHardware() && (
        <Route
          exact
          path={routes.app.editHardware}
          element={
            <RequireAuth path={routes.app.editHardware}>
              <EditHardware />
            </RequireAuth>
          }
        />
      )}
      <Route
        exact
        path={routes.app.taskbox}
        element={
          <RequireAuth path={routes.app.taskbox}>
            <TaskboxPage />
          </RequireAuth>
        }
      />
      <Route
        exact
        path={routes.app.test}
        element={
          <WithoutAuth path={routes.app.test}>
            <TestPage />
          </WithoutAuth>
        }
      />
      {canViewVms() && (
        <Route
          exact
          path={routes.app.vendors}
          element={
            <RequireAuth path={routes.app.vendors}>
              <VendorsPage />
            </RequireAuth>
          }
        />
      )}
      {canViewVms() && (
        <Route
          exact
          path={routes.app.vendorsForm}
          element={
            <RequireAuth path={routes.app.vendorsForm}>
              <VendorsForm />
            </RequireAuth>
          }
        />
      )}
      {canViewVms() && (
        <Route
          exact
          path={`${routes.app.vendorsForm}/:id`}
          element={
            <RequireAuth path={routes.app.vendorsForm}>
              <VendorsForm />
            </RequireAuth>
          }
        />
      )}
      {canViewVms() && (
        <Route
          exact
          path={routes.app.vendors}
          element={
            <RequireAuth path={routes.app.vendors}>
              <VendorsPage />
            </RequireAuth>
          }
        />
      )}
      <Route
        exact
        path={routes.app.resourceForm + "/:vendorId"}
        element={
          <RequireAuth path={routes.app.resourceForm + "/:vendorId"}>
            <ResourceForm />
          </RequireAuth>
        }
      />
      <Route
        exact
        path={`${routes.app.resourceForm}/:vendorId/:id`}
        element={
          <RequireAuth path={routes.app.resourceForm + "/:vendorId/:id"}>
            <ResourceForm />
          </RequireAuth>
        }
      />
      <Route
        exact
        path={routes.app.resource + "/:vendorId"}
        element={
          <RequireAuth path={routes.app.resource + "/:vendorId"}>
            <ResourcePage />
          </RequireAuth>
        }
      />
      <Route
        exact
        path={routes.app.intervieweeLogin}
        element={
          <WithoutAuth path={routes.app.intervieweeLogin}>
            <IntervieweeLoginPage />
          </WithoutAuth>
        }
      />
      <Route
        exact
        path={routes.app.communicationIntervieweeLogin}
        element={
          <WithoutAuth path={routes.app.communicationIntervieweeLogin}>
            <MockIntervieweeLoginPage />
          </WithoutAuth>
        }
      />
      {mockVideoAssessmentView() && (
        <Route
          exact
          path={routes.app.communicationAssessment}
          element={
            <RequireAuth path={routes.app.communicationAssessment}>
              <CommunicationAssessmentPage />
            </RequireAuth>
          }
        />
      )}
      <Route
        exact
        path={routes.app.communicationAssessmentEvaluation}
        element={
          <RequireAuth path={routes.app.communicationAssessmentEvaluation}>
            <CommunicationAssessmentEvaluationPage />
          </RequireAuth>
        }
      />
         <Route
        exact
        path={routes.app.onBench}
        element={
          <RequireAuth path={routes.app.onBench}>
            <OnBench />
          </RequireAuth>
        }
      />
      <Route
        exact
        path={routes.app.screenTest}
        element={
          <WithoutAuth path={routes.app.screenTest}>
            <VideoInterviewPage />
          </WithoutAuth>
        }
      />
      <Route
        exact
        path={routes.app.mockVideoScreenTest}
        element={
          <WithoutAuth path={routes.app.mockVideoScreenTest}>
            <MockVideoInterviewPage />
          </WithoutAuth>
        }
      />
      <Route path="*" element={getLoadingState() ? <MISLoader /> : <ErrorPage />} />
    </Routes>
  );
};

export default MainRoute;
