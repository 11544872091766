import { useEffect, useReducer } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import capitalize from "lodash/capitalize";

import { Box, Button, Paper, Typography, Grid, IconButton, Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import MISLoader from "components/common/MISLoader";
import { useLazyGetUserViewByIdQuery } from "api/members/getUserViewById";

import T from "T";
import Constants from "Constants";
import { canAddTraining, canEditUser } from "utils/permissions";
import { FRONTEND_DATE_FORMAT, BACKEND_DATE_FORMAT } from "settings/constants/date";

import { GET_SIZE } from "utils/responsive";
import { handleError } from "utils/error";
import { get } from "utils/lodash";
import { MISCurrentUser } from "utils/validations";
import { downloadFile } from "utils/file";

import { SubTitleTypography, TitleTypography, StyledContentCopyIcon, getFEDateFormat } from "utils/members";

import { useLazyGetUserNcHistoryQuery } from "api/members/getUserNcHistory";
import { BACKGROUND, NETSMARTZ_THEME_COLOR } from "theme/colors";
import AddIcon from "@mui/icons-material/Add";
import AssignedCourseDialog from "components/Training/AssignedTraining/AssignedCourseDialog";
import { useLazyGetFeedbackByIdQuery } from "api/members/getFeedbacks";
import NCHistory from "./View/NCHistory";
import ProjectHistory from "./View/ProjectHistory";
import TechnicalHistory from "./View/TechnicalHistory";
import AddFeedback from "./View/AddFeedback";
import DeleteNC from "./View/DeleteNC";
import { MONTH_LIST } from "./memberModel";
import ActiveNcList from "./View/NcLists/ActiveNcList";
import DeletedNcList from "./View/NcLists/DeletedNcList";
import { endOfMonth, format, isValid, startOfMonth } from "date-fns";
import FeedbackHistory from "./View/FeedbackHistory";
import InterviewHistory from "./View/InterviewHistory";
import { useLazyGetUserInterviewHistoryQuery } from "api/members/getUserInterviewHistory";
import TrainingHistory from "./View/TrainingHistory";
import { useLazyGetUserTrainingHistoryQuery } from "api/members/getUserTrainingHistory";

const { SERVER_URL } = Constants;

const ViewMember = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isXs } = GET_SIZE();
  const getBEDateFormat = (val) => format(val, BACKEND_DATE_FORMAT);

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    startDate: getBEDateFormat(startOfMonth(new Date())),
    endDate: getBEDateFormat(endOfMonth(new Date())),
    startMonth: null,
    endMonth: null,
    feedbackAddition: false,
    projectId: "",
    ncId: "",
    selectedMonth: "",
    typeOfNc: "",
    assignedCourse: false,
    exportLoading: false,
    openDeleteNC: false,
    openActiveNcCountList: false,
    openDeleteNcCountList: false,
    ncHistory: [],
    interviewHistory: [],
    trainingHistory: [],
  });

  const {
    startDate,
    endDate,
    assignedCourse,
    startMonth,
    endMonth,
    feedbackAddition,
    projectId,
    ncId,
    selectedMonth,
    typeOfNc,
    exportLoading,
    openDeleteNC,
    openActiveNcCountList,
    openDeleteNcCountList,
    ncHistory,
    interviewHistory,
    trainingHistory,
  } = localState;

  const startMonthCount = MONTH_LIST.indexOf(startMonth) + 1;
  const endMonthCount = MONTH_LIST.indexOf(endMonth) + 1;
  const url = location.pathname;
  const id = Number(url.replace(/\D+/g, ""));

  const [getUserViewById, { isFetching, data: viewData }] = useLazyGetUserViewByIdQuery();
  const [getFeedbackById, { data: DataFeed }] = useLazyGetFeedbackByIdQuery();
  const tableRecord = get(DataFeed, "results", []);
  const [getUserNcHistory] = useLazyGetUserNcHistoryQuery();
  const [getUserInterviewHistory] = useLazyGetUserInterviewHistoryQuery();
  const [getUserTrainingHistory] = useLazyGetUserTrainingHistoryQuery();

  const refreshView = () => {
    getUserViewById({ id }).unwrap().catch(handleError);
  };

  useEffect(() => {
    if (initialNcHistory) {
      // setLocalState({ ncHistory: initialNcHistory });
      getNCHistory(id, startDate, endDate);
    }
    const userId = id;
    const pmId = "";
    getFeedbackById({ userId, pmId });
    refreshView();
  }, []);

  const handleBack = () => {
    navigate(-1, {
      state: {
        showActive: empStatus === T.STABLE || empStatus === T.YET_TO_JOIN || empStatus === T.RESIGNED,
      },
    });
  };

  const record = get(viewData, "results", []);
  const fullName = get(record, "userName", "");
  const empCode = get(record, "empCode", "");
  const empStatus = get(record, "empStatus", "");
  const department = get(record, "department", "");
  const designation = get(record, "designation", "");
  const reportingManager = get(record, "reportingManager", "-");
  const nonWorkingDays = get(record, "nonWorkingDays", ["-"]);
  const email = get(record, "email", "");
  const phone = get(record, "mobileNo", "");
  const experience = `${get(record, "expInYears", 0)}.${get(record, "expInMonths", 0)} ${T.YEARS.toLowerCase()}`;
  const jiraFrequency = get(record, "jiraFrequency", "-");
  const joiningDateTime = get(record, "joiningDate", "");

  const joiningDate = joiningDateTime ? getFEDateFormat(joiningDateTime.split(" ")[0], FRONTEND_DATE_FORMAT) : "-";

  const projectHistory = get(record, "projectHistory", []);
  const technicalHistory = get(record, "technicalHistory", []);
  const initialNcHistory = get(record, "ncHistory", []);

  useEffect(() => {
    if (startDate && endDate) {
      getNCHistory(id, startDate, endDate);
    } else {
      setLocalState({ ncHistory: initialNcHistory });
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (email) {
      getUserInterviewHistory({ email })
        .unwrap()
        .then((res) => {
          if (Array.isArray(res) && res.length > 0) {
            setLocalState({ interviewHistory: res });
          }
        })
        .catch(handleError);
    }
    if (id) {
      getUserTrainingHistory({ id })
        .unwrap()
        .then((res) => {
          setLocalState({ trainingHistory: res });
        })
        .catch(handleError);
    }
  }, [email, id]);

  const getNCHistory = (id, startDate, endDate) => {
    getUserNcHistory({ id, startDate, endDate })
      .unwrap()
      .then((res) => {
        setLocalState({ ncHistory: get(res, "results", []) });
      })
      .catch(handleError);
  };

  const onHandleDateChange = (newValue, type) => {
    const validDate = new Date(newValue);
    if (isValid(validDate)) setLocalState({ [type]: format(validDate, BACKEND_DATE_FORMAT) });
  };

  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setLocalState({ [name]: value });
  };

  const { sessionToken } = MISCurrentUser();
  const handleExport = async (type) => {
    setLocalState({ exportLoading: true });
    fetch(`${SERVER_URL}/feedback/project/export?id=${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    })
      .then((res) => res.blob())
      .then((response) => {
        downloadFile(response, type);

        setLocalState({ exportLoading: false });
      })
      .catch(handleError);
  };

  const handleDeleteNCDialog = (id = "") => {
    setLocalState({
      openDeleteNC: !openDeleteNC,
      ncId: id,
    });
  };

  // Active user NC list open handler
  const handleActiveNcCountDialog = (selectedMonth = "", typeOfNc = "") => {
    setLocalState({
      openActiveNcCountList: !openActiveNcCountList,
      selectedMonth,
      typeOfNc,
    });
  };

  // Deleted user NC list open handler
  const handleDeleteNcCountDialog = (selectedMonth = "", typeOfNc = "") => {
    setLocalState({
      openDeleteNcCountList: !openDeleteNcCountList,
      selectedMonth,
      typeOfNc,
    });
  };

  const handleAddFeedbackDialog = (id) => {
    setLocalState({ feedbackAddition: !feedbackAddition, projectId: id });
  };

  const handleAddFeedback = (id) => {
    handleAddFeedbackDialog(id);
  };

  const handleAssignedCourseDialog = () => {
    setLocalState({ assignedCourse: !assignedCourse });
  };

  return (
    <Paper sx={{ p: 2, maxHeight: "calc(100vh - 150px)", overflow: "auto" }}>
      {(isFetching || exportLoading) && <MISLoader />}

      <Box display="flex" alignItems="center" mb={1}>
        <ArrowBackIcon fontSize="small" sx={{ cursor: "pointer" }} onClick={handleBack} />
        <Typography variant="h5" fontWeight={700} ml={1}>
          {fullName.toUpperCase()}
        </Typography>
        {canEditUser() && (
          <Tooltip title={T.EDIT_DETAILS} placement="top">
            <IconButton
              sx={{
                mr: 1,
                cursor: "pointer",
                color: NETSMARTZ_THEME_COLOR,
              }}
              onClick={() => {
                navigate(`edit`, { state: { path: location.pathname, reSelectedPM: null } });
              }}
            >
              <EditOutlinedIcon fontSize="medium" disabled sx={{ height: 18 }} />
            </IconButton>
          </Tooltip>
        )}
        {canAddTraining() && (
          <Button
            size="medium"
            sx={{
              bgcolor: NETSMARTZ_THEME_COLOR,
              whiteSpace: "nowrap",
              color: BACKGROUND.white,

              "& .MuiButton-startIcon": {
                mr: 0,
              },
              "&:hover": {
                bgcolor: NETSMARTZ_THEME_COLOR,
                color: BACKGROUND.white,
              },
            }}
            // disabled={!canAddMasterSettings()}
            startIcon={<AddIcon sx={{ width: 19 }} />}
            onClick={() => handleAssignedCourseDialog()}
          >
            {T.ASSIGN_COURSE}
          </Button>
        )}
      </Box>

      <Grid spacing={2} container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item mt={2}>
          <Box display="flex">
            <SubTitleTypography>{T.EMP_CODE}:</SubTitleTypography>
            <TitleTypography ml={1}>{empCode}</TitleTypography>
            {StyledContentCopyIcon(empCode, T.EMP_CODE)}
          </Box>

          <Box display="flex" mt={0.5}>
            <SubTitleTypography>{T.DEPARTMENT}:</SubTitleTypography>
            <TitleTypography ml={1}>{department}</TitleTypography>
          </Box>

          <Box display="flex" mt={0.5}>
            <SubTitleTypography>{T.DESIGNATION}:</SubTitleTypography>
            <TitleTypography ml={1}>{designation}</TitleTypography>
          </Box>

          <Box display="flex" mt={0.5}>
            <SubTitleTypography>{T.REPORTING_MANAGER}:</SubTitleTypography>
            <TitleTypography ml={1}>{reportingManager}</TitleTypography>
          </Box>

          <Box display="flex" mt={0.5}>
            <SubTitleTypography>{T.NON_WORKING_DAYS}:</SubTitleTypography>
            <TitleTypography ml={1}>{nonWorkingDays.map((item) => capitalize(item)).join(", ")}</TitleTypography>
          </Box>
        </Grid>
        <Grid item mt={2}>
          <Box display="flex">
            <SubTitleTypography>{T.EMAIL_ID}:</SubTitleTypography>
            <TitleTypography ml={1} noWrap maxWidth={isXs ? 205 : "inherit"}>
              {email}
            </TitleTypography>
            {StyledContentCopyIcon(email, T.EMAIL_ID)}
          </Box>

          <Box display="flex" mt={0.5}>
            <SubTitleTypography>{`${T.PHONE} ${T.NUMBER}`}:</SubTitleTypography>
            <TitleTypography ml={1}>{phone}</TitleTypography>
            {StyledContentCopyIcon(phone, `${T.PHONE} ${T.NUMBER}`)}
          </Box>

          <Box display="flex" mt={0.5}>
            <SubTitleTypography>{T.JOINING_DATE_IN_NTZ}:</SubTitleTypography>
            <TitleTypography ml={1} noWrap maxWidth={isXs ? 100 : "inherit"}>
              {joiningDate}
            </TitleTypography>
          </Box>

          <Box display="flex" mt={0.5}>
            <SubTitleTypography>{T.TOTAL_EXPERIENCE}:</SubTitleTypography>
            <TitleTypography ml={1}>{experience}</TitleTypography>
          </Box>

          <Box display="flex" mt={0.5}>
            <SubTitleTypography>{T.FREQUENCY_OF_JIRA}:</SubTitleTypography>
            <TitleTypography ml={1}>{jiraFrequency}</TitleTypography>
          </Box>
        </Grid>
      </Grid>

      <TechnicalHistory results={technicalHistory} />
      <InterviewHistory allTableRows={interviewHistory} handleAddFeedback={handleAddFeedback} handleExport={handleExport} />

      <FeedbackHistory tableRecord={tableRecord} />

      <TrainingHistory tableRecord={trainingHistory} />

      <ProjectHistory allTableRows={projectHistory} handleAddFeedback={handleAddFeedback} handleExport={handleExport} />
      <NCHistory
        startMonth={startMonth}
        endMonth={endMonth}
        startDate={startDate}
        endDate={endDate}
        allTableRows={ncHistory}
        onHandleDateChange={onHandleDateChange}
        refreshNCTable={refreshView}
        handleDeleteNCDialog={handleDeleteNCDialog}
        handleActiveNcCountDialog={handleActiveNcCountDialog}
        handleDeleteNcCountDialog={handleDeleteNcCountDialog}
        onHandleChange={onHandleChange}
        handleExport={handleExport}
      />
      <AddFeedback
        feedbackAddition={feedbackAddition}
        projectId={projectId}
        handleAddFeedbackDialog={handleAddFeedbackDialog}
        refreshView={refreshView}
      />
      <DeleteNC openDeleteNC={openDeleteNC} ncId={ncId} refreshView={refreshView} handleDeleteNCDialog={handleDeleteNCDialog} />

      <ActiveNcList
        openActiveNcCountList={openActiveNcCountList}
        selectedUserName={fullName}
        userId={id}
        endDate={endDate}
        startDate={startDate}
        selectedMonth={selectedMonth}
        typeOfNc={typeOfNc}
        refreshView={refreshView}
        handleActiveNcCountDialog={handleActiveNcCountDialog}
      />

      <DeletedNcList
        openDeleteNcCountList={openDeleteNcCountList}
        selectedUserName={fullName}
        userId={id}
        endDate={endDate}
        startDate={startDate}
        selectedMonth={selectedMonth}
        typeOfNc={typeOfNc}
        refreshView={refreshView}
        handleDeleteNcCountDialog={handleDeleteNcCountDialog}
      />
      <AssignedCourseDialog
        selectedUserName={fullName}
        isDialogOpen={assignedCourse}
        handleAssignedCourseDialog={handleAssignedCourseDialog}
        userId={id}
      />
    </Paper>
  );
};

export default ViewMember;
