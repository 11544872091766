import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, styled, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useReducer, useState } from "react";
import GiftTableHeader from "./GiftTableHeader";
import GiftTableBody from "./GiftTableBody";
import MISNoData from "components/common/MISNoData";
import { noop } from "lodash";
import MISFooterButton from "components/common/MISFooterButton";
import { GIFT_TYPE, OCCASION_LIST } from "../businessCentralModel";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { MISCurrentUser } from "utils/validations";
import { useSaveSpocGiftMutation } from "api/BusinessCentral/saveSpocGift";
import { get } from "utils/lodash";
import T from "T";
import { Task } from "@mui/icons-material";
import { toast } from "react-toastify";
import MISButton from "components/common/MISButton";
import { ArrowBack } from "@mui/icons-material";
import MISAutocomplete from "components/common/MISAutocomplete";
import MISTextField from "components/common/MISTextField";
import { GET_SIZE } from "utils/responsive";
import { TitleTypography } from "utils/members";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import { handleError } from "utils/error";
import ConfirmReject from "../ConfirmReject";

const SubTitleTypography = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: 14,
  lineHeight: "22px",
  // whiteSpace: "nowrap",
}));

const GiftTableModal = ({
  showDialog = false,
  handleShowDialog = noop,
  handleEmptyTask = noop,
  data = null,
  refreshTable = noop,
}) => {
  const { isMd, isLg } = GET_SIZE();
  const { user } = MISCurrentUser();
  const userRole = get(user, "role", "");
  const [saveSpocGift] = useSaveSpocGiftMutation();
  const spocName = get(data, "spoc.name", "");
  const projectName = get(data, "projectId.name", "");
  const vicePresident = get(data, "projectId.projectFunctionalHead.name", "");
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    giftId: "",
    spocName: "",
    spocId: "",
    rejectedGiftId: "",
    occasion: "",
    giftType: "",
    customOccasion: "",
    comment: "",
    isRejectedOpen: false,
  });

  const { spocId, giftId, rejectedGiftId, isRejectedOpen, occasion, giftType, customOccasion, comment } = localState;
  useEffect(() => {
    if (showDialog) {
      setLocalState({
        giftId: get(data, "id", ""),
        spocId: get(data, "spoc.id", ""),
        occasion: [...OCCASION_LIST, ""].includes(get(data, "occasion", "")) ? get(data, "occasion", "") : T.OTHER,
        customOccasion: !OCCASION_LIST.includes(get(data, "occasion", "")) ? get(data, "occasion", "") : "",
        giftType: get(data, "giftType", ""),
      });
    }
  }, [showDialog]);

  const handleOpenReject = (giftId) => {
    setLocalState({ rejectedGiftId: giftId, isRejectedOpen: true });
  };
  const handleCloseReject = () => {
    setLocalState({ rejectedGiftId: "", isRejectedOpen: false });
  };
  const onHandleChange = (e) => {
    const { name, value } = e.target;
    setLocalState({ [name]: value });
  };
  const onHandleAutocompleteChange = (type, newValue) => {
    setLocalState({ [type]: newValue });
  };
  const handleBack = () => {
    setLocalState({ occasion: "", giftType: "", customOccasion: "" });
    handleShowDialog();
  };

  const validateForm = () => {
    if (!occasion) return false;
    if (occasion === T.OTHER && !customOccasion) return false;
    if (!giftType) return false;
    return true;
  };
  const handleSubmitGift = (id, type, comment) => {
    const submitStatus = userRole === T.COO ? (type === "reject" ? 5 : 4) : 2;
    const requestDto = [
      {
        id: id ? id : giftId,
        spocId: spocId,
        status: "Active",
        giftStatus: submitStatus,
        giftType: giftType,
        comment: comment ? comment : "",
        occasion: occasion !== T.OTHER ? occasion : customOccasion,
      },
    ];
    saveSpocGift({ requestDto })
      .unwrap()
      .then((res) => {
        handleBack();
        refreshTable();
      })
      .catch(handleError);
  };
  return (
    <Dialog open={showDialog} onClose={() => handleBack()} maxWidth={isLg ? "lg" : isMd ? "md" : "sm"}>
      <DialogTitle display={"inline-flex"} alignItems={"center"}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Box display="inline-flex" alignItems="center">
            {/* <IconButton onClick={() => handleBack()}>
              <ArrowBack />
            </IconButton> */}
            {spocName && `Gift to ${spocName}`}
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ minWidth: isLg ? "450px" : isMd ? "300px" : "sm", height: "inherit" }}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Box display="flex" whiteSpace="nowrap">
              <SubTitleTypography>{T.PROJECT}&nbsp;:&nbsp;</SubTitleTypography>
              <TitleTypography sx={{ color: NETSMARTZ_THEME_COLOR }}>{projectName}</TitleTypography>
            </Box>
            <Box display="flex" whiteSpace="nowrap">
              <SubTitleTypography>{T.VICE_PRESIDENT}&nbsp;:&nbsp;</SubTitleTypography>
              <SubTitleTypography>{vicePresident}</SubTitleTypography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <MISAutocomplete
              label={T.OCCASION}
              required
              fullWidth
              listDetails={OCCASION_LIST}
              value={occasion}
              getByLabelText={(option) => option}
              onHandleChange={(event, newValue) => onHandleAutocompleteChange("occasion", newValue)}
            />
          </Grid>
          {occasion === T.OTHER && (
            <Grid item md={6} xs={12}>
              <MISTextField
                label={T.CUSTOM_OCCASION}
                placeholder={T.CUSTOM_OCCASION}
                value={customOccasion}
                required
                autoComplete="on"
                size="small"
                variant="outlined"
                name="customOccasion"
                fullWidth
                onChange={(e) => onHandleChange(e)}
              />
            </Grid>
          )}
          <Grid item md={occasion === T.OTHER ? 12 : 6} xs={12}>
            <MISAutocomplete
              label={T.GIFT_TYPE}
              required
              fullWidth
              listDetails={GIFT_TYPE}
              value={giftType}
              getByLabelText={(option) => option}
              onHandleChange={(event, newValue) => onHandleAutocompleteChange("giftType", newValue)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 2 }}>
        <MISButton onClick={() => handleBack()}>{T.CANCEL}</MISButton>
        <MISButton disabled={!validateForm()} variant="contained" sx={{ mx: 2 }} onClick={() => handleSubmitGift()}>
          {T.ACCEPT}
        </MISButton>
        <MISButton variant="outlined" onClick={() => handleOpenReject(giftId)}>
          {T.REJECT}
        </MISButton>
      </DialogActions>
      <ConfirmReject
        comment={comment}
        handleChange={onHandleChange}
        openConfirmSubmit={isRejectedOpen}
        handleSubmit={() => {
          handleSubmitGift(rejectedGiftId, "reject", comment);
          handleCloseReject();
          handleBack();
          setLocalState({ comment: "" });
        }}
        handleClose={() => {
          handleCloseReject();
          setLocalState({ comment: "" });
        }}
      />
    </Dialog>
  );
};

export default GiftTableModal;
