import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    vendorFilter: build.mutation({
      query: ({ page, size, payload }) => ({
        url: `/vendor/get/all/filter/vendors?page=${page}&size=${size}`,
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const { useVendorFilterMutation } = extendedApi;
