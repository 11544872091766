import { createSlice } from "@reduxjs/toolkit";

const onBenchFilterSlice = createSlice({
  name: "onBenchFilterSlice",
  initialState: {
    storedFilters: {},
  },
  reducers: {
    onBenchFilterStore: (state, action) => {
      state.storedFilters = action.payload.storedFilters;
    },
  },
});
export const { onBenchFilterStore } = onBenchFilterSlice.actions;
export default onBenchFilterSlice.reducer;
