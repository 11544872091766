import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    deleteHardwareType: builder.mutation({
      query: (id) => ({
        url: `/hardwareType/delete?hardwareTypeId=${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const { useDeleteHardwareTypeMutation } = extendedApi;
