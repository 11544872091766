import { Box, Chip, Grid, Stack, Typography } from "@mui/material";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import { PROJECT_TYPE, PROJECT_TYPE_MAPPING } from "../businessCentralModel";
import PropTypes from "prop-types";
import T from "T";

const ProjectFilterChips = ({ selectedProjects, onChange }) => {
  const handleClick = (project) => {
    // logic for multiple chipClasses, commented for future purposes
    // const updatedProjects = selectedProjects.includes(project.value)
    //   ? selectedProjects.filter((selectedProject) => selectedProject !== project.value)
    //   : [...selectedProjects, project.value];
    // onChange(updatedProjects);

    if (selectedProjects.length === 1 && selectedProjects.includes(project.value)) {
      return;
    }

    const newStatus = selectedProjects.includes(project.value)
      ? selectedProjects.filter((selectedProject) => selectedProject !== project.value)
      : [project.value];

    onChange(newStatus);
  };

  return (
    <Box>
      <Grid container spacing={2} justifyContent={"center"}>
        {PROJECT_TYPE_MAPPING.map((project) => (
          <Grid item md={"auto"} xs={"auto"} alignContent={"center"}>
            {/* {project.projectTypeName === T.GIFT ? (
              <span style={{ marginLeft: "-5px", marginRight: "10px", verticalAlign: "middle" }}>{"|"}</span>
            ) : null} */}
            <Chip
              key={project.value}
              label={project.projectTypeName}
              clickable
              onClick={() => handleClick(project)}
              sx={{
                backgroundColor: selectedProjects.includes(project.value) ? NETSMARTZ_THEME_COLOR : "default",
                color: selectedProjects.includes(project.value) ? "white" : "default",
                transition: "background-color 0.3s ease, color 0.3s ease",
                "&:hover": {
                  backgroundColor: selectedProjects.includes(project.value) ? NETSMARTZ_THEME_COLOR : "default",
                  color: selectedProjects.includes(project.value) ? "white" : "default",
                },
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

ProjectFilterChips.propTypes = {
  selectedProjects: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ProjectFilterChips;
