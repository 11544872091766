import PropTypes from "prop-types";
import noop from "lodash/noop";

import { Table as TableView, Paper, TableContainer, Box, Card } from "@mui/material";

import CaseStudyTableBody from "./CaseStudyTableBody";
import TableHeader from "./TableHeader";
import MISNoData from "components/common/MISNoData";
import MasterPagination from "components/MasterSetting/MasterPagination";
import { CASE_STUDY_TABLE_HEADER } from "../caseStudyModel";

const CaseStudyTable = ({
  totalTableRowsCount,
  totalPageCount,
  page,
  rowsPerPage,
  allTableRows = [],
  onPageChange = noop,
  onRowsPerPageChange = noop,
  pName = "",
  refreshTable = noop,
  handleAddEditCaseStudyDialog = noop,
  handleCaseStudyUploadLinkDialog = noop,
  handleOpenCaseStudyUpload = noop,
  handleAcceptCaseStudyDialog = noop,
  onClickReminder = noop,
}) => (
  <Card
    elevation={0}
    sx={{
      // p: "0px 8px",
      mt: 1,
      backgroundColor: "background.card",
      "& .add-shadow": {
        boxShadow: "inset -6px 0px 5px -5px rgb(0 0 0 / 15%)",
      },
    }}
  >
    <TableContainer sx={{ height: "calc(100vh - 325px)", overflowY: "auto" }}>
      {allTableRows.length !== 0 ? (
        <TableView stickyHeader>
          <TableHeader columns={CASE_STUDY_TABLE_HEADER} />
          <CaseStudyTableBody
            allTableRows={allTableRows}
            handleAddEditCaseStudyDialog={handleAddEditCaseStudyDialog}
            pName={pName}
            refreshTable={refreshTable}
            handleOpenCaseStudyUpload={handleOpenCaseStudyUpload}
            handleCaseStudyUploadLinkDialog={handleCaseStudyUploadLinkDialog}
            handleAcceptCaseStudyDialog={handleAcceptCaseStudyDialog}
            onClickReminder={onClickReminder}
          />
        </TableView>
      ) : (
        <MISNoData />
      )}
    </TableContainer>
    <MasterPagination
      page={page}
      onPageChange={onPageChange}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={onRowsPerPageChange}
      totalTableRowsCount={totalTableRowsCount}
      totalPageCount={totalPageCount}
    />
  </Card>
);

CaseStudyTable.propTypes = {
  allTableRows: PropTypes.array,
  pName: PropTypes.string,
  refreshTable: PropTypes.func,
  handleFileUpload: PropTypes.func,
  handleAddEditCaseStudyDialog: PropTypes.func,
  handleCaseStudyUploadLinkDialog: PropTypes.func,
  handleAcceptCaseStudyDialog: PropTypes.func,
  onClickReminder: PropTypes.func,
};
export default CaseStudyTable;
