import { Children, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { noop, isEmpty, camelCase } from "lodash";
import { Typography, Divider, Card, Button, Box } from "@mui/material";
import MISAutocomplete from "components/common/MISAutocomplete";
import { ClickAwayListener } from "@mui/material";
import { NETSMARTZ_THEME_COLOR, BACKGROUND } from "theme/colors";
import { GET_SIZE } from "utils/responsive";
import T from "T";
import { get } from "utils/lodash";
import MISDatePicker from "components/common/MISDatePicker";
import { HARDWARE_FILTER_OPTIONS } from "../hardwareModel";
import { useLazyGetHardwareTypeListQuery } from "api/Hardware/ListMutation/getHardwareTypeList";
import { useLazyGetManufacturerListQuery } from "api/Hardware/ListMutation/getManufacturerList";
import { useLazyGetWorkLocationQuery } from "api/members/getWorkLocation";
import { getCurrentTableParams } from "data/members/memberTableSelectors";
import { handleError } from "utils/error";
const FULL_ROWS_PER_PAGE = 10000;
const INITIAL_PAGE = 0;

const HardwareFilterOptions = ({
  tabValue = "",
  filters = {},
  handleFilterClose = noop,
  handleFilterSubmit = noop,
  onhandleFilterChange = noop,
  onHandleDateFilterChange = noop,
  handleClose = noop,
}) => {
  const { isXs } = GET_SIZE();
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    hardwareTypeList: [],
    manufacturerList: [],
    workLocationList: [],
  });
  const { hardwareTypeList, manufacturerList, workLocationList } = localState;
  const [getHardwareTypeList] = useLazyGetHardwareTypeListQuery();
  const [getManufacturerList] = useLazyGetManufacturerListQuery();
  const [getWorkLocation] = useLazyGetWorkLocationQuery();
  useEffect(() => {
    getHardwareTypeListData();
    getManufacturerListData();
    getWorkLocationListData();
  }, []);
  const getListData = (getListFunction, listType) => {
    getListFunction({ page: INITIAL_PAGE, [listType != "workLocation" ? `rowsPerPage` : `rowPerPage`]: FULL_ROWS_PER_PAGE })
      .unwrap()
      .then((res) => {
        const { allTableRows } = getCurrentTableParams(res);
        setLocalState({ [`${listType}List`]: allTableRows });
      })
      .catch(handleError);
  };

  const getHardwareTypeListData = () => getListData(getHardwareTypeList, "hardwareType");
  const getManufacturerListData = () => getListData(getManufacturerList, "manufacturer");
  const getWorkLocationListData = () => getListData(getWorkLocation, "workLocation");

  const getFilterRecords = (val) => {
    switch (val) {
      case T.HARDWARE_TYPE:
        return hardwareTypeList;
      case T.MANUFACTURER:
        return manufacturerList;
      case T.WORK_LOCATION:
        return workLocationList;
      case T.STATUS:
        return ["Unassignment", "Assignment"];
      default:
    }
  };
  const getLabelData = (option, index) => {
    const optionVal = isEmpty(option) ? "" : option;
    switch (index) {
      case 0:
        return get(option, "type", "");
      case 1:
        return get(option, "name", "");
      case 2:
        return get(option, "workLocationName", "");
      case 5:
        return optionVal;
      default:
    }
  };
  const HARDWARE_FILTER_OPTIONS =
    tabValue === "1"
      ? [
          T.HARDWARE_TYPE,
          T.MANUFACTURER,
          T.WORK_LOCATION,
          T.WARRANTY_START_DATE,
          T.WARRANTY_END_DATE,
          T.PURCHASE_START_DATE,
          T.PURCHASE_END_DATE,
        ]
      : [T.HARDWARE_TYPE, T.MANUFACTURER, T.WORK_LOCATION, T.WARRANTY_START_DATE, T.WARRANTY_END_DATE, T.STATUS];
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Card
        sx={{
          mt: 1,
          position: "absolute",
          width: isXs ? "80%" : "27%",
          zIndex: 400,
        }}
      >
        <Box
          sx={{
            maxHeight: "calc(100vh - 250px)",
            overflowY: "auto",
          }}
        >
          <Typography variant="subtitle2" p={1} textAlign="center">
            {T.FILTERS}
          </Typography>
          <Divider />
          {Children.toArray(
            HARDWARE_FILTER_OPTIONS.map((item, index) => {
              const lists = getFilterRecords(item);
              const key = camelCase(item);
              return (
                <Box
                  display="flex"
                  p="10px 20px"
                  justifyContent="space-between"
                  pb={index === HARDWARE_FILTER_OPTIONS.length - 1 && "10px"}
                >
                  <Typography variant="subtitle1" noWrap>
                    {tabValue === "2" && [T.WARRANTY_START_DATE, T.WARRANTY_END_DATE].includes(item)
                      ? item.replace("Warranty", "Return")
                      : item}
                  </Typography>
                  {[T.WARRANTY_START_DATE, T.WARRANTY_END_DATE, T.PURCHASE_START_DATE, T.PURCHASE_END_DATE].includes(item) ? (
                    <MISDatePicker
                      width="200px"
                      inputFormat="DD/MM/YYYY"
                      name={key}
                      value={filters[key]}
                      handleChange={onHandleDateFilterChange}
                    />
                  ) : (
                    <MISAutocomplete
                      listDetails={lists}
                      size="small"
                      placeholder={T.SELECT}
                      sx={{
                        width: 200,
                      }}
                      value={filters[key]}
                      getByLabelText={(option) => getLabelData(option, index)}
                      onHandleChange={(event, newValue) => {
                        onhandleFilterChange(newValue, key);
                      }}
                    />
                  )}
                </Box>
              );
            }),
          )}
        </Box>
        <Divider />
        <Box display="flex" justifyContent="space-between" p={2}>
          <Button
            variant="outlined"
            size="small"
            sx={{
              borderColor: BACKGROUND.black,
              color: BACKGROUND.black,

              "&:hover": {
                borderColor: NETSMARTZ_THEME_COLOR,
                color: NETSMARTZ_THEME_COLOR,
              },
            }}
            onClick={() => handleFilterClose()}
          >
            {T.RESET}
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{
              bgcolor: NETSMARTZ_THEME_COLOR,
              "&:hover": {
                bgcolor: NETSMARTZ_THEME_COLOR,
              },
            }}
            onClick={handleFilterSubmit}
          >
            {T.APPLY}
          </Button>
        </Box>
      </Card>
    </ClickAwayListener>
  );
};

HardwareFilterOptions.propTypes = {
  filters: PropTypes.object,
  handleFilterClose: PropTypes.func,
  handleFilterSubmit: PropTypes.func,
  onhandleFilterChange: PropTypes.func,
  handleClose: PropTypes.func,
};

export default HardwareFilterOptions;
