import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateVendor: build.mutation({
      query: ({ id, payload }) => ({
        url: "/vendor/update/vendor?id=" + id,
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const { useUpdateVendorMutation } = extendedApi;
