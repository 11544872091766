import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    assignHardwareInvent: builder.mutation({
      query: (hardwareAssignmentDto) => ({
        url: `/hardware/assignHardware`,
        method: "POST",
        body: hardwareAssignmentDto,
      }),
    }),
  }),
});

export const { useAssignHardwareInventMutation } = extendedApi;
