import { Children } from "react";
import PropTypes from "prop-types";
import { TableRow, TableHead, TableCell, Typography } from "@mui/material";
import { get } from "utils/lodash";
import { BACKGROUND } from "theme/colors";
import T from "T";

const ResourseTableHeader = ({ columns = [] }) => {
  return (
    <TableHead>
      <TableRow sx={{ height: 20 }}>
        {Children.toArray(
          columns.map((column, ind) => {
            const columnName = get(column, "label", "").toUpperCase();
            return (
              <TableCell
                sx={{
                  border: "none",
                  backgroundColor: BACKGROUND.header,
                  paddingY: 0,
                  left: ind === 0 ? "0px" : "",
                  right: ind === columns.length - 1 ? "0px" : "",
                  position: ind === columns.length - 1 || ind === 0 ? "sticky" : "",
                  zIndex: ind === columns.length - 1 || ind === 0 ? +3 : "",
                }}
              >
                <Typography variant="body1" fontSize={13} noWrap fontWeight={600}>
                  {columnName}
                </Typography>
              </TableCell>
            );
          }),
        )}
      </TableRow>
    </TableHead>
  );
};

export default ResourseTableHeader;
