import { useEffect, useReducer } from "react";
import { Table as TableView, TableContainer, Box, Card, Paper } from "@mui/material";
import MasterPagination from "components/MasterSetting/MasterPagination";
import { HARDWARE_DETAIL_TABLE_HEADER, SORTING_KEYS } from "../hardwareModel";
import HardwareTableBody from "./HardwareTableBody";
import HardwareTableHeader from "./HardwareTableHeader";
import { orderBy } from "lodash";
import MISNoData from "components/common/MISNoData";

const HardwareTable = ({
  totalTableRowsCount,
  totalPageCount,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
  handleConfirmRevoke,
  assignStatus,
  allTableRows = [],
}) => {
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    activeSort: "",
    sortDirection: 0,
    renderedRow: [],
  });

  const { activeSort, sortDirection, renderedRow } = localState;

  useEffect(() => {
    setLocalState({ renderedRow: allTableRows, activeSort: "", sortDirection: 0 });
  }, [allTableRows]);

  const handleToolsSorting = (val) => {
    setLocalState({ activeSort: val });
    let current = sortDirection;
    if (current === 0) current = 1;
    else if (current === 1) current = -1;
    else if (current === -1) current = 0;
    if (activeSort != val) current = 1;
    setLocalState({ sortDirection: current });

    if (current === 0) {
      setLocalState({ renderedRow: allTableRows });
      return;
    }

    const order = current === 1 ? "asc" : "desc";
    const sortedRows = orderBy(allTableRows, [SORTING_KEYS.find((item) => item.key === val).value], [order]);
    setLocalState({ renderedRow: sortedRows });
  };

  return (
    <Card
      elevation={0}
      sx={{
        backgroundColor: "background.card",
        "& .add-shadow": {
          boxShadow: "inset -6px 0px 5px -5px rgb(0 0 0 / 15%)",
        },
      }}
    >
      <Box overflow="hidden">
        <TableContainer component={Paper} sx={{ height: "calc(100vh - 387px)", overflow: "auto" }}>
          {renderedRow.length !== 0 ? (
            <TableView stickyHeader>
              <HardwareTableHeader
                columns={HARDWARE_DETAIL_TABLE_HEADER}
                activeSort={activeSort}
                sortDirection={sortDirection}
                handleToolsSorting={handleToolsSorting}
              />
              <HardwareTableBody
                allTableRows={renderedRow}
                assignStatus={assignStatus}
                handleConfirmRevoke={handleConfirmRevoke}
              />
            </TableView>
          ) : (
            <MISNoData />
          )}
        </TableContainer>
      </Box>
      <MasterPagination
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={onRowsPerPageChange}
        totalTableRowsCount={totalTableRowsCount}
        totalPageCount={totalPageCount}
      />
    </Card>
  );
};

export default HardwareTable;
