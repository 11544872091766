import { createSlice } from "@reduxjs/toolkit";

const onBenchSavedFilterSlice = createSlice({
  name: "onBenchSavedFilterSlice",
  initialState: {
    storedFilterId: "",
  },
  reducers: {
    onBenchSavedFilterStore: (state, action) => {
      state.storedFilterId = action.payload.selectedFilterId;
    },
  },
});
export const { onBenchSavedFilterStore } = onBenchSavedFilterSlice.actions;

export default onBenchSavedFilterSlice.reducer;
