import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from "@mui/material";
import T from "T";
import { ArrowBack } from "@mui/icons-material";
import MISTextField from "components/common/MISTextField";
import MISAutocomplete from "components/common/MISAutocomplete";
import { get } from "utils/lodash";
import MISFooterButton from "components/common/MISFooterButton";

const AddEditSalesRepresentative = ({
  salesRepresentativeName,
  addEditSalesRepresentative,
  departmentsList,
  isAddEditModalOpen,
  onHandleChange,
  handleClose,
  onHandleSubmit,
}) => {
  return (
    <Dialog onClose={handleClose} open={isAddEditModalOpen}>
      <DialogTitle display={"inline-flex"} alignItems={"center"}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Box display="inline-flex" alignItems="center">
            <IconButton onClick={handleClose}>
              <ArrowBack />
            </IconButton>
            {salesRepresentativeName ? `${T.EDIT} ${salesRepresentativeName}` : `${T.ADD} ${T.NEW} ${T.SALES_REPRESENTATIVE}`}
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ height: "inherit" }}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={6}>
            <MISAutocomplete
              label={T.DEPARTMENT}
              required
              listDetails={departmentsList}
              value={departmentsList.find((item) => item.departmentName === addEditSalesRepresentative.department)}
              getByLabelText={(option) => get(option, "departmentName", "")}
              onHandleChange={(event, newValue) => onHandleChange(null, "department", get(newValue, "departmentName", ""))}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <MISTextField
              label={T.NAME}
              required
              placeholder={T.NAME}
              autoComplete="on"
              size="small"
              variant="outlined"
              name="name"
              value={addEditSalesRepresentative.name}
              onChange={(e) => onHandleChange(e)}
            />
          </Grid>
          <Grid item md={6} xs={6}>
            <MISTextField
              label={T.EMAIL}
              required
              placeholder={T.EMAIL}
              autoComplete="on"
              size="small"
              variant="outlined"
              name="email"
              value={addEditSalesRepresentative.email}
              onChange={(e) => onHandleChange(e)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <MISFooterButton
          proceedButtonText={addEditSalesRepresentative.id ? T.UPDATE : T.SUBMIT}
          justify="end"
          sx={{ pb: 0.5 }}
          handleClose={handleClose}
          handleSubmit={
            addEditSalesRepresentative.id ? () => onHandleSubmit(addEditSalesRepresentative.id) : () => onHandleSubmit()
          }
        />
      </DialogActions>
    </Dialog>
  );
};

export default AddEditSalesRepresentative;
