import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    saveProject1: builder.mutation({
      query: ({ requestDto }) => ({
        url: `/businessCentral/save`,
        body: requestDto,
        method: "POST",
      }),
    }),
  }),
});

export const { useSaveProject1Mutation } = extendedApi;
