import { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
/**
 * A custom hook to create and manage a Chart.js instance.
 * @param {React.RefObject<HTMLCanvasElement>} canvasRef - A ref to the canvas element where the chart will be rendered.
 * @param {Object} chartConfig - The configuration object for the Chart.js chart.
 */

const useChart = (canvasRef, chartConfig) => {
  const chartInstanceRef = useRef(null);

  useEffect(() => {
    if (!canvasRef?.current) return;

    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    const ctx = canvasRef.current.getContext("2d");
    if (ctx) {
      chartInstanceRef.current = new Chart(ctx, chartConfig);
    }

    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
        chartInstanceRef.current = null;
      }
    };
  }, [canvasRef, chartConfig]);

  return chartInstanceRef;
};

export default useChart;
