import { DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useAcknowledgeTaskMutation } from "api/taskbox/acknowledgeTask";
import MISDialog from "components/common/MISDialog";
import MISFooterButton from "components/common/MISFooterButton";
import React from "react";
import { useNavigate } from "react-router-dom";
import { handleError } from "utils/error";
import { MISCurrentUser } from "utils/validations";

const AcknowledgementModal = ({ showDialog, acknowledgement, handleShowDialog, handleEmptyTask, refreshTable }) => {
  const navigate = useNavigate();

  const { user } = MISCurrentUser();

  const [acknowledgeTask] = useAcknowledgeTaskMutation();

  console.log(user?.user, "haha");

  const handleSubmit = () => {
    const { task, taskId } = acknowledgement;
    acknowledgeTask({ taskId })
      .unwrap()
      .then((res) => {
        refreshTable();
        switch (task) {
          case "Feedback Acknowledgement":
            navigate("/app/employeeprofile");
            break;
          case "Sir Acknowledgment":
            navigate("/app/employeeprofile");
            break;
          case "Swot Acknowledgement":
            navigate("/app/employeeprofile");
            break;
          default:
            break;
        }
      })
      .catch(handleError);
  };

  return (
    <MISDialog open={showDialog} handleClose={handleShowDialog} disableEscapeKeyDown>
      <DialogTitle>
        <Typography variant="title">{"Acknowledgement"}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>{"I acknowledge the feedback given to me."}</Typography>
      </DialogContent>
      <DialogActions>
        <MISFooterButton handleSubmit={handleSubmit} handleClose={handleShowDialog} />
      </DialogActions>
    </MISDialog>
  );
};

export default AcknowledgementModal;
