import PropTypes from "prop-types";
import ResourseTable from "./ResourseTable";
import { Paper } from "@mui/material";

const ResourseDetail = ({
  usersList,
  rowIndex,
  activeColumn,
  getAllResourseList,
  addNewRow,
  removeRow,
  setActiveInput,
  onHandleTableChange,
  onHandleTableDateChange,
  onHandleTableAutoComplete,
}) => {
  return (
    <ResourseTable
      usersList={usersList}
      rowIndex={rowIndex}
      activeColumn={activeColumn}
      getAllResourseList={getAllResourseList}
      addNewRow={addNewRow}
      removeRow={removeRow}
      setActiveInput={setActiveInput}
      onHandleTableChange={onHandleTableChange}
      onHandleTableDateChange={onHandleTableDateChange}
      onHandleTableAutoComplete={onHandleTableAutoComplete}
    />
  );
};

export default ResourseDetail;
