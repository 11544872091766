import { Box, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { GET_SIZE } from "utils/responsive";
import GiftHistoryTable from "./GiftHistoryTable";
import { useEffect, useReducer } from "react";
import { useGetClientGiftsMutation } from "api/BusinessCentral/getClientGifts";
import { getCurrentTableParams } from "data/members/memberTableSelectors";
import { handleError } from "utils/error";

const GiftHistory = ({ isGiftHistoryOpen = false, spocName = "", spocId = "", handleClose = noop }) => {
  const { isMd, isLg } = GET_SIZE();
  const initialState = {
    columns: [
      { label: "Project Name", key: "projectId.name" },
      { label: "Vice President", key: "projectId.projectFunctionalHead.name" },
      { label: "Occasion", key: "occasion" },
      { label: "Gift Type", key: "giftType" },
      { label: "Created At", key: "createdAt" },
    ],
    giftHistory: [],
  };
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), initialState);
  const { columns, giftHistory } = localState;
  const [getClientGifts] = useGetClientGiftsMutation();
  useEffect(() => {
    const giftsFilterRequestDto = {
      statuses: [4],
      spocId: spocId,
      spocFilter: false,
    };
    isGiftHistoryOpen &&
      getClientGifts({ page: 0, rowsPerPage: 10000, giftsFilterRequestDto })
        .unwrap()
        .then((res) => {
          const { allTableRows } = getCurrentTableParams(res);
          setLocalState({ giftHistory: allTableRows });
        })
        .catch(handleError);
  }, [isGiftHistoryOpen]);
  const handleCloseModal = () => {
    handleClose();
    setLocalState({ giftHistory: [] });
  };
  return (
    <Dialog onClose={() => handleCloseModal()} open={isGiftHistoryOpen} maxWidth={isLg ? "lg" : isMd ? "md" : "sm"}>
      <DialogTitle display={"inline-flex"} alignItems={"center"}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Box display="inline-flex" alignItems="center">
            <IconButton onClick={() => handleCloseModal()}>
              <ArrowBack />
            </IconButton>
            {spocName && `Gift History of ${spocName}`}
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ minWidth: isLg ? "450px" : isMd ? "300px" : "sm", height: "inherit" }}>
        <GiftHistoryTable columns={columns} giftHistory={giftHistory} />
      </DialogContent>
    </Dialog>
  );
};

export default GiftHistory;
