import { useEffect, useReducer } from "react";
import { isEqual } from "lodash";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, Grid, Paper } from "@mui/material";
import TopBar from "./TopBar";
import T from "T";
import { PAGINATION } from "settings/constants/pagination";
import Table from "./Table";
import { get } from "utils/lodash";
import {
  businessCentralFilterStore,
  businessCentralTabValueStore,
  businessCentralSearchStore,
} from "slices/businessCentralFilterSlice";
import { useGetProjectFilterMutation } from "api/BusinessCentral/getProjectFilter";
import { useDeleteProject1Mutation } from "api/BusinessCentral/deleteProject";
import { handleError } from "utils/error";
import { getCurrentTableParams } from "data/members/memberTableSelectors";
import { OCCASION_LIST } from "./businessCentralModel";
import Gift from "./Gift";
import { useGetClientGiftsMutation } from "api/BusinessCentral/getClientGifts";
import { useSaveSpocGiftMutation } from "api/BusinessCentral/saveSpocGift";
import { MISCurrentUser } from "utils/validations";
import MISLoader from "components/common/MISLoader";
const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;

const BusinessCentral = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = MISCurrentUser();
  const userRole = get(user, "role", "");
  const userEmail = get(user, "username", "");
  const statusList = userRole === T.COO ? [2, 3] : [0, 1];

  // ############################## Stoered Data ################################
  const { storedFilters } = useSelector(
    (state) => ({
      storedFilters: get(state, "BusinessCentralFilterSlice.storedFilters", {}),
    }),
    shallowEqual,
  );
  const { storedTabValue } = useSelector(
    (state) => ({
      storedTabValue: get(state, "BusinessCentralFilterSlice.storedTabValue", []),
    }),
    shallowEqual,
  );
  const { storedSearch } = useSelector(
    (state) => ({
      storedSearch: get(state, "BusinessCentralFilterSlice.storedSearch", ""),
    }),
    shallowEqual,
  );

  const emptyFilters = {
    status: null,
    projectManager: null,
    vicePresident: null,
    giftStatus: null,
    projects: [],
  };

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    filters: emptyFilters,
    searchInput: "",
    showOptions: false,
    showCancelIcon: false,
    searchTableData: {},
    page: INITIAL_PAGE,
    rowsPerPage: ROWS_PER_PAGE,
    ProjectTableList: { allTableRows: [], totalTableRowsCount: null, totalPageCount: null },
    selectedProjects: [T.BYT],
    // Gifts Table states
    GiftTableList: { allTableRows: [], totalTableRowsCount: null, totalPageCount: null },
    gifts: [],
    selectedGift: [],
    allSelected: false,
    giftCount: [],
    isPresentGiftOpen: false,
    isGiftHistoryOpen: false,
    spocName: "",
    spocId: "",
    rejectedGiftId: "",
    isRejectedOpen: false,
    isConfirmSubmitOpen: false,
  });
  const {
    filters,
    searchTableData,
    searchInput,
    showOptions,
    showCancelIcon,
    selectedProjects,
    page,
    rowsPerPage,
    ProjectTableList,
    GiftTableList,
    gifts,
    selectedGift,
    allSelected,
    giftCount,
    isPresentGiftOpen,
    isGiftHistoryOpen,
    spocName,
    spocId,
    rejectedGiftId,
    isRejectedOpen,
    isConfirmSubmitOpen,
  } = localState;

  const [getProjectFilter, { isFetching: isProjectFetching, isLoading: isProjectLoading }] = useGetProjectFilterMutation();
  const [getClientGifts, { isFetching: isClientGiftFetching }] = useGetClientGiftsMutation();
  const [deleteProject1] = useDeleteProject1Mutation();
  const [saveSpocGift] = useSaveSpocGiftMutation();

  const isFilterEmpty = isEqual(storedFilters, emptyFilters);

  const handleAddProject = () => {
    navigate("add-project");
  };

  const transformGifts = (data) => {
    const { id, giftType, proposedGift, occasion, spoc, giftStatus, status } = data;
    return {
      id,
      giftType,
      proposedGift,
      giftStatus,
      occasion: [...OCCASION_LIST, null].includes(occasion) ? occasion : T.OTHER,
      spocId: spoc.id,
      status,
      customOccasion: OCCASION_LIST.includes(occasion) ? "" : occasion,
    };
  };

  // ################################ UseEffects ####################################
  useEffect(() => {
    setLocalState({ filters: storedFilters, selectedProjects: storedTabValue, searchInput: storedSearch });
    refreshTable(page, rowsPerPage, storedSearch, storedFilters, storedTabValue);
  }, [page, rowsPerPage, selectedProjects]);
  // ################################ Refresh Table Data ####################################
  const refreshTable = (page, rowsPerPage, search, filters, tabValue) => {
    ![T.GIFT].includes(selectedProjects[0])
      ? getProjectListTableData(page, rowsPerPage, search, filters, tabValue)
      : getGiftListTableData(page, rowsPerPage, search, filters, tabValue);
  };

  // ########################### Table Data API Functions ####################################
  const getProjectListTableData = (page, rowsPerPage, search, filters, tabValue) => {
    const projectFilterRequestDto = {
      status: get(filters, "status", ""),
      type: tabValue,
      text: search,
      managerName: get(filters, "projectManager.name", ""),
      functionalHead: get(filters, "vicePresident.name", ""),
    };
    getProjectFilter({ page, rowsPerPage, projectFilterRequestDto })
      .unwrap()
      .then((res) => {
        const { allTableRows, totalTableRowsCount, totalPageCount } = getCurrentTableParams(res);
        setLocalState({ ProjectTableList: { allTableRows, totalTableRowsCount, totalPageCount } });
      })
      .catch(handleError);
  };

  const getGiftListTableData = (page, rowsPerPage, search, filters) => {
    const { giftStatus, vicePresident, projects } = filters;
    const giftsFilterRequestDto = {
      projectName: projects.map((project) => project.name),
      functionalHeadEmail: userRole === T.COO ? get(vicePresident, "emailId", "") : userEmail,
      text: search,
      statuses: giftStatus ? giftStatus.value : userRole === T.COO ? [2, 3, 4, 5] : [0, 1, 2, 4, 5],
      spocFilter: true,
      role: userRole,
    };
    getClientGifts({ page, rowsPerPage, giftsFilterRequestDto })
      .unwrap()
      .then((res) => {
        const { allTableRows, totalTableRowsCount, totalPageCount } = getCurrentTableParams(res);
        setLocalState({
          GiftTableList: { allTableRows, totalTableRowsCount, totalPageCount },
          gifts: [...allTableRows.map(transformGifts)],
          giftCount: res.object,
        });
      })
      .catch(handleError);
  };
  // gifts: [...gifts, ...allTableRows.map(transformGifts)].filter(
  //   (gift, index, self) => index === self.findIndex((t) => t.id === gift.id),
  // ),
  // gifts: [...allTableRows.map(transformGifts)],

  const deleteProjectOnId = (id) => {
    deleteProject1({ id })
      .unwrap()
      .then((res) => {
        toast.success(T.PROJECT_DELETED_SUCCESSFULLY);
        handleCloseConfirmSubmit();
        refreshTable(page, rowsPerPage, storedSearch, storedFilters, storedTabValue);
      })
      .catch(handleError);
  };

  // ############################ Pagination Things ##############################
  const handleRowsPerPageChange = (event) => {
    const { value } = event.target;
    setLocalState({ page: INITIAL_PAGE, rowsPerPage: value, selectedGift: [], allSelected: false });
  };

  const handlePageChange = (newPage) => {
    setLocalState({ page: newPage, selectedGift: [], allSelected: false });
    document.getElementsByClassName("MuiTableContainer-root")[0].scrollTop = 0;
  };
  // ############################ Search Things ###################################
  const handleSearchChange = (event) => {
    const { value, dataset } = event.currentTarget;
    const searchValue = value || get(dataset, "val", "");
    setLocalState({
      showOptions: event.key !== "Enter",
      showCancelIcon: searchValue !== "",
      searchInput: searchValue,
    });
    if (event.key === "Enter" || event.currentTarget.nodeName === "svg" || searchValue === "") {
      const updatedTabValue =
        searchValue !== "" && !storedTabValue.includes(T.GIFT) ? [] : storedTabValue.length !== 0 ? storedTabValue : [T.BYT];
      setLocalState({ selectedProjects: updatedTabValue });
      dispatch(businessCentralSearchStore({ storedSearch: searchValue }));
      dispatch(businessCentralTabValueStore({ storedTabValue: updatedTabValue }));
      refreshTable(page, rowsPerPage, searchValue, storedFilters, updatedTabValue);
    }
  };

  const handleSearchClick = (value) => {
    setLocalState({
      showOptions: false,
      showCancelIcon: false,
      searchInput: value,
    });
  };

  const handleSearchKeyChange = () => {
    const updatedTabValue =
      searchInput !== "" && !storedTabValue.includes(T.GIFT) ? [] : storedTabValue.length !== 0 ? storedTabValue : [T.BYT];
    setLocalState({
      page: INITIAL_PAGE,
      selectedProjects: updatedTabValue,
    });
    dispatch(businessCentralSearchStore({ storedSearch: searchInput }));
    dispatch(businessCentralTabValueStore({ storedTabValue: updatedTabValue }));
    refreshTable(INITIAL_PAGE, rowsPerPage, searchInput, storedFilters, updatedTabValue);
  };

  const handleClickOutside = () => {
    setLocalState({
      showOptions: false,
    });
  };

  // ################################ Fiter things ####################################
  const onhandleFilterChange = (newValue, item) => {
    setLocalState({ filters: { ...filters, [item]: newValue } });
  };

  const handleFilterSubmit = () => {
    dispatch(businessCentralFilterStore({ storedFilters: filters }));
    refreshTable(page, rowsPerPage, storedSearch, filters, storedTabValue);
  };

  const handleFilterClose = () => {
    dispatch(businessCentralFilterStore({ storedFilters: emptyFilters }));
    setLocalState({ filters: emptyFilters });
    refreshTable(page, rowsPerPage, storedSearch, emptyFilters, storedTabValue);
  };

  const handleChipChange = (updatedProjects) => {
    // dispatch(businessCentralFilterStore({ storedFilters: emptyFilters }));
    dispatch(businessCentralTabValueStore({ storedTabValue: updatedProjects }));
    setLocalState({ page: INITIAL_PAGE, selectedProjects: updatedProjects, searchInput: storedSearch, filters: storedFilters });
    // refreshTable(page, rowsPerPage, "", emptyFilters);
  };
  // ############################ Gift Things ####################################
  const handleSelectGifts = (event, giftId) => {
    const updatedArr = [...selectedGift].includes(giftId)
      ? [...selectedGift].filter((id) => id !== giftId)
      : [...selectedGift, giftId];
    setLocalState({
      selectedGift: updatedArr,
      allSelected: updatedArr.length === GiftTableList.allTableRows.filter((item) => statusList.includes(item.giftStatus)).length,
    });
  };

  const handleSelectAll = (event) => {
    const { checked } = event.target;
    setLocalState({
      allSelected: checked,
      selectedGift: checked
        ? GiftTableList.allTableRows.filter((item) => statusList.includes(item.giftStatus)).map((gift) => gift.id)
        : [],
    });
  };

  const onHandleTableChange = (event, giftId) => {
    const { name, value } = event.target;
    let data = [...gifts];
    const giftIndex = gifts.findIndex((item) => item.id === giftId);
    data[giftIndex] = { ...data[giftIndex], id: giftId, [name]: value };
    setLocalState({ gifts: data });
  };

  const onHandleTableAutoComplete = (newValue, giftId, type) => {
    let data = [...gifts];
    const giftIndex = gifts.findIndex((item) => item.id === giftId);
    data[giftIndex] = {
      ...data[giftIndex],
      id: giftId,
      [type]: newValue,
      ...(type !== "giftType" ? { customOccasion: null } : {}),
    };
    setLocalState({ gifts: data });
  };

  const handleSaveAsDraft = () => {
    const saveStatus = userRole === T.COO ? 3 : 1;
    const requestDto = gifts
      .filter((item) => selectedGift.includes(item.id))
      .map((item) => ({
        ...item,
        giftStatus: saveStatus,
        occasion: item.occasion !== T.OTHER ? item.occasion : item.customOccasion,
      }));
    saveSpocGift({ requestDto })
      .unwrap()
      .then((res) => {
        toast.success(T.GIFT_SAVED_SUCCESSFULLY);
        setLocalState({ allSelected: false, selectedGift: [] });
        refreshTable(page, rowsPerPage, storedSearch, storedFilters, storedTabValue);
      })
      .catch(handleError);
  };

  const handleSubmit = () => {
    const submitStatus = userRole === T.COO ? 4 : 2;
    const requestDto = gifts
      .filter((item) => selectedGift.includes(item.id))
      .map((item) => ({
        ...item,
        giftStatus: submitStatus,
        occasion: item.occasion !== T.OTHER ? item.occasion : item.customOccasion,
      }));
    saveSpocGift({ requestDto })
      .unwrap()
      .then((res) => {
        toast.success(userRole === T.COO ? T.GIFT_APPROVED_SUCCESSFULLY : T.GIFT_SUBMIT_SUCCESSFULLY);
        setLocalState({ allSelected: false, selectedGift: [] });
        refreshTable(page, rowsPerPage, storedSearch, storedFilters, storedTabValue);
      })
      .catch(handleError);
  };

  const handleGiftModalOpen = (name, id) => {
    setLocalState({ isPresentGiftOpen: true, spocName: name, spocId: id });
  };
  const handleGiftModalClose = () => {
    setLocalState({ isPresentGiftOpen: false, spocName: "", spocId: "" });
  };

  const handleGiftHistoryOpen = (name, id) => {
    setLocalState({ isGiftHistoryOpen: true, spocName: name, spocId: id });
  };
  const handleGiftHistoryClose = () => {
    setLocalState({ isGiftHistoryOpen: false, spocName: "", spocId: "" });
  };

  const handleChangeStatus = (id, type, comment) => {
    const submitStatus = userRole === T.COO ? (type === "reject" ? 5 : 4) : 2;
    const selectedGift = gifts.find((item) => item.id === id);
    const requestDto = [
      {
        ...selectedGift,
        giftStatus: submitStatus,
        giftType: selectedGift.giftType,
        comment: comment ? comment : "",
        occasion: selectedGift.occasion !== T.OTHER ? selectedGift.occasion : selectedGift.customOccasion,
      },
    ];
    saveSpocGift({ requestDto })
      .unwrap()
      .then((res) => {
        toast.success(type === "accept" ? "Gift Accepted" : "Gift Rejected");
        setLocalState({ allSelected: false, selectedGift: [] });
        refreshTable(page, rowsPerPage, storedSearch, storedFilters, storedTabValue);
      })
      .catch(handleError);
  };

  const handleOpenReject = (giftId) => {
    setLocalState({ rejectedGiftId: giftId, isRejectedOpen: true });
  };
  const handleCloseReject = () => {
    setLocalState({ rejectedGiftId: "", isRejectedOpen: false });
  };
  // ############################## Confirm Submit ##########################
  const handleOpenConfirmSubmit = () => {
    setLocalState({ isConfirmSubmitOpen: true });
  };
  const handleCloseConfirmSubmit = () => {
    setLocalState({ isConfirmSubmitOpen: false });
  };
  // ############################ Refresh Data ############################
  const refreshData = () => {
    refreshTable(page, rowsPerPage, storedSearch, storedFilters, storedTabValue);
  };

  // ################################ Render ##############################
  return (
    <Paper display={"block"} sx={{ borderRadius: 2, p: 2 }}>
      {(isProjectFetching || isProjectLoading || isClientGiftFetching) && <MISLoader />}
      <Box
        sx={{
          "& .MuiTabPanel-root": {
            p: 2,
            pt: 0,
          },
        }}
      >
        <TopBar
          userRole={userRole}
          selectedProjects={selectedProjects}
          isFilterEmpty={isFilterEmpty}
          filters={filters}
          searchInput={searchInput}
          showOptions={showOptions}
          showCancelIcon={showCancelIcon}
          searchTableData={searchTableData}
          handleChange={handleSearchChange}
          onClick={handleSearchClick}
          handleKeyChange={handleSearchKeyChange}
          onClickOutside={handleClickOutside}
          handleAddProject={handleAddProject}
          handleChipChange={handleChipChange}
          onhandleFilterChange={onhandleFilterChange}
          handleFilterSubmit={handleFilterSubmit}
          handleFilterClose={handleFilterClose}
        />
        {![T.GIFT].includes(selectedProjects[0]) ? (
          <Table
            allTableRows={ProjectTableList.allTableRows}
            totalTableRowsCount={ProjectTableList.totalTableRowsCount}
            totalPageCount={ProjectTableList.totalPageCount}
            page={page}
            rowsPerPage={rowsPerPage}
            deleteProjectOnId={deleteProjectOnId}
            isConfirmSubmitOpen={isConfirmSubmitOpen}
            handleOpenConfirmSubmit={handleOpenConfirmSubmit}
            handleCloseConfirmSubmit={handleCloseConfirmSubmit}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        ) : (
          <Gift
            allTableRows={GiftTableList.allTableRows}
            totalTableRowsCount={GiftTableList.totalTableRowsCount}
            totalPageCount={GiftTableList.totalPageCount}
            page={page}
            rowsPerPage={rowsPerPage}
            gifts={gifts}
            giftCount={giftCount}
            selectedGift={selectedGift}
            allSelected={allSelected}
            handleSelectGifts={handleSelectGifts}
            handleSelectAll={handleSelectAll}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            onHandleTableChange={onHandleTableChange}
            onHandleTableAutoComplete={onHandleTableAutoComplete}
            handleSaveAsDraft={handleSaveAsDraft}
            handleSubmit={handleSubmit}
            isPresentGiftOpen={isPresentGiftOpen}
            isGiftHistoryOpen={isGiftHistoryOpen}
            spocName={spocName}
            spocId={spocId}
            rejectedGiftId={rejectedGiftId}
            isRejectedOpen={isRejectedOpen}
            handleGiftModalOpen={handleGiftModalOpen}
            handleGiftModalClose={handleGiftModalClose}
            handleGiftHistoryOpen={handleGiftHistoryOpen}
            handleGiftHistoryClose={handleGiftHistoryClose}
            handleChangeStatus={handleChangeStatus}
            handleOpenReject={handleOpenReject}
            handleCloseReject={handleCloseReject}
            refreshData={refreshData}
          />
        )}
      </Box>
    </Paper>
  );
};

export default BusinessCentral;
