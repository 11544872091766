import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getManufacturerById: builder.query({
      query: (id) => ({
        url: `/manufacturer/get/id?id=${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyGetManufacturerByIdQuery } = extendedApi;
