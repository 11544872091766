import { createSlice } from "@reduxjs/toolkit";

const onBenchActiveSlice = createSlice({
  name: "onBenchActiveSlice",
  initialState: {
    storedActive: true,
  },
  reducers: {
    onBenchActiveStore: (state, action) => {
      state.storedActive = action.payload.storedActive;
    },
  },
});
export const { onBenchActiveStore } = onBenchActiveSlice.actions;

export default onBenchActiveSlice.reducer;
