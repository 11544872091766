import { Children } from "react";
import PropTypes from "prop-types";
import { noop, isEmpty, camelCase, sortBy, orderBy } from "lodash";
import { Typography, Divider, Card, Button, Box } from "@mui/material";
import MISAutocomplete from "components/common/MISAutocomplete";
import { ClickAwayListener } from "@mui/material";
import { NETSMARTZ_THEME_COLOR, BACKGROUND } from "theme/colors";
import { GET_SIZE } from "utils/responsive";
import T from "T";
import {
  BUSINESS_CENTRAL_FILTER_OPTIONS,
  GIFT_FILTER_OPTIONS,
  GIFT_STATUS,
  PROJECT_STATUS,
} from "components/BusinessCentral/businessCentralModel";
import { get } from "utils/lodash";
import usePMFetch from "hooks/usePMFetch";

const FilterOptions = ({
  userRole,
  filters,
  selectedProjects,
  handleFilterClose = noop,
  handleFilterSubmit = noop,
  onhandleFilterChange = noop,
  handleClose = noop,
}) => {
  const { isXs } = GET_SIZE();
  const { projectManagers, functionalManagers, projectList } = usePMFetch();
  const projectManagerList = get(projectManagers, "results", []);
  const functionalManagerList = get(functionalManagers, "results", []);
  const projects = get(projectList, "results", []);
  const occurrenceCount = {};
  for (let proj of projects) {
    if (occurrenceCount[proj.name]) {
      occurrenceCount[proj.name]++;
    } else {
      occurrenceCount[proj.name] = 1;
    }
  }
  const getFilterRecords = (val) => {
    switch (val) {
      case T.STATUS:
        return PROJECT_STATUS;
      case T.PROJECT_MANAGER:
        return orderBy(projectManagerList, ["name"], ["asc"]);
      case T.VICE_PRESIDENT:
        return orderBy(functionalManagerList, ["name"], ["asc"]);
      case T.GIFT_STATUS:
        return GIFT_STATUS;
      case T.PROJECTS:
        return orderBy(projects, ["name"], ["asc"]);
      default:
    }
  };
  const getLabelData = (option, val) => {
    const optionVal = isEmpty(option) ? "" : option;

    switch (val) {
      case T.STATUS:
        return optionVal;
      case T.PROJECT_MANAGER:
        return get(option, "name", "");
      case T.VICE_PRESIDENT:
        return get(option, "name", "");
      case T.GIFT_STATUS:
        return get(option, "name", "");
      case T.PROJECTS:
        return occurrenceCount[option.name] === 1
          ? `${option.name}`
          : `${option.name} | ${option.department} | ${option.projectType}`;
      default:
    }
  };
  const FILTER_LIST = ![T.GIFT].includes(selectedProjects[0])
    ? BUSINESS_CENTRAL_FILTER_OPTIONS
    : userRole === T.COO
    ? GIFT_FILTER_OPTIONS
    : GIFT_FILTER_OPTIONS.slice(0, 2);
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Card
        sx={{
          mt: 1,
          position: "absolute",
          width: isXs ? "80%" : "25%",
          zIndex: 400,
        }}
      >
        <Box
          sx={{
            maxHeight: "calc(100vh - 250px)",
            overflowY: "auto",
          }}
        >
          <Typography variant="subtitle1" p={1} textAlign="center">
            {T.FILTER}
          </Typography>
          <Divider />

          {Children.toArray(
            FILTER_LIST.map((item, index) => {
              const lists = getFilterRecords(item);
              const key = camelCase(item);
              const multiple = [T.PROJECTS].includes(item);
              return (
                <Box
                  display="flex"
                  p="10px 20px"
                  justifyContent="space-between"
                  pb={index === FILTER_LIST.length - 1 ? "10px" : "0px"}
                >
                  <Typography variant="subtitle1" noWrap>
                    {item}
                  </Typography>
                  <MISAutocomplete
                    multiple={multiple}
                    listDetails={lists}
                    size="small"
                    placeholder={T.SELECT}
                    sx={{
                      width: 185,
                    }}
                    value={filters[key]}
                    getByLabelText={(option) => getLabelData(option, item)}
                    onHandleChange={(event, newValue) => {
                      onhandleFilterChange(newValue, key);
                    }}
                  />
                </Box>
              );
            }),
          )}
        </Box>
        <Divider />
        <Box display="flex" justifyContent="space-between" p={2}>
          <Button
            variant="outlined"
            size="small"
            sx={{
              borderColor: BACKGROUND.black,
              color: BACKGROUND.black,

              "&:hover": {
                borderColor: NETSMARTZ_THEME_COLOR,
                color: NETSMARTZ_THEME_COLOR,
              },
            }}
            onClick={handleFilterClose}
          >
            {T.RESET}
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{
              bgcolor: NETSMARTZ_THEME_COLOR,
              "&:hover": {
                bgcolor: NETSMARTZ_THEME_COLOR,
              },
            }}
            onClick={handleFilterSubmit}
          >
            {T.APPLY}
          </Button>
        </Box>
      </Card>
    </ClickAwayListener>
  );
};

FilterOptions.propTypes = {
  skillList: PropTypes.object,
  status: PropTypes.array,
  clientName: PropTypes.array,
  pMResults: PropTypes.array,
  filters: PropTypes.object,
  onhandleFilterChange: PropTypes.func,
  handleFilterSubmit: PropTypes.func,
  handleFilterClose: PropTypes.func,
  handleClose: PropTypes.func,
};

export default FilterOptions;
