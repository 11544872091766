import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    viewHardware: build.query({
      query: () => ({
        url: "/hardware/view",
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyViewHardwareQuery } = extendedApi;
