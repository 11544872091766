import { Children, useReducer } from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import { Box, TableRow, TableCell, Typography, TableBody, Tooltip, styled, IconButton, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SubTitleTypography, TitleTypography, StyledContentCopyIcon } from "utils/members";
import { BACKGROUND, BORDER, NETSMARTZ_THEME_COLOR } from "theme/colors";
import { get } from "utils/lodash";
import EditIcon from "@mui/icons-material/Edit";
import ReplyIcon from "@mui/icons-material/Reply";
import { getFEDateFormat } from "utils/members";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderTop: "inherit",
  overflow: "hidden",
  padding: "8px 0px 8px 8px",
  ...theme.typography.subtitle1,

  "&:last-child": {
    padding: "8px 0px 8px 0px",
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: BACKGROUND.white,
  },
  "&:nth-of-type(even)": {
    backgroundColor: BACKGROUND.cardDefault,
  },
}));

const HardwareTableBody = ({ allTableRows = [], assignStatus = false, handleConfirmRevoke = noop }) => {
  const navigate = useNavigate();
  const handleEditHardware = (id, serialNumber) => {
    navigate(`/app/hardware/${id}/edit`, { state: { serialNumber } });
  };

  return (
    <TableBody>
      {Children.toArray(
        allTableRows.map((record, index) => {
          const { hardwareID, hardwareType, manufacturer, model, serialNumber, purchaseDate, warrantyEndDate, workLocation } =
            record;
          return (
            <StyledTableRow key={index}>
              <StyledTableCell>{get(hardwareType, "type", "")}</StyledTableCell>
              <StyledTableCell>{get(manufacturer, "name", "")}</StyledTableCell>
              <StyledTableCell>{model}</StyledTableCell>
              <StyledTableCell>{serialNumber}</StyledTableCell>
              <StyledTableCell>{getFEDateFormat(purchaseDate)}</StyledTableCell>
              <StyledTableCell>{getFEDateFormat(warrantyEndDate)}</StyledTableCell>
              <StyledTableCell>{get(workLocation, "workLocationName", "")}</StyledTableCell>
              <StyledTableCell>
                <Tooltip title={""} placement="top">
                  <IconButton sx={{ color: NETSMARTZ_THEME_COLOR }} onClick={() => handleEditHardware(hardwareID, serialNumber)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                {assignStatus && (
                  <Tooltip title={"Revoke"} placement="top">
                    <IconButton sx={{ color: NETSMARTZ_THEME_COLOR }} onClick={() => handleConfirmRevoke(hardwareID)}>
                      <ReplyIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </StyledTableCell>
            </StyledTableRow>
          );
        }),
      )}
    </TableBody>
  );
};

export default HardwareTableBody;
