import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getClientGifts: builder.mutation({
      query: ({ page, rowsPerPage, giftsFilterRequestDto }) => ({
        url: `/businessCentralGift/filter?page=${page}&size=${rowsPerPage}`,
        method: "POST",
        body: giftsFilterRequestDto,
      }),
    }),
  }),
});

export const { useGetClientGiftsMutation } = extendedApi;
