import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllVendors: builder.query({
      query: ({ page, size }) => ({
        url: `/vendor/get/all/vendors?page=${page}&size=${size}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyGetAllVendorsQuery } = extendedApi;

