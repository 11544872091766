// rough component of casestudy
import { useEffect, useReducer } from "react";
import { Paper, Box, Typography } from "@mui/material";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import TopBar from "components/CaseStudy/TopBar";
import { handleError } from "utils/error";
import { MISCurrentUser } from "utils/validations";
import { getCurrentTableParams } from "data/members/memberTableSelectors";
import { PAGINATION } from "settings/constants/pagination";
import { get } from "utils/lodash.js";
import { isEqual } from "lodash";
import { useUpdateAcceptRejectCaseStudyMutation } from "api/CaseStudy/UpdateAcceptRejectCaseStudy";
import { caseStudyFilterStore } from "slices/caseStudyFilterSlice.js";
import { toast } from "react-toastify";
import T from "T.js";
import { useLazyGetReminderQuery } from "api/CaseStudy/ReminderButtonAPI.js";
import { useGetCaseStudyByFilterMutation } from "api/CaseStudy/getCaseStudyByFilter.js";
import AcceptRejectCaseStudy from "./CaseStudyProjects/AcceptRejectCaseStudy.js";
import CaseStudyUploadLinkModal from "./CaseStudyProjects/CaseStudyUploadLinkModal.js";
import AddEditCaseStudy from "./CaseStudyProjects/AddEditCaseStudy.js";
import CaseStudyProjects from "./CaseStudyProjects/index.js";
import CaseStudyUploadFileModal from "./CaseStudyProjects/CaseStudyUploadFileModal.js";
import usePMFetch from "hooks/usePMFetch.js";
import Constants from "Constants";
import MISLoader from "components/common/MISLoader.js";
import { downloadFile } from "utils/file.js";
import { useLazyGetProjectQuery } from "api/CaseStudy/getProject.js";
import { store } from "providers/store.js";
import { useNavigate } from "react-router-dom";
import { badgeStore } from "slices/badgeSlice";

const FULL_ROWS_PER_PAGE = 10000;

const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;

const CaseStudy = () => {
  const { user, sessionToken } = MISCurrentUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { projectManagers, functionalManagers } = usePMFetch();
  const userRole = get(user, "role", "");
  const userId = get(user, "id", "");
  const { SERVER_URL } = Constants;

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    pId: "",
    filters: { projects: [], projectManager: null, functionalManager: null, status: null },
    editId: "",
    skillEditId: "",
    locationEditId: "",
    projectId: "",
    skillId: "",
    comments: "",
    locationId: "",
    startDate: null,
    endDate: null,
    searchInput: "",
    pName: "",
    openAddEditCaseStudy: false,
    openCaseStudyLink: false,
    openCaseStudyUpload: false,
    role: "",
    confirmation: "",
    openRejectCaseStudy: false,
    exportLoading: false,
    page: INITIAL_PAGE,
    rowsPerPage: ROWS_PER_PAGE,
    projectList: [],
    CaseStudyTableData: { allTableRows: [], totalTableRowsCount: null, totalPageCount: null },
  });

  const {
    pId,
    filters,
    editId,
    startDate,
    endDate,
    searchInput,
    pName,
    openRejectCaseStudy,
    exportLoading,
    openAddEditCaseStudy,
    openCaseStudyLink,
    page,
    rowsPerPage,
    CaseStudyTableData,
    openCaseStudyUpload,
    role,
    confirmation,
    projectList,
  } = localState;

  const [UpdateAcceptRejectCaseStudy] = useUpdateAcceptRejectCaseStudyMutation();
  const [getReminder] = useLazyGetReminderQuery();
  const [getCaseStudyByFilter, { isFetching: isCaseStudyFetching }] = useGetCaseStudyByFilterMutation();
  const [getProject] = useLazyGetProjectQuery();
  const emptyFilters = { projects: [], projectManager: null, functionalManager: null, status: null };

  const { storedFilters } = useSelector(
    (state) => ({
      storedFilters: get(state, "CaseStudyFilterSlice.storedFilters", {}),
    }),
    shallowEqual,
  );
  const { storedSearch } = useSelector(
    (state) => ({
      storedSearch: get(state, "CaseStudyFilterSlice.storedSearch", ""),
    }),
    shallowEqual,
  );

  const isFilterEmpty = isEqual(storedFilters, emptyFilters);

  const projectManagersList = get(projectManagers, "results", []);
  const functionalManagersList = get(functionalManagers, "results", []);

  useEffect(() => {
    getProject()
      .unwrap()
      .then((res) => {
        setLocalState({ projectList: res });
      })
      .catch(handleError);
    setLocalState({
      filters: storedFilters,
      searchInput: storedSearch,
    });
    refreshTable(page, rowsPerPage, storedSearch, storedFilters);
  }, [page, rowsPerPage]);

  const refreshTable = (page, rowsPerPage, search, filters) => {
    getCaseStudyTableData(page, rowsPerPage, search, filters);
  };

  const getCaseStudyTableData = (page, rowsPerPage, search, filters) => {
    const caseStudyFilterRequestDto = {
      projectIds: get(filters, "projects", []).map((item) => item.id),
      projectManagerId: get(filters, "projectManager.id", null),
      projectFunctionalHead: get(filters, "functionalManager.id", null),
      caseStudyStatus: get(filters, "status", null),
      text: search,
    };
    getCaseStudyByFilter({ page, rowsPerPage, caseStudyFilterRequestDto })
      .unwrap()
      .then((res) => {
        const { allTableRows, totalTableRowsCount, totalPageCount } = getCurrentTableParams(res);
        setLocalState({ CaseStudyTableData: { allTableRows, totalTableRowsCount, totalPageCount } });
      })
      .catch(handleError);
  };
  // ############################ Export File Things ##############################
  const handleExport = (exportFilter) => {
    setLocalState({ exportLoading: true });
    const requestDto = { ...exportFilter };
    fetch(`${SERVER_URL}/caseStudy/export`, {
      method: "POST",
      body: JSON.stringify(requestDto),
      headers: {
        Authorization: `Bearer ${sessionToken}`,
        "content-type": "application/json",
      },
    })
      .then((res) => res.blob())
      .then((response) => {
        downloadFile(response, T.CSV);
        toast.success("CSV Downloaded Sucessfully");
        setLocalState({ exportLoading: false });
      })
      .catch(handleError);
  };
  // ############################ Pagination Things ##############################
  const handleRowsPerPageChange = (event) => {
    const { value } = event.target;
    setLocalState({ page: INITIAL_PAGE, rowsPerPage: value });
  };

  const handlePageChange = (newPage) => {
    setLocalState({ page: newPage });
    document.getElementsByClassName("MuiTableContainer-root")[0].scrollTop = 0;
  };

  // ############################ Search Things ###################################
  const handleSearchChange = (event) => {
    const { value, dataset } = event.currentTarget;
    const searchValue = value || get(dataset, "val", "");
    setLocalState({
      searchInput: searchValue,
    });
    if (event.key === "Enter" || event.currentTarget.nodeName === "svg" || searchValue === "") {
      dispatch(caseStudyFilterStore({ storedSearch: "", storedFilters: storedFilters }));
      refreshTable(page, rowsPerPage, searchValue, storedFilters);
    }
  };

  const handleSearchKeyChange = () => {
    setLocalState({
      page: INITIAL_PAGE,
    });
    dispatch(caseStudyFilterStore({ storedSearch: searchInput, storedFilters: storedFilters }));
    refreshTable(INITIAL_PAGE, rowsPerPage, searchInput, filters);
  };

  // ############################ Filter Things ###################################
  const onhandleFilterChange = (newValue, item) => {
    setLocalState({ filters: { ...filters, [item]: newValue } });
  };

  const handleFilterSubmit = () => {
    dispatch(caseStudyFilterStore({ storedSearch: storedSearch, storedFilters: filters }));
    refreshTable(page, rowsPerPage, storedSearch, filters);
  };

  const handleFilterClose = () => {
    dispatch(caseStudyFilterStore({ storedSearch: storedSearch, storedFilters: emptyFilters }));
    setLocalState({ filters: emptyFilters });
    refreshTable(page, rowsPerPage, storedSearch, emptyFilters);
  };

  // ##################################################################################################
  const refreshOnChange = () => {
    refreshTable(page, rowsPerPage, storedSearch, storedFilters);
  };

  const handleAddEditCaseStudyDialog = (id = "") => {
    if (openAddEditCaseStudy) {
      refreshTable(page, rowsPerPage, storedSearch, storedFilters);
    }
    setLocalState({
      openAddEditCaseStudy: !openAddEditCaseStudy,
      editId: id,
    });
  };

  const handleCaseStudyUploadLinkDialog = (id = "", pName) => {
    setLocalState({
      openCaseStudyLink: !openCaseStudyLink,
      pName,
      pId: id,
    });
  };

  const handleOpenCaseStudyUpload = (id, publish, type) => {
    setLocalState({ openCaseStudyUpload: true, pId: id, role: publish ? T.KRA : userRole, confirmation: type });
  };

  const handleCloseCaseStudyUpload = () => {
    setLocalState({ openCaseStudyUpload: false, pId: "", role: "", confirmation: "" });
  };

  // const handleStatus = (pId = "") => {
  //   const payload = {
  //     caseStudyApproval: true,
  //   };
  //   UpdateAcceptRejectCaseStudy({ payload, pId })
  //     .unwrap()
  //     .then(() => {
  //       refreshTable(page, rowsPerPage, storedSearch, storedFilters);
  //       toast.success(T.CASE_STUDY_ACCEPTED);
  //     })
  //     .catch(handleError);
  // };

  const handleAcceptCaseStudyDialog = (pId = "", type) => {
    if (type === "accept") {
      const payload = {
        caseStudyApproval: true,
      };
      UpdateAcceptRejectCaseStudy({ payload, pId })
        .unwrap()
        .then((res) => {
          const state = store.getState();
          let badge = get(state, "BadgeSlice.storedBadge", []);
          const response = get(res, "results", {});
          badge = badge.some((module) => module.moduleName === response.moduleName)
            ? badge.map((module) => (module.moduleName === response.moduleName ? { ...module, count: response.count } : module))
            : [...badge, response];
          dispatch(badgeStore({ storedBadge: badge }));
          navigate("/app/case-study");
          refreshTable(page, rowsPerPage, storedSearch, storedFilters);
          toast.success(T.CASE_STUDY_ACCEPTED);
        })
        .catch(handleError);
    }
  };

  const onClickReminder = (caseStudyId) => {
    getReminder(caseStudyId)
      .unwrap()
      .then((res) => {
        toast.success(T.REMINDER_SENT);
      })
      .catch(handleError);
  };

  return (
    <Paper display="block" justifycontent="flex-start" sx={{ borderRadius: 2, p: 2 }}>
      {(isCaseStudyFetching || exportLoading) && <MISLoader />}
      <Typography variant="h5" fontWeight={600} mb={1} marginLeft={2} sx={{ pt: 2 }}>
        {T.CASE_STUDY_LIST_VIEW}
      </Typography>

      <Box
        sx={{
          "& .MuiTabPanel-root": {
            p: 2,
            pt: 0,
            padding: "4px",
          },
        }}
      >
        <TopBar
          isFilterEmpty={isFilterEmpty}
          filters={filters}
          startDate={startDate}
          endDate={endDate}
          searchInput={searchInput}
          projectList={projectList}
          projectManagers={projectManagersList}
          functionalManagers={functionalManagersList}
          handleKeyChange={handleSearchKeyChange}
          handleChange={handleSearchChange}
          handleExport={handleExport}
          handleFilterClose={handleFilterClose}
          handleFilterApply={handleFilterSubmit}
          onhandleFilterChange={onhandleFilterChange}
          handleAddEditCaseStudyDialog={handleAddEditCaseStudyDialog}
          handleCaseStudyUploadLinkDialog={handleCaseStudyUploadLinkDialog}
        />

        <CaseStudyProjects
          allTableRows={CaseStudyTableData.allTableRows}
          totalTableRowsCount={CaseStudyTableData.totalTableRowsCount}
          totalPageCount={CaseStudyTableData.totalPageCount}
          page={page}
          rowsPerPage={rowsPerPage}
          pName={pName}
          refreshTable={refreshOnChange}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          handleAddEditCaseStudyDialog={handleAddEditCaseStudyDialog}
          handleCaseStudyUploadLinkDialog={handleCaseStudyUploadLinkDialog}
          handleOpenCaseStudyUpload={handleOpenCaseStudyUpload}
          handleAcceptCaseStudyDialog={handleAcceptCaseStudyDialog}
          onClickReminder={onClickReminder}
        />
      </Box>

      {/* <AddEditCaseStudy
        allTableRows={CaseStudyTableData.allTableRows}
        editId={editId}
        refreshTable={refreshOnChange}
        openAddEditCaseStudy={openAddEditCaseStudy}
        handleAddEditCaseStudyDialog={handleAddEditCaseStudyDialog}
      /> */}

      {/* <AcceptRejectCaseStudy
        pId={pId}
        openRejectCaseStudy={openRejectCaseStudy}
        refreshView={refreshOnChange}
        refreshTable={refreshOnChange}
        handleAcceptCaseStudyDialog={handleAcceptCaseStudyDialog}
      /> */}

      {/* <CaseStudyUploadLinkModal
        pId={pId}
        refreshTable={refreshOnChange}
        openCaseStudyLink={openCaseStudyLink}
        pName={pName}
        handleCaseStudyUploadLinkDialog={handleCaseStudyUploadLinkDialog}
      /> */}
      <CaseStudyUploadFileModal
        pId={pId}
        userRole={role}
        confirmation={confirmation}
        refreshTable={refreshOnChange}
        openCaseStudyUpload={openCaseStudyUpload}
        handleCloseCaseStudyUpload={handleCloseCaseStudyUpload}
      />
    </Paper>
  );
};

export default CaseStudy;
