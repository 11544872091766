import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProjectFilter: builder.mutation({
      query: ({ page, rowsPerPage, projectFilterRequestDto }) => ({
        url: `/businessCentral/filter?page=${page}&size=${rowsPerPage}`,
        method: "POST",
        body: projectFilterRequestDto,
      }),
    }),
  }),
});

export const { useGetProjectFilterMutation } = extendedApi;
