import { Children } from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import { Box, TableRow, TableCell, Typography, TableBody, Tooltip, styled, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SubTitleTypography, TitleTypography, StyledContentCopyIcon } from "utils/members";
import EditIcon from "@mui/icons-material/Edit";
import { BACKGROUND, BORDER, NETSMARTZ_THEME_COLOR } from "theme/colors";
import { get } from "utils/lodash";
import memo from "utils/react";
import { capitalizeWords } from "utils/commonFunction";
import T from "T";
import MISNoData from "components/common/MISNoData";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderTop: "inherit",
  overflow: "hidden",
  padding: "8px 0px 8px 17px",
  ...theme.typography.subtitle1,
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: BACKGROUND.white,
  },
  "&:nth-of-type(even)": {
    backgroundColor: BACKGROUND.cardDefault,
  },
}));

const CourseTableBody = ({ allTableRows = [], TrackRecord = [], handleSubCourse = noop }) => {
  const navigate = useNavigate();
  const handleEditCourse = (id) => {
    navigate(`/app/training/${id}/edit-course`);
  };

  return (
    <TableBody>
      {Children.toArray(
        allTableRows.map((record, index) => {
          const courseName = get(record, "courseName", "");
          const isAnyCourseInProcess = TrackRecord.some(
            ({ coursename, trackstatus }) => coursename === courseName && [T.IN_PROGRESS, T.REVIEW_PENDING].includes(trackstatus),
          );
          const courseType = get(record, "assignmentType.courseAssignmentType", "");
          const courseDuration = get(record, "courseDuration", "");
          const subsCourses = get(record, "subsCourses", "");
          const courseCreatedBy = get(record, "courseCreatedBy", "");
          const trainingCourseId = get(record, "trainingCourseId", "");
          return (
            <StyledTableRow sx={{ background: BACKGROUND.white }} key={index}>
              <StyledTableCell sx={{ position: "sticky", left: "0px", zIndex: +1, backgroundColor: "inherit" }}>
                <Tooltip title={courseName}>
                  <TitleTypography>{capitalizeWords(courseName)}</TitleTypography>
                </Tooltip>
              </StyledTableCell>
              <StyledTableCell>
                <TitleTypography>{courseType}</TitleTypography>
              </StyledTableCell>
              <StyledTableCell>
                <TitleTypography>{`${courseDuration} Hrs`}</TitleTypography>
              </StyledTableCell>
              <StyledTableCell>
                {courseType !== T.OFFLINE_TRAINING
                  ? Children.toArray(
                      subsCourses.map((data) => {
                        const subsCourseName = get(data, "subsCourseName", "");
                        return (
                          <SubTitleTypography
                            sx={{ color: NETSMARTZ_THEME_COLOR, cursor: "pointer" }}
                            onClick={() => handleSubCourse(data)}
                          >
                            {capitalizeWords(subsCourseName)}
                          </SubTitleTypography>
                        );
                      }),
                    )
                  : "N/A"}
              </StyledTableCell>
              <StyledTableCell sx={{ textAlign: "center" }}>
                <TitleTypography>{courseType !== T.OFFLINE_TRAINING ? subsCourses.length : 0}</TitleTypography>
              </StyledTableCell>
              <StyledTableCell>
                <TitleTypography>{courseCreatedBy.name}</TitleTypography>
              </StyledTableCell>
              <StyledTableCell sx={{ position: "sticky", right: "0px", zIndex: +1, backgroundColor: "inherit" }}>
                <IconButton
                  disabled={isAnyCourseInProcess}
                  onClick={() => {
                    handleEditCourse(trainingCourseId);
                  }}
                >
                  <EditIcon
                    sx={{
                      mr: 1,
                      height: 17,
                      cursor: "pointer",
                      color: isAnyCourseInProcess ? BORDER.tabsGrey : NETSMARTZ_THEME_COLOR,
                    }}
                  />
                </IconButton>
              </StyledTableCell>
            </StyledTableRow>
          );
        }),
      )}
    </TableBody>
  );
};

CourseTableBody.propTypes = {
  allTableRows: PropTypes.array,
  TrackRecord: PropTypes.array,
  handleSubCourse: PropTypes.func,
};

export default memo(CourseTableBody);
