import { Paper } from "@mui/material";
import { useEffect, useReducer } from "react";
import { get } from "lodash";

import { PAGINATION } from "settings/constants/pagination";
import { useLazyGetAllVendorsQuery } from "api/Vendors/getAllVendors";
import { handleError } from "utils/error";
import { useLazyDeleteVendoryByIdQuery } from "api/Vendors/deleteVendorById";
import { toast } from "react-toastify";
import T from "T";
import VendorTable from "./Table/Table";
import { useVendorFilterMutation } from "api/Vendors/vendorFilter";
import { useLazySetActiveVendorByIdQuery } from "api/Vendors/setActiveVendorById";
import { downloadFile } from "utils/file";
import { ClassSharp } from "@mui/icons-material";
import Constants from "Constants";
import { MISCurrentUser } from "utils/validations";

const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;
const { SERVER_URL } = Constants;

const Vendors = () => {
  const { sessionToken } = MISCurrentUser();

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    page: INITIAL_PAGE,
    size: ROWS_PER_PAGE,
    totalPageCount: INITIAL_PAGE,
    totalTableRowsCount: INITIAL_PAGE,
    tableData: [],
    filters: {},
  });
  const { page, size, totalPageCount, totalTableRowsCount, tableData, filters } = localState;

  const [getAllVendors] = useLazyGetAllVendorsQuery();
  const [deleteVendorById] = useLazyDeleteVendoryByIdQuery();
  const [vendorFilter] = useVendorFilterMutation();
  const [setVendorStatus] = useLazySetActiveVendorByIdQuery();

  const deleteVendor = (id) => {
    deleteVendorById({ id })
      .unwrap()
      .then(() => {
        toast.success(T.DELETED);
        getAllVendorsDetail();
      })
      .catch(handleError);
  };

  const getAllVendorsDetail = () => {
    getAllVendors({ page, size })
      .unwrap()
      .then((res) => {
        setLocalState({
          tableData: res.results,
          totalTableRowsCount: get(res, "numberOfRecords", 0),
          totalPageCount: get(res, "numberOfPages", 0),
        });
      })
      .catch(handleError);
  };

  const handleActive = (id, status) => {
    setVendorStatus({ id, status })
      .unwrap()
      .then((res) => {
        toast.success(res.message, { delay: 500 });
        getAllVendorsDetail();
        setLocalState({ filters: {} });
      })
      .catch(handleError);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    filters[name] = value;
    setLocalState({ filters });
  };

  const vendorFilterData = () => {
    const techs = get(filters, "techStack", []);
    const isActive = get(filters, "active", "");
    const payload = {
      techStack: techs.length < 1 ? [] : techs.map((it) => it.skillName),
      workMode: get(filters, "workMode", ""),
      isActive: isActive ? (isActive === "True" ? true : false) : "",
      rating: filters?.rating?.rating,

      text: "",
    };

    vendorFilter({ page: INITIAL_PAGE, size: ROWS_PER_PAGE, payload })
      .unwrap()
      .then((res) => {
        if (res) {
          setLocalState({
            tableData: res.results,
            totalTableRowsCount: get(res, "numberOfRecords", 0),
            totalPageCount: get(res, "numberOfPages", 0),
          });
        }
      })
      .catch(handleError);
  };

  const downloadVendorCsv = () => {
    const techs = get(filters, "techStack", []);
    const isActive = get(filters, "active", "");
    const payload = {
      techStack: techs.length < 1 ? [] : techs.map((it) => it.skillName),
      workMode: get(filters, "workMode", ""),
      isActive: isActive ? (isActive === "True" ? true : false) : "",
      rating: filters?.rating?.rating,

      text: "",
    };
    fetch(`${SERVER_URL}/vendor/download/csv`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        Authorization: `Bearer ${sessionToken}`,
        "content-type": "application/json",
      },
    })
      .then((res) => res.blob())
      .then((response) => {
        downloadFile(response, T.CSV);
      })
      .catch(handleError);
  };

  useEffect(() => {
    getAllVendorsDetail();
  }, [page, size]);

  const handleRowsPerPageChange = (event) => {
    const { value } = event.target;
    setLocalState({ page: INITIAL_PAGE, size: value });
  };

  const handlePageChange = (newPage) => {
    setLocalState({ page: newPage });
    document.getElementsByClassName("MuiTableContainer-root")[0].scrollTop = 0;
  };

  const onhandleFilterChange = (newValue, item) => {
    filters[item] = newValue;

    setLocalState({ filters });
  };

  const handleFilterSubmit = () => {
    vendorFilterData();
  };

  const handleFilterClose = () => {
    setLocalState({ filters: {} });
    getAllVendorsDetail();
  };

  return (
    <Paper display={"block"} sx={{ p: 2, borderRadius: 2 }}>
      <VendorTable
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
        page={page}
        size={size}
        tableData={tableData}
        totalPageCount={totalPageCount}
        totalTableRowsCount={totalTableRowsCount}
        deleteVendor={deleteVendor}
        filters={filters}
        onhandleFilterChange={onhandleFilterChange}
        handleFilterClose={handleFilterClose}
        handleFilterSubmit={handleFilterSubmit}
        handleChange={handleChange}
        handleActive={handleActive}
        downloadVendorCsv={downloadVendorCsv}
      />
    </Paper>
  );
};

export default Vendors;
