import { Card, Typography, Box, Tooltip } from "@mui/material";
import MISButton from "components/common/MISButton";
import T from "T";
import { Add, ArrowBack } from "@mui/icons-material";
import routes from "router/routes";
import { useNavigate } from "react-router-dom";
import { Table as TableView, TableContainer } from "@mui/material";

import MasterPagination from "components/MasterSetting/MasterPagination";

import PropTypes from "prop-types";
import MISNoData from "components/common/MISNoData";
import { noop } from "lodash";
import ResourceTableHeader from "./TableHeader";
import ResourceTableBody from "./TableBody";
import Filters from "../Filters";
import usePMFetch from "hooks/usePMFetch";
import CSV from "../../../../assets/CSV.png";

const ResourceTable = ({
  tableData,
  page,
  size,
  handlePageChange,
  handleRowsPerPageChange,
  totalTableRowsCount,
  totalPageCount,
  deleteVendor,
  filters,
  onhandleFilterChange = noop,
  handleFilterClose = noop,
  handleFilterSubmit = noop,
  handleChange = noop,
  vendorId,
  handleActive = noop,
  downloadResourceCsv = noop,
}) => {
  const navigate = useNavigate();
  const { skillList } = usePMFetch();

  return (
    <Box>
      <Box display="flex" alignItems="center" mb={2}>
        <ArrowBack fontSize="small" sx={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
        <Typography variant="h5" ml={1}>
          {T.RESOURCE + "s"}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          mt: 1,
          p: 1,
          backgroundColor: "background.card",
        }}
      >
        <Filters
          filters={filters}
          handleChange={handleChange}
          handleFilterClose={handleFilterClose}
          handleFilterSubmit={handleFilterSubmit}
          onhandleFilterChange={onhandleFilterChange}
          skillList={skillList}
        />
        <Tooltip title={T.CSV} placement="top">
          <Box sx={{ width: 40, height: 40, cursor: "pointer", ml: 2 }} component="img" src={CSV} onClick={downloadResourceCsv} />
        </Tooltip>
        <MISButton
          sx={{ ml: 2 }}
          size="small"
          startIcon={<Add sx={{ width: 19 }} />}
          onClick={() => {
            navigate(routes.app.resourceForm + "/" + vendorId);
          }}
        >
          {T.NEW}
        </MISButton>
      </Box>
      <Card
        elevation={0}
        sx={{
          p: "0px 8px",
          mt: 1,
          backgroundColor: "background.card",
          "& .add-shadow": {
            boxShadow: "inset -6px 0px 5px -5px rgb(0 0 0 / 15%)",
          },
        }}
      >
        <TableContainer
          sx={{
            height: "calc(100vh - 310px)",
            overflow: "auto",
          }}
        >
          {tableData.length < 1 ? (
            <MISNoData />
          ) : (
            <>
              <TableView stickyHeader sx={{ borderSpacing: "0 8px" }}>
                <ResourceTableHeader columns={RESOURCE_TABLE_HEADER} />

                <ResourceTableBody
                  allTableRows={tableData}
                  page={page}
                  size={size}
                  deleteVendor={deleteVendor}
                  vendorId={vendorId}
                  handleActive={handleActive}
                />
              </TableView>
            </>
          )}
        </TableContainer>
        <MasterPagination
          page={page}
          onPageChange={handlePageChange}
          rowsPerPage={size}
          onRowsPerPageChange={handleRowsPerPageChange}
          totalTableRowsCount={totalTableRowsCount}
          totalPageCount={totalPageCount}
        />
      </Card>
    </Box>
  );
};

ResourceTable.propTypes = {
  tableData: PropTypes.array,
  page: PropTypes.number,
  size: PropTypes.number,
  handlePageChange: PropTypes.func,
  handleRowsPerPageChange: PropTypes.func,
  totalTableRowsCount: PropTypes.number,
  totalPageCount: PropTypes.number,
  deleteVendor: PropTypes.func,
  skillList: PropTypes.array,
  filters: PropTypes.object,
  onhandleFilterChange: PropTypes.func,
  handleFilterClose: PropTypes.func,
  handleFilterSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  vendorId: PropTypes.any,
  handleActive: PropTypes.func,
  downloadResourceCsv: PropTypes.func,
};

export default ResourceTable;

const RESOURCE_TABLE_HEADER = [
  { label: T.S_NO },
  { label: T.NAME },
  {
    label: T.PROJECT_NAME,
  },

  {
    label: T.PROJECT_MANAGER,
  },
  {
    label: T.TECH_STACK,
  },
  {
    label: T.DEPARTMENT,
  },
  {
    label: T.RATING + "'s",
  },
  {
    label: T.OVERALL_RATING,
  },
  {
    label: T.DATES,
  },
  {
    label: T.CONTACT_INFO,
  },
  {
    label: T.PAYMENT + "'s",
  },
  {
    label: T.NOTICE_PERIOD,
  },
  {
    label: T.VENDOR,
  },
  {
    label: T.TIME_ALLOCATION,
  },
  {
    label: T.COMMENTS,
  },
  {
    label: T.ACTION_BUTTONS,
  },
];
