import { Grid, IconButton, InputAdornment, Tooltip, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import T from "T";
import MISAutocomplete from "components/common/MISAutocomplete";
import MISDatePicker from "components/common/MISDatePicker";
import MISTextField from "components/common/MISTextField";
import { get } from "utils/lodash";
import { PROJECT_STATUS, PROJECT_TYPE_MAPPING, CURRENCY_LIST } from "../../businessCentralModel";
import { capitalizeWords } from "utils/commonFunction";
import { ERROR, NETSMARTZ_THEME_COLOR } from "theme/colors";
import WithInputLabel from "components/common/WithInputLabel";
import { TitleTypography } from "utils/members";
import { Children } from "react";

const ProjectDetail = ({
  jiraName,
  projectName,
  projectType,
  projectStatus,
  projectPocName,
  projectPocEmail,
  projectPocNumber,
  projectManagerId,
  projectFunctionalHeadId,
  department,
  salesRepresentativeId,
  revenueGenerated,
  projectStartDate,
  projectEndDate,
  totalCost,
  currency,
  accountInvoice,
  comments,
  companyIndustry,
  companyRegion,
  companyAddress,
  salesRepresentativeList,
  projectManagerList,
  vicePresidentList,
  departmentsList,
  clientRegionList,
  file,
  fileType,
  sharePointWebUrl,
  handleFileUpload,
  handleDeleteFile,
  onHandleChange,
  onHandleAutoCompleteChange,
  onHandleDateChange,
  openSalesRepresentativeModal,
  invalidField,
  projectDetailsFields,
  totalRevenueGenerated,
}) => {
  const sharePointUrlArray = sharePointWebUrl.split(",");
  const isInvalid = (fieldName) =>
    invalidField?.includes(fieldName) && projectDetailsFields.find((item) => item.name === fieldName)?.value;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography fontSize={16} fontWeight={600}>
          {T.PROJECT_DETAILS.toLocaleUpperCase()}
        </Typography>
      </Grid>
      <Grid item md={4} xs={12}>
        <MISTextField
          label={T.NAME}
          required
          fullWidth
          placeholder={T.NAME}
          autoComplete="on"
          size="small"
          variant="outlined"
          name="projectName"
          value={projectName}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderBottom: isInvalid("projectName") && `3px solid ${ERROR.main}`,
            },
          }}
          onChange={(e) => onHandleChange(e)}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MISAutocomplete
          label={T.TYPE}
          required
          listDetails={PROJECT_TYPE_MAPPING}
          value={PROJECT_TYPE_MAPPING.find((item) => item.value === projectType)}
          getByLabelText={(option) => get(option, "projectTypeName", "")}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderBottom: isInvalid("projectType") && `3px solid ${ERROR.main}`,
            },
          }}
          onHandleChange={(event, newValue) => onHandleAutoCompleteChange("projectType", get(newValue, "value", ""))}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MISAutocomplete
          label={T.STATUS}
          required
          listDetails={PROJECT_STATUS}
          value={projectStatus}
          getByLabelText={(option) => option}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderBottom: isInvalid("projectStatus") && `3px solid ${ERROR.main}`,
            },
          }}
          onHandleChange={(event, newValue) => onHandleAutoCompleteChange("projectStatus", newValue)}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MISAutocomplete
          label={T.DEPARTMENT}
          required
          listDetails={departmentsList}
          value={departmentsList.find((item) => item.departmentName === department)}
          getByLabelText={(option) => get(option, "departmentName", "")}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderBottom: isInvalid("department") && `3px solid ${ERROR.main}`,
            },
          }}
          onHandleChange={(event, newValue) => onHandleAutoCompleteChange("department", get(newValue, "departmentName", ""))}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MISDatePicker
          label={T.START_DATE}
          required
          placeholder={T.START_DATE}
          name="projectStartDate"
          value={projectStartDate}
          handleChange={onHandleDateChange}
          outlined={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderBottom: isInvalid("projectStartDate") && `3px solid ${ERROR.main}`,
            },
          }}
        // renderInput={(params) => <MISTextField {...params} />}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MISDatePicker
          label={T.END_DATE}
          required
          minDate={projectStartDate}
          placeholder={T.END_DATE}
          name="projectEndDate"
          value={projectEndDate}
          handleChange={onHandleDateChange}
          outlined={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderBottom: isInvalid("projectEndDate") && `3px solid ${ERROR.main}`,
            },
          }}
        // renderInput={(params) => <MISTextField {...params} />}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MISAutocomplete
          label={T.SALES_REPRESENTATIVE}
          required
          listDetails={salesRepresentativeList}
          value={salesRepresentativeList.find((item) => item.id === salesRepresentativeId)}
          getByLabelText={(option) => get(option, "name", "")}
          onHandleChange={(event, newValue) => onHandleAutoCompleteChange("salesRepresentativeId", get(newValue, "id", ""))}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderBottom: isInvalid("salesRepresentativeId") && `3px solid ${ERROR.main}`,
            },
          }}
          inputAdornment={
            <InputAdornment>
              <Tooltip title={`Add/Edit ${T.SALES_REPRESENTATIVE}`}>
                <IconButton onClick={() => openSalesRepresentativeModal()}>
                  <AddCircleOutlineIcon
                    sx={{
                      color: NETSMARTZ_THEME_COLOR,
                      width: 20,
                      height: 20,
                    }}
                  />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          }
          andormentPosition={"endAdornment"}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MISAutocomplete
          label={T.PROJECT_MANAGER}
          required
          listDetails={projectManagerList}
          value={projectManagerList.find((item) => item.id === projectManagerId)}
          getByLabelText={(option) => get(option, "name", "")}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderBottom: isInvalid("projectManagerId") && `3px solid ${ERROR.main}`,
            },
          }}
          onHandleChange={(event, newValue) => onHandleAutoCompleteChange("projectManagerId", get(newValue, "id", ""))}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MISAutocomplete
          label={T.VICE_PRESIDENT}
          required
          listDetails={vicePresidentList}
          value={vicePresidentList.find((item) => item.id === projectFunctionalHeadId)}
          getByLabelText={(option) => get(option, "name", "")}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderBottom: isInvalid("projectFunctionalHeadId") && `3px solid ${ERROR.main}`,
            },
          }}
          onHandleChange={(event, newValue) => onHandleAutoCompleteChange("projectFunctionalHeadId", get(newValue, "id", ""))}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MISAutocomplete
          label={T.REGION}
          required
          listDetails={clientRegionList}
          value={clientRegionList.find((item) => item.regionName === companyRegion)}
          getByLabelText={(option) => get(option, "regionName", "")}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderBottom: isInvalid("companyRegion") && `3px solid ${ERROR.main}`,
            },
          }}
          onHandleChange={(event, newValue) => onHandleAutoCompleteChange("companyRegion", get(newValue, "regionName", ""))}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MISTextField
          label={T.INDUSTRY}
          fullWidth
          placeholder={T.INDUSTRY}
          autoComplete="on"
          size="small"
          variant="outlined"
          name="companyIndustry"
          value={companyIndustry}
          onChange={(e) => onHandleChange(e)}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MISTextField
          label={T.COMPANY_ADDRESS}
          fullWidth
          placeholder={T.COMPANY_ADDRESS}
          autoComplete="on"
          size="small"
          variant="outlined"
          name="companyAddress"
          value={companyAddress}
          onChange={(e) => onHandleChange(e)}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MISTextField
          label={T.POC_NAME}
          required
          fullWidth
          placeholder={T.POC_NAME}
          autoComplete="on"
          size="small"
          variant="outlined"
          name="projectPocName"
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderBottom: isInvalid("projectPocName") && `3px solid ${ERROR.main}`,
            },
          }}
          value={projectPocName}
          onChange={(e) => onHandleChange(e)}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MISTextField
          label={T.POC_EMAIL}
          fullWidth
          placeholder={T.POC_EMAIL}
          autoComplete="on"
          size="small"
          variant="outlined"
          name="projectPocEmail"
          value={projectPocEmail}
          onChange={(e) => onHandleChange(e)}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MISTextField
          label={T.POC_EMAIL}
          fullWidth
          placeholder={T.POC_EMAIL}
          autoComplete="on"
          size="small"
          variant="outlined"
          name="projectPocNumber"
          value={projectPocNumber}
          onChange={(e) => onHandleChange(e)}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MISAutocomplete
          label={T.CURRENCY}
          required
          listDetails={CURRENCY_LIST}
          value={currency}
          getByLabelText={(option) => option}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderBottom: isInvalid("currency") && `3px solid ${ERROR.main}`,
            },
          }}
          onHandleChange={(event, newValue) => onHandleAutoCompleteChange("currency", newValue)}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MISTextField
          label={T.ACCOUNT_INVOICE}
          fullWidth
          placeholder={T.ACCOUNT_INVOICE}
          autoComplete="on"
          size="small"
          variant="outlined"
          name="accountInvoice"
          value={accountInvoice}
          onChange={(e) => onHandleChange(e)}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MISTextField
          label={T.COMMENTS}
          fullWidth
          placeholder={T.COMMENTS}
          autoComplete="on"
          size="small"
          variant="outlined"
          name="comments"
          value={comments}
          onChange={(e) => onHandleChange(e)}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MISTextField
          label={T.JIRA_NAME}
          fullWidth
          placeholder={T.JIRA_NAME}
          autoComplete="on"
          size="small"
          variant="outlined"
          name="jiraName"
          value={jiraName}
          onChange={(e) => onHandleChange(e)}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MISTextField
          label={T.TOTAL_COST}
          fullWidth
          placeholder={T.TOTAL_COST}
          autoComplete="on"
          size="small"
          variant="outlined"
          name="totalCost"
          value={totalCost}
          onChange={(e) => onHandleChange(e)}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MISTextField
          label={T.REVENUE_GENERATED}
          fullWidth
          placeholder={T.REVENUE_GENERATED}
          autoComplete="on"
          size="small"
          variant="outlined"
          name="revenueGenerated"
          disabled
          value={revenueGenerated}
          onChange={(e) => onHandleChange(e)}

        />
      </Grid>
      <Grid item md={4} xs={12}>
        <WithInputLabel label={T.UPLOAD_FILE}>
          <>
            <input
              name="fileType"
              style={{
                color: fileType ? "transparent" : NETSMARTZ_THEME_COLOR,
                marginTop: 10,
              }}
              type="file"
              onChange={(e) => handleFileUpload(e)}
              onClick={(e) => handleDeleteFile(e)}
            />
            {fileType && <div style={{ color: NETSMARTZ_THEME_COLOR }}>Chosen File: {fileType}</div>}
          </>
        </WithInputLabel>
      </Grid>
      <Grid item md={12} xs={12}>
        {Children.toArray(
          sharePointUrlArray.map((item) => {
            return (
              <Typography key={item}>
                <a href={item} target="_blank" rel="noopener noreferrer" style={{ color: NETSMARTZ_THEME_COLOR }}>
                  {item.split("/")[item.split("/").length - 1]}
                </a>
              </Typography>
            );
          }),
        )}
      </Grid>
    </Grid>
  );
};

export default ProjectDetail;
