import { Children } from "react";
import PropTypes from "prop-types";
import { noop, isEmpty } from "lodash";

import { Typography, Divider, Card, Button, Box } from "@mui/material";
import MISAutocomplete from "components/common/MISAutocomplete";
import { ClickAwayListener } from "@mui/material";
import { VENDOR_WORK_MODE, VENDOR_RATING } from "components/Members/Member/memberModel";

import { NETSMARTZ_THEME_COLOR, BACKGROUND } from "theme/colors";
import { GET_SIZE } from "utils/responsive";

import T from "T";

import { get } from "utils/lodash";

const FilterOptions = ({
  filters = {},

  skillList = {},

  handleFilterClose = noop,
  handleFilterSubmit = noop,
  onhandleFilterChange = noop,
  handleClose = noop,
  handleChange = noop,
}) => {
  const technologyList = get(skillList, "results", []);
  const { isXs } = GET_SIZE();

  const getFilterRecords = (val) => {
    switch (val) {
      case T.TECH_STACK:
        return technologyList;

      // case T.STATUS:
      //   return EMP_STATUS;

      case T.WORK_MODE:
        return VENDOR_WORK_MODE;

      case T.ACTIVE:
        return ["True", "False"];

      case T.RATING:
        return VENDOR_RATING;

      default:
    }
  };

  const getLabelData = (option, index) => {
    const optionVal = isEmpty(option) ? "" : option;

    switch (index) {
      case 0:
        return get(option, "skillName", "");

      case 1:
        return optionVal;
      case 2:
        return optionVal;
      case 3:
        return optionVal;
      default:
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Card
        sx={{
          mt: 1,
          position: "absolute",
          width: isXs ? "80%" : "30%",
          marginLeft: "-150px",
          zIndex: 400,
        }}
      >
        <Box
          sx={{
            maxHeight: "calc(100vh - 250px)",
            overflowY: "auto",
          }}
        >
          <Typography variant="subtitle1" p={1} textAlign="center">
            {T.FILTER}
          </Typography>
          <Divider />

          {Children.toArray(
            FILTER_OPTIONS.map((item, index) => {
              const lists = getFilterRecords(item.label);

              const multiple = [T.TECH_STACK].includes(item.label);

              return (
                <Box display="flex" p="10px 20px" justifyContent="space-between" pb={index >= 7 ? "15px" : "0px"}>
                  <Typography variant="subtitle1" noWrap>
                    {item.label}
                  </Typography>

                  {item.label === T.RATING && (
                    // <Box>
                    //   <Rating name="rating" precision={0.5} value={filters.rating} onChange={handleChange} />
                    // </Box>
                    <MISAutocomplete
                      multiple={multiple}
                      listDetails={lists}
                      size="small"
                      placeholder={T.SELECT}
                      sx={{
                        width: 185,
                      }}
                      value={Object.keys(filters).length > 0 ? filters[item.name] : []}
                      getByLabelText={(option) => getLabelData(option.name, index)}
                      onHandleChange={(event, newValue) => {
                        onhandleFilterChange(newValue, item.name);
                      }}
                    />
                  )}

                  {item.label !== T.RATING && (
                    <MISAutocomplete
                      multiple={multiple}
                      listDetails={lists}
                      size="small"
                      placeholder={T.SELECT}
                      sx={{
                        width: 185,
                      }}
                      value={Object.keys(filters).length > 0 ? filters[item.name] : []}
                      getByLabelText={(option) => getLabelData(option, index)}
                      onHandleChange={(event, newValue) => {
                        onhandleFilterChange(newValue, item.name);
                      }}
                    />
                  )}
                </Box>
              );
            }),
          )}
        </Box>
        <Divider />
        <Box display="flex" justifyContent="space-between" p={2}>
          <Button
            variant="outlined"
            size="small"
            sx={{
              borderColor: BACKGROUND.black,
              color: BACKGROUND.black,

              "&:hover": {
                borderColor: NETSMARTZ_THEME_COLOR,
                color: NETSMARTZ_THEME_COLOR,
              },
            }}
            onClick={handleFilterClose}
          >
            {T.RESET}
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{
              bgcolor: NETSMARTZ_THEME_COLOR,
              "&:hover": {
                bgcolor: NETSMARTZ_THEME_COLOR,
              },
            }}
            onClick={handleFilterSubmit}
          >
            {T.APPLY}
          </Button>
        </Box>
      </Card>
    </ClickAwayListener>
  );
};

FilterOptions.propTypes = {
  projectManagers: PropTypes.object,
  workLocationList: PropTypes.object,
  skillList: PropTypes.object,
  departmentList: PropTypes.object,
  projectList: PropTypes.object,
  filters: PropTypes.object,
  onhandleFilterChange: PropTypes.func,
  handleFilterSubmit: PropTypes.func,
  handleFilterClose: PropTypes.func,
  handleClose: PropTypes.func,
  handleChange: PropTypes.func,
};

export default FilterOptions;

export const FILTER_OPTIONS = [
  { label: T.TECH_STACK, name: "techStack" },
  // { label: T.STATUS, name: "status" },
  { label: T.WORK_MODE, name: "workMode" },
  { label: T.ACTIVE, name: "active" },
  { label: T.RATING, name: "rating" },
];
