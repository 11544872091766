import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProject: build.query({
      query: () => ({
        url: `/projects/get/all/managerId`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyGetProjectQuery } = extendedApi;
