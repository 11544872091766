import { Box } from "@mui/system";
import MISFooterButton from "components/common/MISFooterButton";
import { get } from "lodash";
import { useReducer, useEffect } from "react";
import T from "T";
import { toast } from "react-toastify";
import { handleError } from "utils/error";
import { useLocation, useNavigate } from "react-router-dom";
import ConfirmCancel from "components/Training/ConfirmCancel";
import { isAsstUrl, isNumber, isUrl } from "utils/validations";
import { turnToArray } from "utils/commonFunction";
import CreateHardware from "./CreateHardware";
import { useGetFilterHardwareMutation } from "api/Hardware/getFilterHardware";
import { getCurrentTableParams } from "data/members/memberTableSelectors";
import { useLazyGetHardwareTypeListQuery } from "api/Hardware/ListMutation/getHardwareTypeList";
import { useLazyGetManufacturerListQuery } from "api/Hardware/ListMutation/getManufacturerList";
import { useLazyGetWorkLocationQuery } from "api/members/getWorkLocation";
import { format, isValid } from "date-fns";
import { BACKEND_DATE_FORMAT } from "settings/constants/date";
import { useSaveHardwareMutation } from "api/Hardware/saveHardware";
import { useUpdateHardwareMutation } from "api/Hardware/updateHardware";
import HardwareTypeDialog from "components/Hardware/HardwareDialog/HardwareTypeDialog";
import { useLazyGetHardwareTypeByIdQuery } from "api/Hardware/ListMutation/getHardwareTypeById";
import { useSaveHardwareTypeMutation } from "api/Hardware/ListMutation/saveHardwareType";
import { useUpdateHardwareTypeMutation } from "api/Hardware/ListMutation/updateHardwareType";
import { useDeleteHardwareTypeMutation } from "api/Hardware/ListMutation/deleteHardwareType";
import { useDeleteManufacturerMutation } from "api/Hardware/ListMutation/deleteManufacturer";
import { useLazyGetManufacturerByIdQuery } from "api/Hardware/ListMutation/getManufacturerById";
import { useSaveManufacturerMutation } from "api/Hardware/ListMutation/saveManufacturer";
import { useUpdateManufacturerMutation } from "api/Hardware/ListMutation/updateManufacturer";
import ManufacturerDialog from "components/Hardware/HardwareDialog/ManufacturerDialog";
import { useLazyGetHardwareByIdQuery } from "api/Hardware/getHardwareById";
const FULL_ROWS_PER_PAGE = 10000;
const INITIAL_PAGE = 0;

const Form = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const url = location.pathname;
  const id = url.includes("edit") && Number(url.replace(/\D+/g, ""));
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    openConfirmCancel: false,
    invalidField: null,
    hardwareID: "",
    manufacturer: null,
    hardwareType: null,
    workLocation: null,
    model: "",
    serialNumber: "",
    purchaseDate: null,
    warrantyDate: null,
    hdd: "",
    windowsOs: "",
    ram: "",
    poNo: "",
    hostName: "",
    seatNumber: "",
    systemDetails: "",
    isOpenHardwareTypeDialog: false,
    isAddEditHardwareTypeOpen: false,
    addEditHardwareType: {
      hardwareTypeId: "",
      hardwareTypeName: "",
      hardwareTypeHeadName: "",
    },
    isOpenManufacturerDialog: false,
    isAddEditManufacturerOpen: false,
    addEditManufacturer: {
      ManufacturerId: "",
      ManufacturerName: "",
      ManufacturerHeadName: "",
    },
    hardwareTypeList: [],
    manufacturerList: [],
    workLocationList: [],
  });

  const {
    openConfirmCancel,
    hardwareID,
    manufacturer,
    hardwareType,
    workLocation,
    model,
    serialNumber,
    purchaseDate,
    warrantyDate,
    hdd,
    windowsOs,
    ram,
    poNo,
    hostName,
    seatNumber,
    systemDetails,
    isOpenHardwareTypeDialog,
    isAddEditHardwareTypeOpen,
    addEditHardwareType,
    isOpenManufacturerDialog,
    isAddEditManufacturerOpen,
    addEditManufacturer,
    hardwareTypeList,
    manufacturerList,
    workLocationList,
    invalidField,
  } = localState;
  // const [getFilterHardware] = useGetFilterHardwareMutation();
  const [saveHardware] = useSaveHardwareMutation();
  const [getHardwareById] = useLazyGetHardwareByIdQuery();
  const [updateHardware] = useUpdateHardwareMutation();
  const [getHardwareTypeList] = useLazyGetHardwareTypeListQuery();
  const [getHardwareTypeById] = useLazyGetHardwareTypeByIdQuery();
  const [saveHardwareType] = useSaveHardwareTypeMutation();
  const [updateHardwareType] = useUpdateHardwareTypeMutation();
  const [deleteHardwareType] = useDeleteHardwareTypeMutation();
  const [getManufacturerList] = useLazyGetManufacturerListQuery();
  const [getManufacturerById] = useLazyGetManufacturerByIdQuery();
  const [saveManufacturer] = useSaveManufacturerMutation();
  const [updateManufacturer] = useUpdateManufacturerMutation();
  const [deleteManufacturer] = useDeleteManufacturerMutation();
  const [getWorkLocation] = useLazyGetWorkLocationQuery();

  useEffect(() => {
    getHardwareTypeListData();
    getManufacturerListData();
    getWorkLocationListData();
  }, []);

  useEffect(() => {
    if (id) {
      getHardwareById(id)
        .unwrap()
        .then((res) => {
          const prevRequirements = {
            hardwareID: get(res, "hardwareID", null),
            hardwareType: get(res, "hardwareType", null),
            manufacturer: get(res, "manufacturer", null),
            model: get(res, "model", null),
            windowsOs: get(res, "windowsOs", null),
            systemDetails: get(res, "systemDetails", null),
            ram: get(res, "ram", null),
            seatNumber: get(res, "seatNumber", null),
            hdd: get(res, "hdd", null),
            poNo: get(res, "poNo", null),
            serialNumber: get(res, "serialNumber", null),
            purchaseDate: get(res, "purchaseDate", null),
            warrantyDate: get(res, "warrantyEndDate", null),
            workLocation: get(res, "workLocation", null),
            hostName: get(res, "hostName", null),
          };
          setLocalState(prevRequirements);
        })
        .catch(handleError);
    }
  }, [id]);

  const getListData = (getListFunction, listType) => {
    getListFunction({ page: INITIAL_PAGE, [listType != "workLocation" ? `rowsPerPage` : `rowPerPage`]: FULL_ROWS_PER_PAGE })
      .unwrap()
      .then((res) => {
        const { allTableRows } = getCurrentTableParams(res);
        setLocalState({ [`${listType}List`]: allTableRows });
      })
      .catch(handleError);
  };

  const getHardwareTypeListData = () => getListData(getHardwareTypeList, "hardwareType");
  const getManufacturerListData = () => getListData(getManufacturerList, "manufacturer");
  const getWorkLocationListData = () => getListData(getWorkLocation, "workLocation");

  const getBEDateFormat = (val) => format(val, BACKEND_DATE_FORMAT);

  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setLocalState({ [name]: value });
  };

  const onHandleAutoComplete = (type, value) => {
    setLocalState({ [type]: value });
  };

  const onHandleDateChange = (newValue, type) => {
    if (newValue !== null) {
      const validDate = new Date(newValue);
      if (isValid(validDate)) {
        setLocalState({ [type]: getBEDateFormat(validDate) });
      }
    } else {
      setLocalState({ [type]: null });
    }
  };

  const handleClose = () => {
    navigate(-1);
  };

  const confrmCancelModal = () => {
    setLocalState({ openConfirmCancel: !openConfirmCancel });
  };
  //############################New Dialog Function##########################
  const onHandleDialogChange = (e) => {
    const { name, value } = e.target;
    if (name === "hardwareTypeName") {
      setLocalState({ addEditHardwareType: { ...addEditHardwareType, hardwareTypeName: value } });
    } else {
      setLocalState({ addEditManufacturer: { ...addEditManufacturer, manufacturerName: value } });
    }
  };

  const handleOpenDialog = (type) => {
    const isHardwareType = type === "hardwareType";
    setLocalState({ [isHardwareType ? "isOpenHardwareTypeDialog" : "isOpenManufacturerDialog"]: true });
  };

  const handleCloseDialog = (type) => {
    const isHardwareType = type === "hardwareType";
    setLocalState({ [isHardwareType ? "isOpenHardwareTypeDialog" : "isOpenManufacturerDialog"]: false });
  };

  const closeAddEditMutation = (type) => {
    const isHardwareType = type === "hardwareType";
    setLocalState({
      [isHardwareType ? "addEditHardwareType" : "addEditManufacturer"]: {
        [`${isHardwareType ? "hardwareType" : "manufacturer"}Id`]: "",
        [`${isHardwareType ? "hardwareType" : "manufacturer"}Name`]: "",
        [`${isHardwareType ? "hardwareType" : "manufacturer"}HeadName`]: "",
      },
      [isHardwareType ? "isAddEditHardwareTypeOpen" : "isAddEditManufacturerOpen"]: false,
      [isHardwareType ? "isOpenHardwareTypeDialog" : "isOpenManufacturerDialog"]: true,
    });
    isHardwareType ? getHardwareTypeListData() : getManufacturerListData();
  };

  const handleDeleteType = (id, type) => {
    const isHardwareType = type === "hardwareType";
    const deleteFunction = isHardwareType ? deleteHardwareType : deleteManufacturer;
    const successMessage = isHardwareType ? T.HARDWARE_TYPE_DELETED_SUCCESSFULLY : T.MANUFACTURER_DELETED_SUCCESSFULLY;
    const refreshData = isHardwareType ? getHardwareTypeListData : getManufacturerListData;

    deleteFunction(id)
      .unwrap()
      .then(() => {
        toast.success(successMessage);
        refreshData();
      })
      .catch(handleError);
  };
  //============================HardwareType Dialog Functions========================================
  const openAddEditHardwareTypeMutation = (id) => {
    if (id) {
      getHardwareTypeById(id)
        .unwrap()
        .then((res) => {
          const { id, type } = res;
          setLocalState({
            addEditHardwareType: {
              hardwareTypeId: id,
              hardwareTypeName: type,
              hardwareTypeHeadName: type,
            },
          });
        })
        .catch(handleError);
    }
    setLocalState({ isAddEditHardwareTypeOpen: true, isOpenHardwareTypeDialog: false });
  };

  const handleSubmitHardwareType = (id) => {
    const payload = {
      type: addEditHardwareType.hardwareTypeName,
    };
    if (id) {
      updateHardwareType({ id, ...payload })
        .unwrap()
        .then((res) => {
          toast.success(T.HARDWARE_TYPE_UPDATED_SUCCESSFULLY);
          closeAddEditMutation("hardwareType");
        })
        .catch(handleError);
    } else {
      saveHardwareType(payload)
        .unwrap()
        .then((res) => {
          toast.success(T.HARDWARE_TYPE_ADDED_SUCCESSFULLY);
          closeAddEditMutation("hardwareType");
        })
        .catch(handleError);
    }
  };
  //============================Manufacturer Dialog Functions========================================
  const openAddEditManufacturerMutation = (id) => {
    if (id) {
      getManufacturerById(id)
        .unwrap()
        .then((res) => {
          const { id, name } = res;
          setLocalState({
            addEditManufacturer: {
              manufacturerId: id,
              manufacturerName: name,
              manufacturerHeadName: name,
            },
          });
        })
        .catch(handleError);
    }
    setLocalState({ isAddEditManufacturerOpen: true, isOpenManufacturerDialog: false });
  };

  const handleSubmitManufacturer = (id) => {
    const payload = {
      name: addEditManufacturer.manufacturerName,
    };
    if (id) {
      updateManufacturer({ id, ...payload })
        .unwrap()
        .then((res) => {
          toast.success(T.MANUFACTURER_UPDATED_SUCCESSFULLY);
          closeAddEditMutation("manufacturer");
        })
        .catch(handleError);
    } else {
      saveManufacturer(payload)
        .unwrap()
        .then((res) => {
          toast.success(T.MANUFACTURER_ADDED_SUCCESSFULLY);
          closeAddEditMutation("manufacturer");
        })
        .catch(handleError);
    }
  };
  // ####################### Validation Function ###############################
  const hardwareDetailsFields = [
    { name: "hardwareType", value: !hardwareType },
    { name: "manufacturer", value: !manufacturer },
    { name: "model", value: !model },
    { name: "serialNumber", value: !serialNumber },
    { name: "purchaseDate", value: !purchaseDate },
    { name: "warrantyDate", value: !warrantyDate },
    { name: "workLocation", value: !workLocation },
  ];

  const validation = () => {
    const invalidFields = hardwareDetailsFields.filter((field) => field.value).map((field) => field.name);

    if (invalidFields.length > 0) {
      toast.error("Invalid Field(s)");
      setLocalState({ invalidField: invalidFields });
      return false;
    }

    setLocalState({ invalidField: null });
    return true;
  };
  // ####################### Handle Submit Functions ############################
  const handleSubmit = () => {
    const payload = {
      hardwareID: id ? hardwareID : null,
      hardwareTypeId: get(hardwareType, "id", ""),
      manufacturerId: get(manufacturer, "id", ""),
      model,
      purchaseDate,
      serialNumber,
      warrantyEndDate: warrantyDate,
      workLocationId: get(workLocation, "id", ""),
      hdd,
      windowsOs,
      ram,
      poNo,
      hostName,
      seatNumber,
      systemDetails,
    };
    if (!validation()) return;
    if (!id) {
      saveHardware(payload)
        .unwrap()
        .then(() => {
          toast.success(T.HARDWARE_ADDED_SUCCESSFULLY);
          handleClose();
        })
        .catch(handleError);
    } else {
      updateHardware(payload)
        .unwrap()
        .then(() => {
          toast.success(T.HARDWARE_UPDATED_SUCCESSFULLY);
          handleClose();
        })
        .catch(handleError);
    }
  };

  // ####################### Render Functions ############################
  const renderNewHardwareDetails = () => ({
    id,
    hardwareType,
    manufacturer,
    workLocation,
    model,
    serialNumber,
    purchaseDate,
    warrantyDate,
    hdd,
    windowsOs,
    ram,
    poNo,
    hostName,
    seatNumber,
    systemDetails,
    hardwareTypeList,
    manufacturerList,
    workLocationList,
    hardwareDetailsFields,
    invalidField,
    onHandleChange,
    onHandleAutoComplete,
    onHandleDateChange,
    handleOpenDialog,
  });

  return (
    <>
      <Box
        // mb={2}
        sx={{
          height: "calc(100vh - 210px)",
          overflowY: "auto",
        }}
      >
        <CreateHardware {...renderNewHardwareDetails()} />
      </Box>
      <MISFooterButton
        proceedButtonText={id ? T.UPDATE : T.SUBMIT}
        justify="end"
        sx={{ pb: 0.5 }}
        handleClose={confrmCancelModal}
        handleSubmit={handleSubmit}
      />
      <ConfirmCancel openConfirmCancel={openConfirmCancel} confrmCancelModal={confrmCancelModal} handleClose={handleClose} />
      <HardwareTypeDialog
        hardwareTypeList={hardwareTypeList}
        isOpenHardwareTypeDialog={isOpenHardwareTypeDialog}
        isAddEditHardwareTypeOpen={isAddEditHardwareTypeOpen}
        addEditHardwareType={addEditHardwareType}
        openAddEditHardwareTypeMutation={openAddEditHardwareTypeMutation}
        closeAddEditMutation={closeAddEditMutation}
        handleClose={handleCloseDialog}
        onHandleChange={onHandleDialogChange}
        handleDeleteType={handleDeleteType}
        handleSubmitHardwareType={handleSubmitHardwareType}
      />
      <ManufacturerDialog
        manufacturerList={manufacturerList}
        isOpenManufacturerDialog={isOpenManufacturerDialog}
        isAddEditManufacturerOpen={isAddEditManufacturerOpen}
        addEditManufacturer={addEditManufacturer}
        openAddEditManufacturerMutation={openAddEditManufacturerMutation}
        closeAddEditMutation={closeAddEditMutation}
        handleClose={handleCloseDialog}
        onHandleChange={onHandleDialogChange}
        handleDeleteType={handleDeleteType}
        handleSubmitManufacturer={handleSubmitManufacturer}
      />
    </>
  );
};

export default Form;
