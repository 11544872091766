import { Typography, TableContainer, Table, TableHead, TableRow, TableCell, Paper, TableBody } from "@mui/material";
import MISNoData from "components/common/MISNoData";
import ConsolidatedTableBody from "components/NonCompliance/NCList/ConsolidatedTableBody";
import { get } from "utils/lodash";
import { getFEDateFormat } from "utils/members";

const GiftHistoryTable = ({ columns, giftHistory }) => {
  return (
    <TableContainer component={Paper} sx={{ maxHeight: "calc(100svh - 450px)", overflow: "auto" }}>
      {giftHistory.length > 0 ? (
        <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              {columns && columns.length && columns.map((column) => <TableCell key={column.key}>{column.label}</TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {giftHistory.map((data) => (
              <TableRow key={data.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                {columns.map((column) =>
                  column.key === "createdAt" ? (
                    <TableCell key={column.key}>{getFEDateFormat(get(data, column.key, ""))}</TableCell>
                  ) : (
                    <TableCell key={column.key}>{get(data, column.key, "")}</TableCell>
                  ),
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <MISNoData />
      )}
    </TableContainer>
  );
};

export default GiftHistoryTable;
