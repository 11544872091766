import { createSlice } from "@reduxjs/toolkit";

const onBenchSearchSlice = createSlice({
  name: "onBenchSearchSlice",
  initialState: {
    storedSearchInput: "",
  },
  reducers: {
    onBenchSearchStore: (state, action) => {
      state.storedSearchInput = action.payload.storedSearchInput;
    },
  },
});
export const { onBenchSearchStore } = onBenchSearchSlice.actions;

export default onBenchSearchSlice.reducer;
