import { Box, Typography, Grid, Card, CardContent, CardMedia } from "@mui/material";
import Desktop from "assets/Desktop.png";
import Laptop from "assets/Laptop.png";
import Phone from "assets/Phone.png";
import Tablet from "assets/Tablet.png";
import { get } from "utils/lodash";
import StackedBar from "./Charts/StackedBar";

const HardwareDashboardTab = ({ HardwareViewData = {} }) => {
  const {
    hardwareMap = {},
    mapList = [],
    hardwareTypeList = {},
    assignedHardware = 0,
    unassignedHardware = 0,
  } = HardwareViewData;
  const coloredCard = (name, img, val, details, color) => (
    <Card
      sx={{
        backgroundColor: color, // Light pink background
        borderRadius: "16px",
        boxShadow: 3,
        color: "#fff",
      }}
    >
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "bold", fontSize: "20px", marginBottom: 1, color: "#fff" }}>{name}</Typography>
            <Typography sx={{ fontWeight: "bold", fontSize: "25px", color: "#fff" }}>{val}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                lineHeight: 1.5,
                overflowY: "auto",
                maxHeight: 75,
                color: "#ffffff",
              }}
            >
              {Object.entries(details).map(([brand, value], index) => {
                return (
                  <Typography key={index} sx={{ fontWeight: 400, fontSize: "14px", lineHeight: 1.25 }}>
                    {`${brand}: ${value}`}
                  </Typography>
                );
              })}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  const categorizeItems = (brandName, itemType) => {
    if (["Laptop", "Desktop", "Phone"].includes(itemType)) {
      const appleNaming = { Laptop: "MacBook", Desktop: "iMac", Phone: "iPhone", Tablet: "iPad" };
      return brandName === "Apple" ? appleNaming[itemType] : itemType;
    }
    return itemType;
  };

  const categoryCounts = {};
  const brandInfo = {};

  mapList?.forEach((brand) => {
    const [brandName, categories] = Object.entries(brand)[0];
    Object.entries(categories).forEach(([itemType, [{ Assigned, Unassigned }]]) => {
      const category = categorizeItems(brandName, itemType);
      if (!category) return;

      categoryCounts[category] ||= { Assigned: 0, Unassigned: 0 };
      brandInfo[category] ||= {};

      categoryCounts[category].Assigned += Assigned;
      categoryCounts[category].Unassigned += Unassigned;

      brandInfo[category][brandName] ||= { Assigned: 0, Unassigned: 0 };
      brandInfo[category][brandName].Assigned += Assigned;
      brandInfo[category][brandName].Unassigned += Unassigned;
    });
  });

  const hardwareListValues = [
    {
      name: "Laptop",
      color: "#fbc300",
      image: Laptop,
      value: get(hardwareMap, "Laptop", "0"),
      details: get(hardwareTypeList, "Laptop", {}),
    },
    {
      name: "Desktop",
      color: "#78d42b",
      image: Desktop,
      value: get(hardwareMap, " Desktop", "0"),
      details: get(hardwareTypeList, "Desktop", {}),
    },
    {
      name: "Phone",
      color: "#2a90d4",
      image: Phone,
      value: get(hardwareMap, "Phone", "0"),
      details: get(hardwareTypeList, "Phone", {}),
    },
    {
      name: "Tablet",
      color: "#ec7b00",
      image: Tablet,
      value: get(hardwareMap, "Tablet", "0"),
      details: get(hardwareTypeList, "Tablet", {}),
    },
  ];

  return (
    <Card
      elevation={0}
      sx={{
        backgroundColor: "background.card",
        "& .add-shadow": {
          boxShadow: "inset -6px 0px 5px -5px rgb(0 0 0 / 15%)",
        },
      }}
    >
      <Box overflow="hidden">
        <Grid container sx={{ p: 1 }} spacing={3}>
          {hardwareListValues.map((item) => {
            const { name, image, value, details, color } = item;
            return (
              <Grid item md={12 / hardwareListValues.length} xs={12 / hardwareListValues.length}>
                {coloredCard(name, image, value, details, color)}
              </Grid>
            );
          })}
        </Grid>
        <Box sx={{ height: "calc(100vh - 312px)", width: "100%" }}>
          <StackedBar categoryCounts={categoryCounts} brandInfo={brandInfo} />
        </Box>
      </Box>
    </Card>
  );
};

export default HardwareDashboardTab;
