import PropTypes from "prop-types";
import { Box, Grid, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import TablePagination from "components/common/MISTablePagination";
import Pagination from "components/common/MISPagination";
import { PAGINATION } from "settings/constants/pagination";
import MISButton from "components/common/MISButton";
import { MISCurrentUser } from "utils/validations";
import T from "T";
import { get } from "utils/lodash";
const { INITIAL_PAGE, ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } = PAGINATION;

const GiftPagination = ({
  page = INITIAL_PAGE,
  onPageChange,
  rowsPerPage = ROWS_PER_PAGE,
  onRowsPerPageChange,
  allSelected = false,
  selectedGift = [],
  totalTableRowsCount = INITIAL_PAGE,
  totalPageCount = INITIAL_PAGE,
  handleSaveAsDraft,
  handleSubmit,
}) => {
  const theme = useTheme();
  const { user } = MISCurrentUser();
  const userRole = get(user, "role", "");
  const isLg = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Grid container alignItems="center" justifyContent="space-between" sx={{ backgroundColor: "background.card", pl: 1, pr: 1 }}>
      <Grid md={"auto"} xs container justifyContent="flex-start">
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          component="div"
          count={totalTableRowsCount || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => onPageChange(newPage)}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      </Grid>
      <Grid md={"auto"} xs container justifyContent="space-between">
        <Grid container xs justifyContent={"space-around"} sx={{ mx: 2 }}>
          <Tooltip title={`Please select atleast one gift to save draft`} placement="top">
            <Box>
              <MISButton
                disabled={selectedGift.length === 0}
                variant="outlined"
                sx={{ mx: 1 }}
                onClick={(e) => handleSaveAsDraft(e)}
              >
                {"Save as Draft"}
              </MISButton>
            </Box>
          </Tooltip>
          <Tooltip title={`Please select atleast one gift to submit`} placement="top">
            <Box>
              <MISButton disabled={selectedGift.length === 0} sx={{ mx: 1 }} onClick={(e) => handleSubmit(e)}>
                {selectedGift.length <= 1
                  ? userRole === T.COO
                    ? "Approve"
                    : "Submit"
                  : userRole === T.COO
                  ? "Approve All"
                  : "Submit All"}
              </MISButton>
            </Box>
          </Tooltip>
        </Grid>
        {/* {isLg && ( */}
        <Grid md={"auto"} xs>
          <Pagination
            count={totalPageCount || 0}
            page={page + 1}
            onChange={(event, newPage) => onPageChange(newPage - 1)}
            shape="rounded"
          />
        </Grid>
        {/* )} */}
      </Grid>
    </Grid>
  );
};

GiftPagination.propTypes = {
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  totalDataCount: PropTypes.number,
  totalPageCount: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
};
export default GiftPagination;
