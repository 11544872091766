import PropTypes from "prop-types";
import { noop, orderBy } from "lodash";

import { Box, Grid, Card, Table as TableView, TableContainer, useMediaQuery, useTheme } from "@mui/material";

import { PAGINATION } from "settings/constants/pagination";

import TablePagination from "components/common/MISTablePagination";
import Pagination from "components/common/MISPagination";
import TableHeader from "./TableHeader";
import MISTableBody from "./TableBody";

import "./index.scss";
import MISNoData from "components/common/MISNoData";
import { useEffect, useReducer } from "react";

const { INITIAL_PAGE, ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } = PAGINATION;
export const SORTING_KEYS = [{ key: "experience", value: "experience" }];

const Table = ({
  columns = [],
  // storedScrollPosition = 0,
  lockedColumns = [],
  // tableData ={},
  page = INITIAL_PAGE,
  onPageChange,
  rowsPerPage = ROWS_PER_PAGE,
  onRowsPerPageChange,
  totalTableRowsCount = INITIAL_PAGE,
  totalPageCount = INITIAL_PAGE,
  allTableRows,
  refreshUserAction = noop,
  refreshMemberTable = noop,
  handleScroll = noop,
}) => {
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up("md"));

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    activeSort: "",
    sortDirection: 0,
    renderedRow: [],
  });

  const { activeSort, sortDirection, renderedRow } = localState;

  useEffect(() => {
    setLocalState({ renderedRow: allTableRows, activeSort: "", sortDirection: 0 });
  }, [allTableRows]);

  const handleToolsSorting = (val) => {
    console.log("haha", val);
    setLocalState({ activeSort: val });
    let current = sortDirection;
    if (current === 0) current = 1;
    else if (current === 1) current = -1;
    else if (current === -1) current = 0;
    if (activeSort != val) current = 1;
    setLocalState({ sortDirection: current });

    if (current === 0) {
      setLocalState({ renderedRow: allTableRows });
      return;
    }

    const order = current === 1 ? "desc" : "asc";
    const sortedRows = orderBy(
      allTableRows,
      [
        SORTING_KEYS.find((item) => item.key === val).value === "experience"
          ? "expInYears"
          : [SORTING_KEYS.find((item) => item.key === val).value],
      ],
      [order],
    );
    setLocalState({ renderedRow: sortedRows });
  };
  // const tableRef = useRef(null);
  // useEffect(()=>{
  //     if(storedScrollPosition && tableData)
  //     {
  //         tableRef.current.scrollTop = storedScrollPosition;
  //     }

  // },[storedScrollPosition,tableData]);
  return (
    <Card
      elevation={0}
      sx={{
        p: "0px 8px",
        mt: 1.5,
        backgroundColor: "background.card",
        "& .add-shadow": {
          boxShadow: "inset -6px 0px 5px -5px rgb(0 0 0 / 15%)",
        },
      }}
    >
      <TableContainer
        sx={{
          height: "calc(100vh - 315px)",
          overflow: "auto",
        }}
      >
        {allTableRows.length !== 0 ? (
          <TableView stickyHeader sx={{ borderSpacing: "0 8px" }}>
            <TableHeader
              columns={columns}
              lockedColumns={lockedColumns}
              activeSort={activeSort}
              sortDirection={sortDirection}
              handleToolsSorting={handleToolsSorting}
            />
            <MISTableBody
              columns={columns}
              lockedColumns={lockedColumns}
              // records={orderBy(allTableRows, ["userName"], ["asc"])}
              records={renderedRow}
              refreshUserAction={refreshUserAction}
              refreshMemberTable={refreshMemberTable}
            />
          </TableView>
        ) : (
          <MISNoData />
        )}
      </TableContainer>

      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs container justifyContent="flex-start">
          <TablePagination
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            component="div"
            count={totalTableRowsCount || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, newPage) => onPageChange(newPage)}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </Grid>
        {isLg && (
          <Grid item xs container justifyContent="flex-end">
            <Pagination
              count={totalPageCount || 0}
              page={page + 1}
              onChange={(event, newPage) => onPageChange(newPage - 1)}
              shape="rounded"
            />
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

Table.propTypes = {
  columns: PropTypes.array,
  lockedColumns: PropTypes.array,
  page: PropTypes.number,
  storedScrollPosition: PropTypes.number,
  rowsPerPage: PropTypes.number,
  totalDataCount: PropTypes.number,
  totalPageCount: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  tableData: PropTypes.object,
  refreshMemberTable: PropTypes.func,
  handleScroll: PropTypes.func,
};

export default Table;
