import React from "react";
import { noop } from "lodash";
import PropTypes from "prop-types";
import HardwareTypeTable from "./HardwareTypeTable";
import HardwareTypeMutation from "./HardwareTypeMutation";

const HardwareTypeDialog = ({
  hardwareTypeList = [],
  isOpenHardwareTypeDialog = bool,
  isAddEditHardwareTypeOpen = bool,
  addEditHardwareType = {},
  openAddEditHardwareTypeMutation = noop,
  closeAddEditMutation = noop,
  handleClose = noop,
  onHandleChange = noop,
  handleSubmitHardwareType = noop,
  handleDeleteType = noop,
}) => {
  const columns = [
    { label: "S.no", key: "id" },
    { label: "Hardware Type Name", key: "type" },
    { label: "Created By", key: "updatedBy" },
    { label: "Actions", key: "actions" },
  ];
  return (
    <>
      <HardwareTypeTable
        columns={columns}
        hardwareTypeList={hardwareTypeList}
        isOpenHardwareTypeDialog={isOpenHardwareTypeDialog}
        openAddEditHardwareTypeMutation={openAddEditHardwareTypeMutation}
        handleClose={handleClose}
        handleDeleteType={handleDeleteType}
      />
      <HardwareTypeMutation
        isAddEditHardwareTypeOpen={isAddEditHardwareTypeOpen}
        addEditHardwareType={addEditHardwareType}
        handleClose={closeAddEditMutation}
        handleSubmitHardwareType={handleSubmitHardwareType}
        onHandleChange={onHandleChange}
      />
    </>
  );
};

HardwareTypeDialog.propTypes = {
  hardwareTypeList: PropTypes.arrayOf(PropTypes.object),
  isOpenHardwareTypeDialog: PropTypes.bool,
  isAddEditHardwareTypeOpen: PropTypes.bool,
  addEditHardwareType: PropTypes.object,
  openAddEditHardwareTypeMutation: PropTypes.func,
  closeAddEditMutation: PropTypes.func,
  handleClose: PropTypes.func,
  onHandleChange: PropTypes.func,
  handleSubmitHardwareType: PropTypes.func,
  handleDeleteHardwareType: PropTypes.func,
};

export default HardwareTypeDialog;
