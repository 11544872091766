import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    bulkHardwareUpload: build.mutation({
      query: (bulkMemberFile) => ({
        url: `/hardware/import`,
        method: "POST",
        body: bulkMemberFile,
      }),
    }),
  }),
});

export const { useBulkHardwareUploadMutation } = extendedApi;
