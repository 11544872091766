import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    resourceFilter: build.mutation({
      query: ({ page, size, vendorId, payload }) => ({
        url: `/resource/get/all/filter/resource?page=${page}&size=${size}&vendorId=${vendorId}`,
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const { useResourceFilterMutation } = extendedApi;
