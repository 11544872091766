import { ArrowBack } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Paper,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import T from "T";
import MISButton from "components/common/MISButton";
import PropTypes from "prop-types";
import { GET_SIZE } from "utils/responsive";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { noop } from "lodash";
import { get } from "utils/lodash";

const HardwareTypeTable = ({
  columns = [],
  hardwareTypeList = [],
  isOpenHardwareTypeDialog = bool,
  openAddEditHardwareTypeMutation = noop,
  handleClose = noop,
  handleDeleteType = noop,
}) => {
  const { isMd, isLg } = GET_SIZE();
  return (
    <Dialog
      onClose={() => handleClose("hardwareType")}
      open={isOpenHardwareTypeDialog}
      maxWidth={isLg ? "lg" : isMd ? "md" : "sm"}
    >
      <DialogTitle display={"inline-flex"} alignItems={"center"}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Box display="inline-flex" alignItems="center">
            <IconButton onClick={() => handleClose("hardwareType")}>
              <ArrowBack />
            </IconButton>
            {T.ADD_EDIT_HARDWARE_TYPE}
          </Box>
          <MISButton onClick={() => openAddEditHardwareTypeMutation()} startIcon={<AddIcon sx={{ width: 19 }} />}>
            {T.NEW}
          </MISButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ height: "inherit" }}>
        <TableContainer component={Paper} sx={{ maxHeight: "calc(100svh - 350px)", overflow: "auto" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
            <TableHead>
              <TableRow>
                {columns &&
                  columns.length &&
                  columns.map((column, index) => {
                    return <TableCell key={column.key}>{column.label}</TableCell>;
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {hardwareTypeList.map((hardwareType, index) => (
                <TableRow key={hardwareType.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  {columns.map((column) => (
                    <TableCell key={`${hardwareType.id}-${column.key}`}>
                      {column.key === "actions" ? (
                        <>
                          <IconButton
                            sx={{ color: NETSMARTZ_THEME_COLOR }}
                            onClick={() => {
                              openAddEditHardwareTypeMutation(hardwareType.id);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            sx={{ color: NETSMARTZ_THEME_COLOR }}
                            onClick={() => handleDeleteType(hardwareType.id, "hardwareType")}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </>
                      ) : column.key === "id" ? (
                        index + 1
                      ) : column.key === "updatedBy" ? (
                        get(hardwareType[column.key], "userName", "")
                      ) : (
                        hardwareType[column.key]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

HardwareTypeTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  hardwareTypeList: PropTypes.arrayOf(PropTypes.object),
  isOpenHardwareTypeDialog: PropTypes.bool,
  openAddEditHardwareTypeMutation: PropTypes.func,
  handleClose: PropTypes.func,
  handleDeleteType: PropTypes.func,
};

export default HardwareTypeTable;
