import { Table as TableView, TableContainer, Box, Card } from "@mui/material";
import { RESOURSE_TABLE_HEADER } from "components/BusinessCentral/businessCentralModel";
import MISNoData from "components/common/MISNoData";
import ResourseTableHeader from "./ResourseTableHeader";
import ResourceTableBody from "./ResourceTableBody";

const ResourseTable = ({
  usersList,
  rowIndex,
  activeColumn,
  getAllResourseList,
  addNewRow,
  removeRow,
  setActiveInput,
  onHandleTableChange,
  onHandleTableDateChange,
  onHandleTableAutoComplete,
}) => {
  return (
    <Card
      elevation={0}
      sx={{
        backgroundColor: "background.card",
        "& .add-shadow": {
          boxShadow: "inset -6px 0px 5px -5px rgb(0 0 0 / 15%)",
        },
      }}
    >
      <Box overflow="hidden">
        <TableContainer
          sx={{
            height: "calc(100vh - 310px)",
            overflow: "auto",
          }}
        >
          {getAllResourseList.length !== 0 ? (
            <TableView stickyHeader sx={{ borderSpacing: "0 8px" }}>
              <ResourseTableHeader columns={RESOURSE_TABLE_HEADER} />
              <ResourceTableBody
                usersList={usersList}
                rowIndex={rowIndex}
                activeColumn={activeColumn}
                getAllResourseList={getAllResourseList}
                addNewRow={addNewRow}
                removeRow={removeRow}
                setActiveInput={setActiveInput}
                onHandleTableChange={onHandleTableChange}
                onHandleTableDateChange={onHandleTableDateChange}
                onHandleTableAutoComplete={onHandleTableAutoComplete}
              />
            </TableView>
          ) : (
            <MISNoData />
          )}
        </TableContainer>
      </Box>
    </Card>
  );
};

export default ResourseTable;
