import React from "react";
import { useState, useReducer } from "react";
import { useSelector, shallowEqual } from "react-redux";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { InputAdornment, OutlinedInput, Paper, Typography, IconButton, Button, Box } from "@mui/material";

import { useChangePwdMutation } from "api/members/changePwd";

import T from "T";
import { SUCCESS, ERROR, NETSMARTZ_THEME_COLOR } from "theme/colors";

import { handleError } from "utils/error";
import { isPassword } from "utils/validations";
import MISDialog from "components/common/MISDialog";
import MISLoader from "components/common/MISLoader";
import PwdChangedSuccess from "assets/PwdChangedSuccess.png";
import { handleLogout } from "utils/logout";

import { get } from "utils/lodash";
import MISFooterButton from "components/common/MISFooterButton";
import { useNavigate } from "react-router-dom";
import { useForgotPasswordMutation } from "api/postForgotPwd";
import { useParams } from "react-router-dom";

const ForgotPasswordForm = () => {
  const { token } = useParams();
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    newPassword: "",
    confirmPassword: "",
    showPassword: false,
  });
  const { newPassword, confirmPassword, showNewPassword, showConfirmPassword } = localState;

  const [forgotPassword] = useForgotPasswordMutation();

  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setLocalState({ [name]: value });
  };

  const handleClickShowNewPassword = () =>
    setLocalState({
      showNewPassword: !showNewPassword,
    });

  const handleClickShowConfirmPassword = () =>
    setLocalState({
      showConfirmPassword: !showConfirmPassword,
    });

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const isDisabledProceed =
    !newPassword || !confirmPassword || newPassword.length < 7 || !isPassword(newPassword) || newPassword !== confirmPassword;

  const handleChangePwd = () => {
    const body = {
      password: newPassword,
      confirmPassword,
      token,
    };
    forgotPassword(body)
      .unwrap()
      .then((res) => {
        console.log("Password changed successfully");
      })
      .catch(handleError);
  };

  return (
    <Paper elevation={3} sx={{ p: "32px 20px" }}>
      <Typography variant="h5" textAlign="center" fontSize={22}>
        {T.FORGOT_PWD.toUpperCase()}
      </Typography>

      <Typography variant="body1" mt={1}>
        {T.ENTER_NEW_PWD}
      </Typography>

      <OutlinedInput
        id="newPassword"
        placeholder={T.TYPE_YOUR_PWD_HERE}
        name="newPassword"
        value={newPassword}
        type={showNewPassword ? "text" : "password"}
        variant="outlined"
        sx={{
          mb: 1,
          mt: 0.5,
          "& .MuiOutlinedInput-notchedOutline": {
            borderBottom: newPassword && `3px solid ${isPassword(newPassword) ? SUCCESS.main : ERROR.main}`,
          },
          "& .MuiOutlinedInput-input": {
            padding: "9.5px 14px",
            fontSize: 14,
          },
        }}
        fullWidth
        required
        onChange={onHandleChange}
        endAdornment={
          <InputAdornment
            aria-label="toggle password visibility"
            onClick={handleClickShowNewPassword}
            onMouseDown={handleMouseDownPassword}
            position="end"
          >
            <IconButton>{showNewPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}</IconButton>
          </InputAdornment>
        }
      />
      <Typography variant="body2">{`${T.NEW_PASSWORD_CONDITION} ${T.NO_SPECIAL_CHARACTERS_ALLOWED}`}</Typography>

      <Typography variant="body1" mt={2}>
        {T.CONFIRM_PWD}
      </Typography>

      <OutlinedInput
        id="confirmPassword"
        placeholder={T.TYPE_YOUR_PWD_HERE}
        name="confirmPassword"
        value={confirmPassword}
        type={showConfirmPassword ? "text" : "password"}
        variant="outlined"
        sx={{
          mb: 2,
          mt: 0.5,
          "& .MuiOutlinedInput-notchedOutline": {
            borderBottom:
              confirmPassword &&
              `3px solid ${isPassword(confirmPassword) && newPassword === confirmPassword ? SUCCESS.main : ERROR.main}`,
          },
          "& .MuiOutlinedInput-input": {
            padding: "9.5px 14px",
            fontSize: 14,
          },
        }}
        fullWidth
        required
        onChange={onHandleChange}
        endAdornment={
          <InputAdornment
            aria-label="toggle password visibility"
            onClick={handleClickShowConfirmPassword}
            onMouseDown={handleMouseDownPassword}
            position="end"
          >
            <IconButton>{showConfirmPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}</IconButton>
          </InputAdornment>
        }
      />
      <Button
        variant="contained"
        sx={{
          bgcolor: NETSMARTZ_THEME_COLOR,
          mt: 3,
          mb: 1,
          textTransform: "capitalize",
          width: "100%",
        }}
        onClick={handleChangePwd}
        disabled={isDisabledProceed}
      >
        {T.SUBMIT}
      </Button>
    </Paper>
  );
};

export default ForgotPasswordForm;
