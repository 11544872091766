import PropTypes from "prop-types";
import noop from "lodash/noop";
import { BACKGROUND } from "theme/colors";
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import T from "T";
import MISFooterButton from "components/common/MISFooterButton";
import MISTextField from "components/common/MISTextField";
import MISDialog from "components/common/MISDialog";
import MISButton from "components/common/MISButton";

const ConfirmReject = ({
  comment = "",
  handleChange = noop,
  openConfirmSubmit = false,
  handleClose = noop,
  handleSubmit = noop,
}) => (
  <MISDialog open={openConfirmSubmit} handleClose={handleClose}>
    <DialogTitle>{T.CONFIRM_REJECTED}</DialogTitle>
    <DialogContent>
      <MISTextField
        label={T.COMMENT}
        required
        fullWidth
        placeholder={T.COMMENT}
        autoComplete="on"
        size="small"
        variant="outlined"
        name="comment"
        value={comment}
        onChange={(e) => handleChange(e)}
      />
    </DialogContent>
    <DialogActions>
      <MISButton variant={"outlined"} onClick={() => handleClose()}>
        {T.CANCEL}
      </MISButton>
      <MISButton variant={"contained"} onClick={handleSubmit}>
        {T.REJECTED}
      </MISButton>
    </DialogActions>
  </MISDialog>
);

ConfirmReject.propTypes = {
  handleChange: PropTypes.func,
  comment: PropTypes.string,
  openConfirmSubmit: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default ConfirmReject;
