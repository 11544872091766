import PropTypes from "prop-types";
import { noop } from "lodash";

import ProjectsTable from "./CaseStudyTable";

const CaseStudyProjects = ({
  totalTableRowsCount,
  totalPageCount,
  page,
  rowsPerPage,
  onPageChange = noop,
  onRowsPerPageChange = noop,
  allTableRows = [],
  handleAddEditCaseStudyDialog = noop,
  pName,
  refreshTable = noop,
  handleOpenCaseStudyUpload = noop,
  handleCaseStudyUploadLinkDialog = noop,
  handleAcceptCaseStudyDialog = noop,
  onClickReminder = noop,
}) => (
  <ProjectsTable
    pName={pName}
    allTableRows={allTableRows}
    totalTableRowsCount={totalTableRowsCount}
    totalPageCount={totalPageCount}
    page={page}
    rowsPerPage={rowsPerPage}
    onPageChange={onPageChange}
    onRowsPerPageChange={onRowsPerPageChange}
    refreshTable={refreshTable}
    handleAddEditCaseStudyDialog={handleAddEditCaseStudyDialog}
    handleOpenCaseStudyUpload={handleOpenCaseStudyUpload}
    handleCaseStudyUploadLinkDialog={handleCaseStudyUploadLinkDialog}
    handleAcceptCaseStudyDialog={handleAcceptCaseStudyDialog}
    onClickReminder={onClickReminder}
  />
);

CaseStudyProjects.propTypes = {
  allTableRows: PropTypes.array,
  pName: PropTypes.string,
  refreshTable: PropTypes.func,
  handleAddEditCaseStudyDialog: PropTypes.func,
  handleCaseStudyUploadLinkDialog: PropTypes.func,
  handleRejectCaseStudyDialog: PropTypes.func,
  onClickReminder: PropTypes.func,
};

export default CaseStudyProjects;
