import { Table as TableView, Card, TableContainer, Typography, Box, Tooltip } from "@mui/material";
import MISButton from "components/common/MISButton";
import { useNavigate } from "react-router-dom";
import TableHeader from "./TableHeader";
import MisTableBody from "./TableBody";
import MasterPagination from "components/MasterSetting/MasterPagination";
import T from "T";
import PropTypes from "prop-types";
import { Add, ArrowBack } from "@mui/icons-material";
import routes from "router/routes";
import MISNoData from "components/common/MISNoData";
import Filters from "../Filters";
import usePMFetch from "hooks/usePMFetch";
import { noop } from "lodash";
import { StyledBox } from "utils/members";
import CSV from "../../../assets/CSV.png";

const VendorTable = ({
  tableData,
  page,
  size,
  handlePageChange,
  handleRowsPerPageChange,
  totalTableRowsCount,
  totalPageCount,
  deleteVendor,
  filters,
  onhandleFilterChange = noop,
  handleFilterClose = noop,
  handleFilterSubmit = noop,
  handleChange = noop,
  handleActive = noop,
  downloadVendorCsv = noop,
}) => {
  const navigate = useNavigate();

  const { skillList } = usePMFetch();

  return (
    <Box>
      <Typography variant="h5">{T.VENDOR + "s"}</Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          mt: 1,
          p: 1,
          backgroundColor: "background.card",
        }}
      >
        <Filters
          skillList={skillList}
          filters={filters}
          onhandleFilterChange={onhandleFilterChange}
          handleFilterClose={handleFilterClose}
          handleFilterSubmit={handleFilterSubmit}
          handleChange={handleChange}
        />
        <Tooltip title={T.CSV} placement="top">
          <Box sx={{ width: 40, height: 40, cursor: "pointer", ml: 2 }} component="img" src={CSV} onClick={downloadVendorCsv} />
        </Tooltip>
        <MISButton
          sx={{ ml: 2 }}
          size="small"
          startIcon={<Add sx={{ width: 19 }} />}
          onClick={() => {
            navigate(routes.app.vendorsForm);
          }}
        >
          {T.NEW}
        </MISButton>
      </Box>
      <Card
        elevation={0}
        sx={{
          p: "0px 8px",
          mt: 1,

          backgroundColor: "background.card",
          "& .add-shadow": {
            boxShadow: "inset -6px 0px 5px -5px rgb(0 0 0 / 15%)",
          },
        }}
      >
        <TableContainer
          sx={{
            height: "calc(100vh - 310px)",
            overflow: "auto",
          }}
        >
          {tableData.length > 0 ? (
            <TableView stickyHeader sx={{ borderSpacing: "0 8px" }}>
              <TableHeader columns={VENDOR_TABLE_HEADER} />

              <MisTableBody
                allTableRows={tableData}
                page={page}
                size={size}
                deleteVendor={deleteVendor}
                handleActive={handleActive}
              />
            </TableView>
          ) : (
            <MISNoData />
          )}
        </TableContainer>
        <MasterPagination
          page={page}
          onPageChange={handlePageChange}
          rowsPerPage={size}
          onRowsPerPageChange={handleRowsPerPageChange}
          totalTableRowsCount={totalTableRowsCount}
          totalPageCount={totalPageCount}
        />
      </Card>
    </Box>
  );
};

VendorTable.propTypes = {
  tableData: PropTypes.array,
  page: PropTypes.number,
  size: PropTypes.number,
  handlePageChange: PropTypes.func,
  handleRowsPerPageChange: PropTypes.func,
  totalTableRowsCount: PropTypes.number,
  totalPageCount: PropTypes.number,
  deleteVendor: PropTypes.func,
  skillList: PropTypes.array,
  filters: PropTypes.object,
  onhandleFilterChange: PropTypes.func,
  handleFilterClose: PropTypes.func,
  handleFilterSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  handleActive: PropTypes.array,
  downloadVendorCsv: PropTypes.func,
};

export default VendorTable;

export const VENDOR_TABLE_HEADER = [
  { label: T.S_NO },
  // { label: T.COMPANY_NAME },
  { label: T.COMPANY_NAME },
  // { label: T.VENDOR + " " + T.EMAIL },
  // {
  //   label: T.VENDOR + " " + T.LOCATION,
  // },
  // {
  //   label: T.VENDOR + " " + T.PHONE,
  // },
  {
    label: T.POC + " " + T.DETAILS,
  },
  {
    label: T.NO_OF_EMPLOYEES,
  },
  { label: T.TECH_STACK },
  {
    label: T.WORK_MODE,
  },
  // { label: T.VENDOR + " " + T.STATUS },
  { label: T.OWNER + " " + T.DETAILS },
  // { label: T.OWNER + " " + T.EMAIL },
  // {
  //   label: T.OWNER + " " + T.LOCATION,
  // },
  // {
  //   label: T.OWNER + " " + T.PHONE,
  // },
  {
    label: T.PAN,
  },
  { label: T.GST },
  {
    label: T.RATING,
  },
  { label: T.COMMENTS },
  { label: T.CREATED_BY },
  // { label: T.EDIT },
  // {
  //   label: T.DELETE,
  // },
  { label: T.ACTION_BUTTONS },
];
