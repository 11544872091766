import React from "react";
import { noop } from "lodash";
import PropTypes from "prop-types";
import ManufacturerTable from "./ManufacturerTable";
import ManufacturerMutation from "./ManufacturerMutation";

const ManufacturerDialog = ({
  manufacturerList = [],
  isOpenManufacturerDialog = bool,
  isAddEditManufacturerOpen = bool,
  addEditManufacturer = {},
  openAddEditManufacturerMutation = noop,
  closeAddEditMutation = noop,
  handleClose = noop,
  onHandleChange = noop,
  handleSubmitManufacturer = noop,
  handleDeleteType = noop,
}) => {
  const columns = [
    { label: "S.no", key: "id" },
    { label: "Manufacturer Name", key: "name" },
    { label: "Created By", key: "updatedBy" },
    { label: "Actions", key: "actions" },
  ];
  return (
    <>
      <ManufacturerTable
        columns={columns}
        manufacturerList={manufacturerList}
        isOpenManufacturerDialog={isOpenManufacturerDialog}
        openAddEditManufacturerMutation={openAddEditManufacturerMutation}
        handleClose={handleClose}
        handleDeleteType={handleDeleteType}
      />
      <ManufacturerMutation
        isAddEditManufacturerOpen={isAddEditManufacturerOpen}
        addEditManufacturer={addEditManufacturer}
        handleClose={closeAddEditMutation}
        handleSubmitManufacturer={handleSubmitManufacturer}
        onHandleChange={onHandleChange}
      />
    </>
  );
};

ManufacturerDialog.propTypes = {
  manufacturerList: PropTypes.arrayOf(PropTypes.object),
  isOpenManufacturerDialog: PropTypes.bool,
  isAddEditManufacturerOpen: PropTypes.bool,
  addEditManufacturer: PropTypes.object,
  openAddEditManufacturerMutation: PropTypes.func,
  closeAddEditMutation: PropTypes.func,
  handleClose: PropTypes.func,
  onHandleChange: PropTypes.func,
  handleSubmitManufacturer: PropTypes.func,
  handleDeleteManufacturer: PropTypes.func,
};

export default ManufacturerDialog;
