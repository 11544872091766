import { createSlice } from "@reduxjs/toolkit";
import { PAGINATION } from "settings/constants/pagination";

const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;
const SCROLL_ORIGIN = 0;
const onBenchSlice = createSlice({
  name: "onBenchSlice",
  initialState: {
    pag: INITIAL_PAGE,
    rowPerPag: ROWS_PER_PAGE,
    storedScrollPosition: SCROLL_ORIGIN,
  },
  reducers: {
    onBenchStore: (state, action) => {
      state.pag = action.payload.page;
      state.rowPerPag = action.payload.rowsPerPage;
      state.storedScrollPosition = action.payload.storedScrollPosition;
    },
  },
});
export const { onBenchStore } = onBenchSlice.actions;

export default onBenchSlice.reducer;
