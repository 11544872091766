import { Children, useEffect, useReducer } from "react";
import { CircularProgress, DialogActions, DialogContent, DialogTitle, Grid, InputAdornment, Typography } from "@mui/material";
import T from "T";
import MISDialog from "components/common/MISDialog";
import { get, noop } from "lodash";
import MISAutocomplete from "components/common/MISAutocomplete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import MISFooterButton from "components/common/MISFooterButton";
import { useGetFilterHardwareMutation } from "api/Hardware/getFilterHardware";
import { PAGINATION } from "settings/constants/pagination";
import { handleError } from "utils/error";
import { getCurrentTableParams } from "data/members/memberTableSelectors";
import { useAssignHardwareInventMutation } from "api/Hardware/assignHardware";
import { toast } from "react-toastify";
import { useLazyGetUserListQuery } from "api/members/getUserList";

const AssignHardware = ({
  hardwareMod = false,
  assignHardware = false,
  editHardware = false,
  handleClose = noop,
  refreshTable = noop,
  userName = "",
  userId = "",
}) => {
  const { INITIAL_PAGE } = PAGINATION;
  const ROWS_PER_PAGE = 10000;

  const initialState = {
    usersList: [],
    user: null,
    unAssignedHardwareList: [],
    assignedHardwareList: [],
    hardware: [{ id: "", hardwareType: "", serNo: "" }],
  };
  const emptyHardwareMap = { id: "", hardwareType: "", serNo: "" };
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), initialState);
  const { usersList, user, unAssignedHardwareList, assignedHardwareList, hardware } = localState;
  const [getUserList, { isFetching: isUserLoading }] = useLazyGetUserListQuery();
  const [getFilterHardware] = useGetFilterHardwareMutation();
  const [assignHardwareInvent, { isLoading: assignHardwareLoading }] = useAssignHardwareInventMutation();

  useEffect(() => {
    if (editHardware || assignHardware) {
      getFilterHardware({ page: INITIAL_PAGE, rowsPerPage: ROWS_PER_PAGE, payload: { hardwareStatus: "Unassignment" } })
        .unwrap()
        .then((res) => {
          const { allTableRows } = getCurrentTableParams(res);
          setLocalState({ unAssignedHardwareList: allTableRows });
        })
        .catch(handleError);
      getFilterHardware({ page: INITIAL_PAGE, rowsPerPage: ROWS_PER_PAGE, payload: { text: null } })
        .unwrap()
        .then((res) => {
          const { allTableRows } = getCurrentTableParams(res);
          setLocalState({ assignedHardwareList: allTableRows });
        })
        .catch(handleError);
      getUserList({ page: 0, size: 10000, status: T.ACTIVE })
        .unwrap()
        .then((res) => {
          const { allTableRows } = getCurrentTableParams(res);
          setLocalState({
            usersList: allTableRows,
          });
        })
        .catch(handleError);
    }
  }, [userId, assignHardware]);

  useEffect(() => {
    if ((userId && editHardware) || user) {
      getFilterHardware({
        page: INITIAL_PAGE,
        rowsPerPage: ROWS_PER_PAGE,
        payload: { userId: userId ? userId : user.id, status: "Assignment" },
      })
        .unwrap()
        .then((res) => {
          const { allTableRows } = getCurrentTableParams(res);
          const transformHardware = (data) => {
            const { hardwareID, hardwareType, serialNumber } = data;
            return {
              id: hardwareID,
              hardwareType: hardwareType.type,
              serNo: serialNumber,
            };
          };
          if (allTableRows.length === 0) {
            setLocalState({ hardware: [emptyHardwareMap] });
          } else {
            setLocalState({ hardware: allTableRows.map(transformHardware) });
          }
        })
        .catch(handleError);
    }
  }, [editHardware, user]);

  const renderIcons = (index) => {
    return (
      <>
        {(index > 0 || (hardware.length > 0 && index >= 0)) && (
          <RemoveCircleOutlineIcon
            onClick={() => {
              hardware.length === 1 ? onHandleReqReset() : onHandleReqRemove(index);
            }}
            fontSize="small"
            sx={{
              cursor: "pointer",
              color: NETSMARTZ_THEME_COLOR,
            }}
          />
        )}
        {index === hardware.length - 1 && (
          <AddCircleOutlineIcon
            onClick={() => {
              onHandleReqAddMore();
            }}
            fontSize="small"
            sx={{
              cursor: "pointer",
              color: NETSMARTZ_THEME_COLOR,
            }}
          />
        )}
      </>
    );
  };

  const onHandleReqAddMore = () => {
    hardware.push(emptyHardwareMap);
    setLocalState({ hardware });
  };

  const onHandleReqRemove = (index) => {
    hardware.splice(index, 1);
    setLocalState({ hardware });
  };

  const onHandleReqReset = () => {
    setLocalState({ hardware: [emptyHardwareMap] });
  };

  const onHandleAutoCompleteChange = (index, newValue, type) => {
    if (type === "hardwareType") {
      hardware[index][type] = get(newValue, "label", "");
      if (!hardware[index][type]) {
        hardware[index]["serNo"] = "";
        hardware[index]["id"] = "";
      }
    } else {
      hardware[index][type] = get(newValue, "serialNumber", "");
      hardware[index]["id"] = get(newValue, "hardwareID", "");
    }

    setLocalState({ hardware });
  };
  const handleUserAutoCompleteChange = (newValue, type) => {
    if (newValue) {
      setLocalState({ [type]: newValue });
    } else {
      setLocalState({ [type]: newValue, hardware: [emptyHardwareMap] });
    }
  };
  const unAssignedHardwareTypeSet = new Set(unAssignedHardwareList.map((item) => item.hardwareType?.type).filter(Boolean));
  const assignedHardwareTypeSet = new Set(assignedHardwareList.map((item) => item.hardwareType?.type).filter(Boolean));

  const unAssignedHardwareTypeList = [];
  unAssignedHardwareTypeSet.forEach((type) => {
    unAssignedHardwareTypeList.push({ label: type });
  });
  const assignedHardwareTypeList = [];
  assignedHardwareTypeSet.forEach((type) => {
    assignedHardwareTypeList.push({ label: type });
  });

  const handleHardwareAssign = (e) => {
    const hardwareIds = hardware.map((item) => get(item, "id", ""));
    const hardwareAssignmentDto = {
      userId: userId ? userId : get(user, "id", ""),
      hardwareIds: hardwareIds.some((item) => item === "") ? [] : hardwareIds,
    };
    assignHardwareInvent(hardwareAssignmentDto)
      .unwrap()
      .then(() => {
        toast.success(editHardware ? T.HARDWARE_UPDATED_SUCCESSFULLY : T.HARDWARE_ASSIGNED_SUCCESSFULLY);
        setLocalState(initialState);
        refreshTable();
        handleClose();
      })
      .catch(handleError);
  };

  return (
    <MISDialog
      open={assignHardware || editHardware}
      handleClose={() => {
        setLocalState(initialState);
        handleClose();
      }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        <Typography variant="h6">
          {userName
            ? `${editHardware ? T.ASSIGN_REVOKE_HARDWARE : T.ASSIGN_HARDWARE} to ${userName}`
            : `${T.ASSIGN_HARDWARE} ${user ? `to ${user.userName}` : ""}`}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ width: "650px" }}>
        {hardwareMod && (
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <MISAutocomplete
                label={T.USER}
                fullWidth
                required
                listDetails={usersList}
                loading={isUserLoading}
                getByLabelText={(option) => get(option, "userEmailId", "")}
                value={user}
                onHandleChange={(event, newValue) => handleUserAutoCompleteChange(newValue, "user")}
                inputAdornment={
                  <InputAdornment>
                    {isUserLoading ? <CircularProgress sx={{ color: NETSMARTZ_THEME_COLOR }} size={20} /> : null}
                  </InputAdornment>
                }
                andormentPosition={"endAdornment"}
              />
            </Grid>
          </Grid>
        )}
        {Children.toArray(
          hardware.map((data, index) => {
            return (
              <Grid container spacing={2} key={index} pt={index === 0 ? 0 : 1}>
                <Grid item md={6} xs={12}>
                  <MISAutocomplete
                    label={T.HARDWARE_ITEM}
                    fullWidth
                    required
                    listDetails={unAssignedHardwareTypeList}
                    getByLabelText={(option) => get(option, "label", "")}
                    value={assignedHardwareTypeList.find((item) => item.label === hardware[index].hardwareType) || null}
                    onHandleChange={(event, newValue) => onHandleAutoCompleteChange(index, newValue, "hardwareType")}
                  />
                </Grid>
                <Grid item md={4.5} xs={12}>
                  <MISAutocomplete
                    label={T.SERIAL_NUMBER}
                    fullWidth
                    disabled={!hardware[index].hardwareType}
                    required
                    listDetails={unAssignedHardwareList
                      .filter((item) => item.hardwareType.type === hardware[index].hardwareType)
                      .filter((item) => !hardware.some((sNo) => item.serialNumber === sNo.serNo))}
                    getByLabelText={(option) => get(option, "serialNumber", "")}
                    value={
                      assignedHardwareList
                        .filter((item) => item.hardwareType.type === hardware[index].hardwareType)
                        .find((item) => item.serialNumber === hardware[index].serNo) || null
                    }
                    onHandleChange={(event, newValue) => onHandleAutoCompleteChange(index, newValue, "serNo")}
                  />
                </Grid>
                <Grid item md={1.5} xs={12} mt={4}>
                  {renderIcons(index)}
                </Grid>
              </Grid>
            );
          }),
        )}
      </DialogContent>
      <DialogActions>
        <MISFooterButton
          proceedButtonText={editHardware ? T.SUBMIT : T.ASSIGN_HARDWARE}
          justify="center"
          sx={{ p: 1.5 }}
          size="medium"
          disableProceed={hardwareMod ? false : assignHardware && hardware.some((item) => item.id === "")}
          handleClose={() => {
            setLocalState(initialState);
            handleClose();
          }}
          showLoader={assignHardwareLoading}
          handleSubmit={handleHardwareAssign}
        />
      </DialogActions>
    </MISDialog>
  );
};

export default AssignHardware;
