import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from "@mui/material";
import T from "T";
import { ArrowBack } from "@mui/icons-material";
import MISTextField from "components/common/MISTextField";
import MISAutocomplete from "components/common/MISAutocomplete";
import { get } from "utils/lodash";
import MISFooterButton from "components/common/MISFooterButton";
import { noop } from "lodash";
import PropTypes from "prop-types";

const ManufacturerMutation = ({
  addEditManufacturer = {},
  isAddEditManufacturerOpen = bool,
  onHandleChange = noop,
  handleClose = noop,
  handleSubmitManufacturer = noop,
}) => {
  return (
    <Dialog onClose={() => handleClose("manufacturer")} open={isAddEditManufacturerOpen}>
      <DialogTitle display={"inline-flex"} alignItems={"center"}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Box display="inline-flex" alignItems="center">
            <IconButton onClick={() => handleClose("manufacturer")}>
              <ArrowBack />
            </IconButton>
            {get(addEditManufacturer, "manufacturerHeadName", "")
              ? `${T.EDIT} ${get(addEditManufacturer, "manufacturerHeadName", "")}`
              : `${T.ADD} ${T.NEW} ${T.MANUFACTURER}`}
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ height: "inherit" }}>
        <MISTextField
          label={T.MANUFACTURER}
          required
          placeholder={T.MANUFACTURER}
          autoComplete="on"
          size="small"
          variant="outlined"
          name="manufacturerName"
          value={get(addEditManufacturer, "manufacturerName", "")}
          onChange={(e) => onHandleChange(e)}
        />
      </DialogContent>
      <DialogActions>
        <MISFooterButton
          proceedButtonText={addEditManufacturer.manufacturerId ? T.UPDATE : T.SUBMIT}
          justify="end"
          sx={{ pb: 0.5 }}
          handleClose={() => handleClose("manufacturer")}
          handleSubmit={
            addEditManufacturer.manufacturerId
              ? () => handleSubmitManufacturer(addEditManufacturer.manufacturerId)
              : () => handleSubmitManufacturer()
          }
        />
      </DialogActions>
    </Dialog>
  );
};

ManufacturerMutation.propTypes = {
  addEditManufacturer: PropTypes.object,
  isAddEditManufacturerOpen: PropTypes.bool,
  onHandleChange: PropTypes.func,
  handleClose: PropTypes.func,
  handleSubmitManufacturer: PropTypes.func,
};

export default ManufacturerMutation;
