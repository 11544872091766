import React from "react";

import { useReducer } from "react";

import { styled, TableCell, TableBody, TableRow, Grid, IconButton, Tooltip, Rating } from "@mui/material";
import PropTypes from "prop-types";
import { get, noop } from "lodash";
import T from "T";
import ConfirmSubmit from "components/Members/Member/ConfirmSubmit";
import { Box } from "@mui/system";
import { SubTitleTypography, TitleTypography } from "utils/members";
import { Delete, Edit, ToggleOff, ToggleOn } from "@mui/icons-material";
import { BORDER, ERROR, NETSMARTZ_THEME_COLOR, SUCCESS } from "theme/colors";
import { useNavigate } from "react-router-dom";
import routes from "router/routes";
const StyledTableCell = styled(TableCell)(() => ({
  borderTop: "inherit",
  overflow: "hidden",
  backgroundColor: "white",
  padding: "8px 8px 8px 24px",
  fontSize: 13.2,
  maxWidth: 150,
}));

const ResourceTableBody = ({ allTableRows, page, size, deleteVendor = noop, vendorId, handleActive = noop }) => {
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    openConfirmSubmit: false,

    resourceId: "",
  });

  const { openConfirmSubmit, resourceId } = localState;

  const navigate = useNavigate();

  return (
    <TableBody>
      {allTableRows?.map((record, index) => {
        const serialNumber = index + 1 + page * size;
        const active = get(record, "isActive", false);

        return (
          <TableRow key={index}>
            {/* <StyledTableCell sx={{ pl: 4.5 }}>{serialNumber}.</StyledTableCell> */}
            <TableCell
              sx={{
                backgroundColor: "white",
                borderLeft: active ? `5px solid ${SUCCESS.main}` : `5px solid ${ERROR.main}`,

                pl: 4.5,
              }}
            >
              {serialNumber}.
            </TableCell>
            <StyledTableCell>{get(record, "resourceName", "-")}</StyledTableCell>
            <StyledTableCell>{get(record, "projectName", "-")}</StyledTableCell>
            <StyledTableCell>{get(record, "projectManager.name", "-")}</StyledTableCell>

            <StyledTableCell sx={{ maxWidth: 250, wordWrap: "break-word" }}>
              {record.techStack.map((it) => it.skillName).toString()}
            </StyledTableCell>

            <StyledTableCell>{get(record, "department.departmentName", "-")}</StyledTableCell>
            <StyledTableCell sx={{ maxWidth: 250 }}>
              <Box display="flex" whiteSpace="nowrap">
                <SubTitleTypography>{T.CSM}:</SubTitleTypography>
                <TitleTypography ml={1}>{get(record, "csmRating", "-")}</TitleTypography>
              </Box>
              <Box display="flex" whiteSpace="nowrap">
                <SubTitleTypography>{T.TECHNICAL}:</SubTitleTypography>
                <TitleTypography ml={1}>{get(record, "techRating", "-")}</TitleTypography>
              </Box>
              <Box display="flex" whiteSpace="nowrap">
                <SubTitleTypography>{T.BEHAVIOUR}:</SubTitleTypography>
                <TitleTypography ml={1}>{get(record, "behaviourRating", "-")}</TitleTypography>
              </Box>
            </StyledTableCell>
            <StyledTableCell>{<Rating precision={0.5} value={get(record, "overAllRating", "-")} />}</StyledTableCell>

            <StyledTableCell sx={{ maxWidth: 250 }}>
              <Box display="flex" whiteSpace="nowrap">
                <SubTitleTypography>{T.START_DATE}:</SubTitleTypography>
                <TitleTypography ml={1}>{get(record, "startDate", "-")}</TitleTypography>
              </Box>
              <Box display="flex" whiteSpace="nowrap">
                <SubTitleTypography>{T.END_DATE}:</SubTitleTypography>
                <TitleTypography ml={1}>{get(record, "endDate", "-")}</TitleTypography>
              </Box>
            </StyledTableCell>
            <StyledTableCell>{get(record, "contactNumber", "-")}</StyledTableCell>

            <StyledTableCell sx={{ maxWidth: 250 }}>
              <Box display="flex" whiteSpace="nowrap">
                <SubTitleTypography>{T.PAYMENT}:</SubTitleTypography>
                <TitleTypography ml={1}>{get(record, "payment", "-")}</TitleTypography>
              </Box>
              <Box display="flex" whiteSpace="nowrap">
                <SubTitleTypography>{"Next due"}:</SubTitleTypography>
                <TitleTypography ml={1}>{get(record, "nextDue", "-")}</TitleTypography>
              </Box>
            </StyledTableCell>
            <StyledTableCell>{get(record, "noticePeriodTime", "-")}</StyledTableCell>
            <StyledTableCell sx={{ maxWidth: 250 }}>
              <Box display="flex" whiteSpace="nowrap">
                <SubTitleTypography>{T.VENDOR_CURRENCY}:</SubTitleTypography>
                <TitleTypography ml={1}>{get(record, "vendorCurrency", "-")}</TitleTypography>
              </Box>
              <Box display="flex" whiteSpace="nowrap">
                <SubTitleTypography>{T.VENDOR_COST}:</SubTitleTypography>
                <TitleTypography ml={1}>{get(record, "vendorCost", "-")}</TitleTypography>
              </Box>
            </StyledTableCell>
            <StyledTableCell sx={{ maxWidth: 250 }}>
              <Box display="flex" whiteSpace="nowrap">
                <SubTitleTypography>{T.CLIENT_HOUR_ALLOC}:</SubTitleTypography>
                <TitleTypography ml={1}>{get(record, "clientHours", "-")}</TitleTypography>
              </Box>
              <Box display="flex" whiteSpace="nowrap">
                <SubTitleTypography>{T.ACTUAL_HOUR}:</SubTitleTypography>
                <TitleTypography ml={1}>{get(record, "actualHourAllocated", "-")}</TitleTypography>
              </Box>
              <Box display="flex" whiteSpace="nowrap">
                <SubTitleTypography>{T.COMMITMENT}:</SubTitleTypography>
                <TitleTypography ml={1}>{get(record, "commitment", "-")}</TitleTypography>
              </Box>
            </StyledTableCell>
            <StyledTableCell>{get(record, "comments", "-")}</StyledTableCell>

            <StyledTableCell>
              <Grid display={"inline-flex"}>
                <IconButton
                  sx={{
                    color: NETSMARTZ_THEME_COLOR,
                  }}
                  onClick={() => {
                    navigate(routes.app.resourceForm + "/" + vendorId + "/" + record.resourceId);
                  }}
                >
                  <Edit />
                </IconButton>

                <Tooltip title={active ? T.ACTIVATE : T.DEACTIVATE} placement="top">
                  <IconButton
                    sx={{
                      color: active ? NETSMARTZ_THEME_COLOR : BORDER.tabsGrey,
                    }}
                    onClick={() => {
                      handleActive(record.resourceId, active ? false : true);
                    }}
                  >
                    {active ? <ToggleOn /> : <ToggleOff />}
                  </IconButton>
                </Tooltip>

                <Tooltip placement="top" title={T.DELETE}>
                  <IconButton
                    sx={{ color: NETSMARTZ_THEME_COLOR }}
                    onClick={() => {
                      setLocalState({ resourceId: record.resourceId, openConfirmSubmit: true });
                    }}
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </StyledTableCell>
          </TableRow>
        );
      })}
      <ConfirmSubmit
        dialogMessageText={T.DELETE}
        proceedButtonText={T.DELETE}
        openConfirmSubmit={openConfirmSubmit}
        handleClose={() => {
          setLocalState({ openConfirmSubmit: false });
        }}
        handleSubmit={() => {
          deleteVendor(resourceId);
          setLocalState({ openConfirmSubmit: false });
        }}
      />
    </TableBody>
  );
};

ResourceTableBody.propTypes = {
  page: PropTypes.number,
  size: PropTypes.number,
  deleteVendor: PropTypes.func,
  allTableRows: PropTypes.array,
  handleActive: PropTypes.array,
  vendorId: PropTypes.any,
};

export default ResourceTableBody;
