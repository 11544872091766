import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getFilterAssignHardware: builder.mutation({
      query: ({ page, rowsPerPage, payload }) => ({
        url: `/hardware/assignHardwareFilter?page=${page}&size=${rowsPerPage}`,
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const { useGetFilterAssignHardwareMutation } = extendedApi;
