import { TableCell, TableHead, TableRow, Typography } from "@mui/material";
import React, { Children } from "react";
import { BACKGROUND } from "theme/colors";
import PropTypes from "prop-types";
const ResourceTableHeader = ({ columns }) => {
  return (
    <TableHead>
      <TableRow>
        {Children.toArray(
          columns.map((column) => {
            return (
              <TableCell
                sx={{
                  border: "none",
                  backgroundColor: BACKGROUND.header,
                  padding: "0px 8px 0px 24px",
                }}
              >
                <Typography fontSize={14} noWrap fontWeight={600} variant="body1" textTransform={"uppercase"}>
                  {column.label}
                </Typography>
              </TableCell>
            );
          }),
        )}
      </TableRow>
    </TableHead>
  );
};

ResourceTableHeader.propTypes = {
  columns: PropTypes.array,
};

export default ResourceTableHeader;
