import { useReducer, useEffect } from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";

import { Table as TableView, Paper, TableContainer, Box, Card } from "@mui/material";

import { ASSIGN_TABLE_HEADER } from "settings/constants/members";
import { PAGINATION } from "settings/constants/pagination";
import MasterPagination from "components/MasterSetting/MasterPagination";
import { orderBy } from "lodash";
import T from "T";
import AssignHeader from "./AssignHeader";
import AssignTableBody from "./AssignTableBody";
import MISNoData from "components/common/MISNoData";
import { minWidth } from "@mui/system";

const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;

export const stickyCol = {
  backgroundColor: 'inherit',
  position: 'sticky',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  right: 0,
  boxShadow: 'inset -6px 0px 5px -5px rgb(0 0 0 / 15%)',
  zIndex: 5,
  minWidth: 150
}

const AssignTable = ({
  page = INITIAL_PAGE,
  onPageChange,
  rowsPerPage = ROWS_PER_PAGE,
  onRowsPerPageChange,
  totalTableRowsCount = INITIAL_PAGE,
  totalPageCount = INITIAL_PAGE,
  allTableRows = [],
  generate_Certificate = noop,
  handleReview = noop,
  handleDeleteCourse = noop,
}) => {
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    sortingLabel: "",
    sorting: {},
    renderedRow: [],
  });

  useEffect(() => {
    setLocalState({
      sorting: {
        ["courseName"]: { disable: false, data: orderBy(allTableRows, ["trainingCourse.courseName"], ["asc"]) },
        ["trainingDuration"]: { disable: false, data: orderBy(allTableRows, ["courseDuration"], ["asc"]) },
        ["memberInfo"]: { disable: false, data: orderBy(allTableRows, ["userId.userName"], ["asc"]) },
        ["assignDate"]: { disable: false, data: orderBy(allTableRows, ["assignDate"], ["asc"]) },
      },
      renderedRow: allTableRows,
    });
  }, [allTableRows]);

  const { sortingLabel, sorting, renderedRow } = localState;
  const handleAssignSorting = (sortLabel) => {
    const newSorting = {};
    for (const key in sorting) {
      newSorting[key] = {
        disable: sortingLabel === sortLabel ? false : key === sortLabel ? false : !sorting[key].disable,
        data: sorting[key].data,
      };
    }
    setLocalState({
      sortingLabel: sortingLabel === sortLabel ? "" : sortLabel,
      sorting: newSorting,
      renderedRow: sortingLabel === sortLabel ? allTableRows : sorting[sortLabel].data,
    });
  };

  return (
    <Card
      elevation={0}
      sx={{
        p: "0px 8px",
        mt: 1.5,
        backgroundColor: "background.card",
        "& .add-shadow": {
          boxShadow: "inset -6px 0px 5px -5px rgb(0 0 0 / 15%)",
        },
      }}
    >
      <Box overflow="hidden">
        <TableContainer
          sx={{
            height: "calc(100vh - 295px)",
            overflow: "auto",
          }}
        >
          {renderedRow.length !== 0 ? (
            <TableView stickyHeader sx={{ borderSpacing: "0 8px" }}>
              <AssignHeader columns={ASSIGN_TABLE_HEADER} handleAssignSorting={handleAssignSorting} sorting={sorting} />
              <AssignTableBody
                page={page}
                onPageChange={onPageChange}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={onRowsPerPageChange}
                totalTableRowsCount={totalTableRowsCount}
                totalPageCount={totalPageCount}
                allTableRows={renderedRow}
                handleReview={handleReview}
                handleDeleteCourse={handleDeleteCourse}
                generate_Certificate={generate_Certificate}
              />
            </TableView>
          ) : (
            <MISNoData />
          )}
        </TableContainer>
      </Box>

      <MasterPagination
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={onRowsPerPageChange}
        totalTableRowsCount={totalTableRowsCount}
        totalPageCount={totalPageCount}
      />
    </Card>
  );
};

AssignTable.propTypes = {
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  totalDataCount: PropTypes.number,
  totalPageCount: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  allTableRows: PropTypes.array,
  handleReview: PropTypes.func,
  generate_Certificate: PropTypes.func,
};
export default AssignTable;
